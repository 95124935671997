import React, { createContext, useContext, useEffect, useState } from 'react'

type ContextValue = {
  title: string
  setTitle: React.Dispatch<React.SetStateAction<string>>
}

const DocumentTitleContext = createContext<ContextValue | null>(null)
DocumentTitleContext.displayName = 'DocumentTitleContext'

export const DocumentTitleContextProvider: React.FC = ({ children }): JSX.Element => {
  const [title, setTitle] = useState<string>('Powerspace Ads')

  return <DocumentTitleContext.Provider value={{ title, setTitle }}>{children}</DocumentTitleContext.Provider>
}

export function useDocumentTitle(newTitle?: string): ContextValue {
  const { title, setTitle } = useContext(DocumentTitleContext) || {}

  if (!title || !setTitle) throw new Error('DocumentTitleContext was not used')

  useEffect(() => {
    if (newTitle) setTitle(newTitle)
  }, [newTitle])

  return { title, setTitle }
}
