import React, { useMemo } from 'react'
import { Grid, Skeleton, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { AdCopy } from '../../../../__generated__/graphql'

const StyledImage = styled('img')(({ theme }) => ({
  maxWidth: 150,
  height: 'auto',
  marginRight: theme.spacing(1),
}))

const MaxGrid = styled(Grid)({
  width: 340,
})

type AdCopyPreviewCellProps = {
  item: Pick<AdCopy, 'name' | 'advertiserName' | 'title' | 'description' | 'callToAction'> & {
    image?: string
    file?: File | null
  }
}

export const SkeletonPreview: React.FC = (): JSX.Element => {
  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item width="10%">
        <Skeleton variant="text" animation={false} />
      </Grid>

      <Grid item container direction="row" wrap="nowrap">
        <Grid item container alignContent="center" xs="auto">
          <Skeleton variant="rectangular" width={150} height={80} animation={false} sx={{ mr: 1 }} />
        </Grid>
        <MaxGrid item>
          <Skeleton variant="text" animation={false} width="40%" />
          <Skeleton variant="text" animation={false} />
          <Skeleton variant="text" animation={false} width="80%" />
          <Skeleton variant="text" animation={false} width="30%" />
        </MaxGrid>
      </Grid>
    </Grid>
  )
}

export const AdCopyPreviewCell: React.FC<AdCopyPreviewCellProps> = ({ item }): JSX.Element => {
  const imageURL = useMemo(() => {
    if (item?.image) return item.image

    if (item?.file) return URL.createObjectURL(item.file)
    return ''
  }, [item.file, item.image])

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography variant="caption" component="p">
          {item.name}
        </Typography>
      </Grid>

      <Grid item container direction="row" wrap="nowrap">
        <Grid item container alignContent="center" xs="auto">
          <StyledImage src={imageURL} aria-label={`image adcopy ${item.name}`} />
        </Grid>
        <MaxGrid item>
          <Typography variant="caption" color="textSecondary">
            {item.advertiserName}
          </Typography>
          <Typography variant="h6" component="p">
            {item.title}
          </Typography>
          <Typography variant="body2">{item.description}</Typography>
          <Typography variant="body2" color="secondary">
            {item.callToAction}
          </Typography>
        </MaxGrid>
      </Grid>
    </Grid>
  )
}
