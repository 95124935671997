import { theme } from './theme'
import { appBarTheme } from './appBar'
import { buttonTheme } from './button'
import { formLabelTheme } from './form'
import { tabsTheme, tabTheme } from './tab'
import { tableCellTheme, tableHeadTheme, tableRowTheme } from './table'
import { dialogContentTextTheme, dialogContentTheme, dialogTitleTheme } from './dialog'
import { switchTheme } from './switch'

theme.components = {
  MuiAppBar: appBarTheme,
  MuiButton: buttonTheme,
  MuiFormLabel: formLabelTheme,
  MuiTab: tabTheme,
  MuiTabs: tabsTheme,
  MuiTableCell: tableCellTheme,
  MuiTableRow: tableRowTheme,
  MuiTableHead: tableHeadTheme,
  MuiDialogTitle: dialogTitleTheme,
  MuiDialogContent: dialogContentTheme,
  MuiDialogContentText: dialogContentTextTheme,
  MuiSwitch: switchTheme,
}

export default theme
