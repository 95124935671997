import React, { lazy, Suspense } from 'react'
import { ApolloProvider, useQuery, gql } from '@apollo/client'
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import DateAdapter from '@mui/lab/AdapterDayjs'
import { apolloClient } from '../apollo'
import muiTheme from '../styles/theme'
import AppRouter from './AppRouter'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { LocalizationProvider } from '@mui/lab'
import { DocumentTitleContextProvider } from '../utils/providers/documentTitleContext'
import ReactHelmet from '../components/head/ReactHelmet'
import { SnackbarContextProvider } from '../utils/providers/snackbarContext'
import Snackbar from '../components/layout/Snackbar'
import { ErrorBoundary } from '../components/error/ErrorBoundary'
import PwsSpinner from '../components/loader/PwsSpinner'
import OldFrontRedirection from './OldFrontRedirection'
import { getIsOldFrontUrl } from '../utils/isOldFrontUrl'

const LoggedOutRouter = lazy(() => import('./LoggedOutRouter'))

const IsLoggedIn = (): JSX.Element => {
  const IS_LOGGED_IN = gql`
    query IsUserLoggedIn {
      isLoggedIn @client
    }
  `

  const { data } = useQuery(IS_LOGGED_IN)

  if (data.isLoggedIn) return <AppRouter />
  return <LoggedOutRouter />
}

export const App = (): JSX.Element => {
  const isOldFrontUrl = getIsOldFrontUrl()

  return (
    <I18nextProvider i18n={i18next}>
      <LocalizationProvider dateAdapter={DateAdapter}>
        <ApolloProvider client={apolloClient}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
              <ErrorBoundary>
                <DocumentTitleContextProvider>
                  <SnackbarContextProvider>
                    <Suspense
                      fallback={<PwsSpinner />}
                      /**
                       * Will be used when Concurrent mode is release
                       * https://github.com/facebook/react/issues/13206
                       * maxDuration={1000}
                       */
                    >
                      <ReactHelmet />
                      {/* <CssBaseline /> */}
                      <BrowserRouter>{isOldFrontUrl ? <OldFrontRedirection /> : <IsLoggedIn />}</BrowserRouter>
                      <Snackbar />
                    </Suspense>
                  </SnackbarContextProvider>
                </DocumentTitleContextProvider>
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </ApolloProvider>
      </LocalizationProvider>
    </I18nextProvider>
  )
}
