import React from 'react'
import LoginForm from '../components/form/LoginForm'
import LoggedOutLayoutWrapper from '../components/layout/LoggedOutLayoutWrapper'
import { Helmet } from 'react-helmet'

const Login: React.FC = () => {
  return (
    <LoggedOutLayoutWrapper>
      <Helmet>
        <title>Powerspace Ads - Non intrusive advertising platform</title>
      </Helmet>
      <LoginForm />
    </LoggedOutLayoutWrapper>
  )
}

export default Login
