import React, { Fragment, useEffect, useMemo, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_API_KEY } from '../../env'
import { TargetingCircleInput, TargetingZoneInput } from '../../__generated__/graphql'
import { has } from 'lodash-es'
import { countriesCenterAndZoom } from '../../utils/countriesCenterAndZoom'
import { theme } from '../../styles/theme/theme'

interface MapProps {
  locations: Array<TargetingCircleInput> | Array<TargetingZoneInput | string>
  booleanColor: boolean
  countryCode: string
}

const GeoTargetingMap: React.FC<MapProps> = ({ locations, booleanColor, countryCode }) => {
  const [mapReference, setMapReference] = useState(null)
  const [mapsReference, setMapsReference] = useState(null)
  const [drawings, setDrawings] = useState<unknown[]>([])
  const apiKey = GOOGLE_API_KEY

  const color = useMemo(
    (): string => (booleanColor ? theme.palette.error.main : theme.palette.success.main),
    [booleanColor]
  )

  const markerOptions = {
    strokeColor: color,
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: color,
    fillOpacity: 0.3,
  }

  const drawCity = (location: TargetingCircleInput): void => {
    const circle = new (mapsReference as any).Circle({
      center: { lat: location?.location?.latitude, lng: location?.location?.longitude },
      radius: location?.radius * 1000,
      map: mapReference,
      ...markerOptions,
    })
    setDrawings((drawings) => [...drawings, circle])
  }

  const drawDepartementOrRegion = (location: TargetingZoneInput): void => {
    const rectangle = new (mapsReference as any).Rectangle({
      bounds: {
        north: location?.geometry?.northeast?.latitude,
        south: location?.geometry?.southwest?.latitude,
        east: location?.geometry?.northeast?.longitude,
        west: location?.geometry?.southwest?.longitude,
      },
      map: mapReference,
      ...markerOptions,
    })
    setDrawings((drawings) => [...drawings, rectangle])
  }

  const onApiLoaded = (map: any, maps: any): void => {
    if (map && maps) {
      setMapReference(map)
      setMapsReference(maps)
    }
  }

  const removeDrawings = (): void => {
    drawings.forEach((drawing: any) => drawing.setMap(null))
    setDrawings([])
  }

  const displayLocations = (): void => {
    removeDrawings()
    locations.forEach((location: TargetingCircleInput | TargetingZoneInput | string) => {
      if (has(location, 'location')) drawCity(location as TargetingCircleInput)
      else if (has(location, 'geometry')) drawDepartementOrRegion(location as TargetingZoneInput)
    })
  }

  useEffect(() => {
    if (mapsReference && mapReference) displayLocations()
  }, [locations, mapReference, mapsReference, booleanColor])

  return (
    <Fragment>
      <div data-testid={'googleMapGeoTargeting'} style={{ height: '36.5rem', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: apiKey, libraries: ['places'], id: '__googleMapsScriptId' }}
          defaultCenter={countriesCenterAndZoom[countryCode].center || countriesCenterAndZoom.fr.center}
          defaultZoom={countriesCenterAndZoom[countryCode].zoom || countriesCenterAndZoom.fr.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => onApiLoaded(map, maps)}
          draggable={false}
        />
      </div>
    </Fragment>
  )
}

export default GeoTargetingMap
