import React, { useMemo } from 'react'
import { Item, sidebarPalette } from './Sidebar'
import { NavLink, useParams } from 'react-router-dom'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import ListItemIcon from '@mui/material/ListItemIcon'
import Lock from '@mui/icons-material/Lock'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import StopIcon from '@mui/icons-material/Stop'
import makeStyles from '@mui/styles/makeStyles'
import { ListItemButton, Theme } from '@mui/material'
import { AdGroupRoute, AllRoute, PositionRoute } from '../../../pages/routeParams'
import { Status } from '../../../__generated__/graphql'
import MenuWithIcon from '../MenuWithIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import HasPermission from '../../permissions/HasPermission'
import { useConfirmDialogContext } from '../../../utils/providers/confirmDialogContext'
import { FileCopy } from '@mui/icons-material'
import { useDuplicateAdGroupOrPosition } from '../../../utils/hooks/useDuplicateAdGroupOrPosition'
import BackdropLoader from '../../loader/BackdropLoader'
import SidebarWebsiteActions from './PublisherActions'
import SidebarCampaignActions from './AdvertiserActions'

export const useSidebarStyles = makeStyles((theme: Theme) => ({
  subItems: {
    '& div span': {
      textTransform: 'none',
      fontWeight: 'normal',
    },
    color: sidebarPalette.light,
    '&:hover svg.active': {
      color: theme.palette.success.main,
    },
    '&:hover svg.inactive': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      backgroundColor: sidebarPalette.dark,
    },
  },
  listIcons: {
    minWidth: 'inherit',
    paddingRight: '1rem',
    color: 'inherit',
  },
  lockIcon: {
    '& svg': {
      fontSize: '1.4rem',
      marginLeft: '0.3rem',
      marginRight: '0.3rem',
    },
  },
  activeItem: {
    borderLeft: `0.5rem solid ${theme.palette.primary.main}`,
    backgroundColor: sidebarPalette.dark,
    paddingLeft: '1.1rem',
  },
  activeSubItem: {
    '& span': {
      color: sidebarPalette.text,
    },
    '& svg.active': {
      color: theme.palette.success.main,
    },
    '& svg.inactive': {
      color: theme.palette.error.main,
    },
  },
}))

export const Items: React.FC<{
  item: Item
  deployAll: boolean
  updateSidebarItems: () => void
}> = ({ item, deployAll = false, updateSidebarItems }) => {
  const classes = useSidebarStyles()
  const { slug, siteId, campaignId, positionId, adgroupId, campaignProgrammaticId, inventoryId } = useParams<AllRoute>()
  const {
    company: { type: companyType },
  } = useCompanyDetailsContext()

  const itemFoundInPath = item.id === (siteId || campaignId || campaignProgrammaticId)

  const subItemsIds = item.subItems.map((subItem) => subItem.id)
  const subItemIsFromItem = subItemsIds.includes(positionId || adgroupId || inventoryId)

  const displaySubItems = deployAll || itemFoundInPath || subItemIsFromItem

  const lowerCompanyType = companyType.toLowerCase()
  const subItemLink = companyType === 'Publisher' ? 'site' : 'campaign'

  const sortedSubItems = sortItemsByStatus(item.subItems).map((subItem) => (
    <SubItems key={subItem.id} subItem={subItem} />
  ))

  return (
    <>
      <ListItemButton
        disableRipple
        component={NavLink}
        activeClassName={classes.activeItem}
        to={`/${lowerCompanyType}/${slug}/${subItemLink}/${item.id}`}
        autoFocus={displaySubItems}
      >
        {item.status === Status.Archived && (
          <ListItemIcon className={`${classes.lockIcon} ${classes.listIcons}`}>
            <Lock />
          </ListItemIcon>
        )}
        <ListItemText>{item.name}</ListItemText>
        {displaySubItems && (
          <HasPermission roleRequired="edit">
            {companyType === 'Publisher' ? (
              <SidebarWebsiteActions item={item} updateSidebarItems={updateSidebarItems} />
            ) : (
              <SidebarCampaignActions item={item} />
            )}
          </HasPermission>
        )}
      </ListItemButton>
      <Collapse in={displaySubItems} unmountOnExit>
        {sortedSubItems}
      </Collapse>
    </>
  )
}

const SubItems: React.FC<{
  subItem: Item['subItems'][number]
}> = ({ subItem }) => {
  const t = useAppTranslations()
  const classes = useSidebarStyles()
  const { slug, adgroupId, positionId } = useParams<AdGroupRoute | PositionRoute>()
  const isSelected = subItem.id === (adgroupId || positionId)
  const companyType = useCompanyDetailsContext()['company']['type']

  const lowerCompanyType = companyType.toLowerCase()
  const subItemLink = companyType === 'Publisher' ? 'position' : 'adgroup'
  const isAdGroup = subItemLink === 'adgroup'

  const [duplicateSubItem, duplicateLoading] = useDuplicateAdGroupOrPosition(subItem.id, subItem.name)
  const setConfirmDialogState = useConfirmDialogContext()

  const actionsItems = useMemo(
    () => [
      {
        text: t('commons.actions.duplicate'),
        icon: <FileCopy fontSize="small" />,
        action: () => {
          setConfirmDialogState({
            message: [
              t('commons.actions.confirmDuplicate', { name: subItem.name }),
              ...(isAdGroup ? [t('adgroup.duplicateStatusWarning')] : []),
            ],
            buttonLabel: t('commons.actions.duplicate'),
            onSubmit: duplicateSubItem,
          })
        },
        autoClose: true,
      },
    ],
    [subItem, setConfirmDialogState, duplicateSubItem]
  )

  return (
    <ListItemButton
      key={subItem.name}
      className={classes.subItems}
      autoFocus={true}
      disableRipple
      component={NavLink}
      activeClassName={`${classes.activeItem} ${classes.activeSubItem}`}
      to={`/${lowerCompanyType}/${slug}/${subItemLink}/${subItem.id}`}
      sx={{
        '&.Mui-focusVisible': {
          backgroundColor: 'inherit',
        },
      }}
    >
      <BackdropLoader open={duplicateLoading} />
      <SubItemIcon
        isActive={subItem.isActive || subItem.status === Status.Active}
        displayArchivedIcon={subItem?.isArchived || subItem?.status === Status.Archived}
      />
      <ListItemText data-testid="menuSubItem">{subItem.name}</ListItemText>
      {isSelected && (
        <HasPermission roleRequired="edit">
          <MenuWithIcon
            actionItems={actionsItems}
            dataId="sidebarMenuSubItemIcon"
            size="small"
            iconButtonSx={{ py: 0, color: 'white' }}
          >
            <MoreVertIcon fontSize="small" />
          </MenuWithIcon>
        </HasPermission>
      )}
    </ListItemButton>
  )
}

const SubItemIcon: React.FC<{ isActive: boolean; displayArchivedIcon: boolean }> = ({
  isActive,
  displayArchivedIcon,
}) => {
  const classes = useSidebarStyles()
  return displayArchivedIcon ? (
    <ListItemIcon className={`${classes.lockIcon} ${classes.listIcons}`}>
      <Lock />
    </ListItemIcon>
  ) : (
    <ListItemIcon className={classes.listIcons}>
      {isActive ? (
        <PlayArrowIcon className={'active'} fontSize="small" />
      ) : (
        <StopIcon className={'inactive'} fontSize="small" />
      )}
    </ListItemIcon>
  )
}

const sortItemsByStatus = (items: Item['subItems']): Item['subItems'] => {
  const sortedSubItemsMap = items.slice().reduce((previous, current) => {
    const isArchived = current.isArchived ?? current.status === Status.Archived
    const isActive = current.isActive ?? current.status === Status.Active

    const status = isArchived ? Status.Archived : isActive ? Status.Active : Status.Inactive

    const items: Item['subItems'] = previous.get(status) ?? []
    items.push(current)

    return previous.set(status, items)
  }, new Map<Status, Item['subItems']>())

  return (sortedSubItemsMap.get(Status.Active) ?? [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .concat(
      (sortedSubItemsMap.get(Status.Inactive) ?? []).sort((a, b) => a.name.localeCompare(b.name)),
      (sortedSubItemsMap.get(Status.Archived) ?? []).sort((a, b) => a.name.localeCompare(b.name))
    )
}
