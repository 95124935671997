import React, { createContext, useContext } from 'react'
import { Pagination } from '../../__generated__/graphql'

export interface PaginationInfos {
  page: number
  rowsPerPage: number
  count: number
  rowsPerPageOptions: number[]
}

type ContextValue = [PaginationInfos, React.Dispatch<React.SetStateAction<PaginationInfos>>]

const PaginationContext = createContext<ContextValue | null>(null)
PaginationContext.displayName = 'PaginationContext'

export type PaginationContextProviderProps = {
  state: ContextValue
}

export const DEFAULT_PAGINATION: PaginationInfos = {
  page: 0,
  rowsPerPage: 50,
  count: -1,
  rowsPerPageOptions: [50, 100, 200],
}

export const PaginationContextProvider: React.FC<PaginationContextProviderProps> = ({
  children,
  state,
}): JSX.Element => {
  return <PaginationContext.Provider value={state}>{children}</PaginationContext.Provider>
}

export function usePagination(): ContextValue {
  const value = useContext(PaginationContext)
  if (!value) throw new Error('Pagination was not used')
  return value
}

export const buildPagination = (paginationContext: PaginationInfos): Pagination => {
  let limit, offset

  if (paginationContext.rowsPerPage && paginationContext.rowsPerPage !== -1) limit = paginationContext.rowsPerPage

  if (paginationContext.rowsPerPage && paginationContext.rowsPerPage !== -1)
    offset = paginationContext.rowsPerPage * paginationContext.page

  return {
    limit,
    offset,
  }
}
