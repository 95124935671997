import { groupBy } from 'lodash-es'
import React, { useCallback, useMemo } from 'react'
import { ResponsiveContainer, LineChart, CartesianGrid, YAxis, Label, XAxis, Tooltip, Line, LabelList } from 'recharts'
import theme from '../../../styles/theme'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { StatsConf, StatsConfKeys } from '../../../utils/statsConf'
import { AdvertiserTimeseriesCost, PublisherTimeseriesCost } from '../../../__generated__/graphql'
import { ChartOptionsType } from '../../../utils/hooks/costDetails/useCostDetails'

const timeseriesChart: React.FC<{
  costDetails: {
    timeseries: AdvertiserTimeseriesCost[] | PublisherTimeseriesCost[]
  }
  chartItems: StatsConfKeys[]
  chartItemsVisibility: Record<StatsConfKeys, boolean>
  chartDateFormat: string
  isPublisherStats: boolean
  chartOptions: ChartOptionsType
}> = ({
  costDetails,
  chartItems,
  chartItemsVisibility,
  chartDateFormat,
  isPublisherStats,
  chartOptions,
}): JSX.Element => {
  const t = useAppTranslations()
  const timeSeries = useMemo(() => costDetails.timeseries || [], [costDetails])
  const { displayValues } = chartOptions

  const dataForGraph = useMemo(() => {
    return timeSeries.map(({ date, total }) => ({
      date: date.format(chartDateFormat),
      ...total,
    }))
  }, [costDetails])

  const getUnit = useCallback(
    (style) => {
      switch (style) {
        case 'percent':
          return '%'
        case 'currency':
          return '€'

        default:
          ''
      }
    },
    [costDetails]
  )

  const [lines, yAxises] = useMemo(() => {
    const statsToDisplay = chartItems
      .filter((name) => {
        const maybeItemVisibility = chartItemsVisibility[name as StatsConfKeys]
        return maybeItemVisibility !== undefined && maybeItemVisibility
      })
      .flatMap((name) => {
        const maybeConf = StatsConf[name]
        return maybeConf === undefined ? [] : [{ name, statStyle: maybeConf }]
      })

    const lines = statsToDisplay.map(({ name, statStyle }, i) => (
      <Line key={i} strokeWidth={2} type="monotone" dataKey={name} yAxisId={statStyle.family} stroke={statStyle.color}>
        {displayValues && <LabelList dataKey={name} position={'top'} fill={statStyle.color} offset={15} />}
      </Line>
    ))

    const styleAndOrientations = statsToDisplay
      .filter(
        (stat, index, all) => all.findIndex((toCheck) => toCheck.statStyle.family === stat.statStyle.family) === index
      )
      .map(({ statStyle }, index, all) => ({
        statStyle,
        orientation: (index < Math.round(all.length / 2) ? 'left' : 'right') as 'left' | 'right',
      }))

    const styleByOrientation = groupBy(styleAndOrientations, 'orientation')
    const sortedAxises = [...(styleByOrientation['left'] ?? []).reverse(), ...(styleByOrientation['right'] ?? [])]

    const yAxises = sortedAxises.map(({ statStyle, orientation }, index) => (
      <YAxis
        width={60}
        domain={[0, 'auto']}
        stroke={theme.palette.grey[300]}
        key={index}
        interval="preserveStartEnd"
        yAxisId={statStyle.family}
        orientation={orientation}
        tickLine={false}
        tickMargin={0}
        axisLine={false}
        tick={{
          stroke: theme.palette.grey[500],
          fontSize: 11,
          fontWeight: 0,
        }}
        unit={getUnit(statStyle.style)}
        tickFormatter={(tick) => {
          if (tick >= 1000 && tick < 1000000) return tick / 1000 + 'K'
          else if (tick >= 1000000) return tick / 1000000 + 'M'
          else return tick
        }}
      >
        <Label
          value={
            t(`stats.customDisplay.${isPublisherStats ? 'publisher' : 'advertiser'}.${statStyle.family}`) as string
          }
          angle={-90}
          position={orientation === 'left' ? `insideLeft` : `insideRight`}
          fill={theme.palette.grey[500]}
          fontSize={12}
        />
      </YAxis>
    ))

    return [lines, yAxises]
  }, [chartItemsVisibility, chartItems, chartOptions])

  const tooltipFormatter = (value: number, name: string): string =>
    Intl.NumberFormat('fr-FR', {
      style: StatsConf[name as StatsConfKeys]?.style,
      maximumFractionDigits: 2,
      currency: 'EUR',
    }).format(StatsConf[name as StatsConfKeys]?.style === 'percent' ? value / 100 : value)

  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={dataForGraph}>
        <CartesianGrid strokeDasharray="6 6" vertical={false} />
        {yAxises}

        <XAxis
          dataKey="date"
          stroke={theme.palette.grey[300]}
          tick={{
            stroke: theme.palette.grey[500],
            fontSize: 10,
          }}
        />
        <Tooltip formatter={tooltipFormatter} />
        {lines}
      </LineChart>
    </ResponsiveContainer>
  )
}

export default timeseriesChart
