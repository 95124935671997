import React from 'react'
import { useField } from 'formik'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { StyledFormLabel } from './styled'

export interface RadioButtonsGroupProps {
  name: string
  label: string
  options?: Array<Options>
  onMouseLeave?: () => void
}

export interface Options {
  value: string | number | boolean
  label: string
}

export interface RadioButtonProps {
  value: string | number | boolean
  label: string
  name: string
}

const RadioButton: React.FC<RadioButtonProps> = ({ value, label, name }) => {
  const t = useAppTranslations()
  const stringLabel: string = t(label)
  const [, , helpers] = useField({ name })
  const { setValue } = helpers
  return <FormControlLabel control={<Radio />} label={stringLabel} onChange={() => setValue(value)} value={value} />
}

const RadioButtonsGroup: React.FC<RadioButtonsGroupProps> = ({ name, label, options = [], onMouseLeave }) => {
  const t = useAppTranslations()
  const [, meta] = useField({ name })

  const { value } = meta

  const renderRadio = options.map((option, index) => (
    <RadioButton key={index} value={option.value} label={option.label} name={name} />
  ))

  return (
    <FormControl component="fieldset" onMouseLeave={onMouseLeave}>
      <StyledFormLabel>{t(label)}</StyledFormLabel>
      <RadioGroup row aria-label="gender" name={name} value={value}>
        {renderRadio}
      </RadioGroup>
    </FormControl>
  )
}
export default RadioButtonsGroup
