import React from 'react'
import { styled } from '@mui/system'
import { ReactComponent as PwsLogo } from '../../img/logo_powerspace_spinner.svg'

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  flexGrow: 1,
}))

const RotatingLogo = styled(PwsLogo)({
  width: '50px',
  height: '50px',
  animation: 'spin 0.7s linear infinite',
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
})

export const PwsSpinner = (): JSX.Element => {
  return (
    <DivContainer data-testid="pwsSpinner">
      <RotatingLogo />
    </DivContainer>
  )
}

export default PwsSpinner
