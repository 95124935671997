import { AllRoute } from '../pages/routeParams'

export const determineStatsTypeFromParams = (params: AllRoute): string => {
  let baseType = ''

  if (params.positionId) baseType = 'position'
  else if (params.siteId) baseType = 'website'
  else if (params.adgroupId) baseType = 'adgroup'
  else if (params.campaignId) baseType = 'campaign'
  else if (params.campaignProgrammaticId) baseType = 'campaign'

  if (baseType && params.slug) return `${baseType}_by_${params.slug}`

  if (params.slug && !baseType) return params.slug

  return baseType || 'report'
}
