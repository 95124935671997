import React from 'react'
import * as Yup from 'yup'
import { FormikStepProps } from '../../../components/form/wizzard/FormikStepper'
import { AdGroupCreateInput, AdGroupInput, Network, PriorityListQuery } from '../../../__generated__/graphql'
import { EditionModeContextProvider, EditionModes } from '../../../utils/providers/editionModeContext'
import InfosFields from '../settings/adGroupDetails/InfosFields'
import { urlRegexWithTrackingParams } from '../../../utils/UrlRegex'

export const detailsSchema = Yup.object().shape({
  name: Yup.string().min(3).required('commons.required'),
  isActive: Yup.boolean().required('commons.required'),
  redirectUrl: Yup.string()
    .matches(urlRegexWithTrackingParams, 'commons.invalidUrl')
    .min(3)
    .required('commons.required'),
  channels: Yup.array().of(Yup.string()),
  network: Yup.string().default(Network.Media).required('commons.required'),
  campaignId: Yup.string().required('commons.required'),
  format: Yup.string().required('commons.required'),
})

const AdgroupDetailStep: React.FC<
  FormikStepProps & {
    adgroup: AdGroupInput | AdGroupCreateInput
    priorityList: PriorityListQuery['campaign']['advertiser']['reseller']['priorities']
  }
> = ({ adgroup, priorityList }) => {
  return (
    <EditionModeContextProvider defaultMode={EditionModes.Edition}>
      <InfosFields adgroup={adgroup} creatingAdgroup={true} priorityList={priorityList} />
    </EditionModeContextProvider>
  )
}

export default AdgroupDetailStep
