import React from 'react'
import { Helmet } from 'react-helmet'
import LoggedOutLayoutWrapper from '../components/layout/LoggedOutLayoutWrapper'
import { Typography, Button } from '@mui/material'
import { DOMAIN } from '../env'
import { useAppTranslations } from '../utils/hooks/useAppTranslations'

const OldFrontRedirection: React.FC = (): JSX.Element => {
  const t = useAppTranslations()
  const handleDiscoverClick = (): void => {
    window.location.hostname = DOMAIN
  }
  return (
    <LoggedOutLayoutWrapper>
      <Helmet>
        <title>Powerspace Ads - Non intrusive advertising platform</title>
      </Helmet>
      <h1 style={{ textAlign: 'center' }}>{t('layout.newFrontVersion.title')}</h1>
      <Typography margin={'auto'} variant="body1">
        {t('layout.newFrontVersion.oldUrlWarning')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleDiscoverClick} sx={{ mt: 'auto' }} size="large">
        {t('layout.newFrontVersion.loginNewVersion')}
      </Button>
    </LoggedOutLayoutWrapper>
  )
}

export default OldFrontRedirection
