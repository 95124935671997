import { Dialog, DialogTitle, IconButton, DialogContent, Box, Breakpoint } from '@mui/material'
import { Cancel } from '@mui/icons-material'
import React, { Dispatch } from 'react'
import { useConfirmDialogContext } from '../../utils/providers/confirmDialogContext'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

export const FormDialog: React.FC<{
  open: boolean
  setOpen: Dispatch<React.SetStateAction<boolean>>
  title: string
  form: JSX.Element
  maxWidth?: Breakpoint
}> = ({ open, setOpen, title, form, maxWidth = 'lg' }) => {
  const t = useAppTranslations()
  const useConfirmDialog = useConfirmDialogContext()

  const handleClose = (): void => {
    useConfirmDialog({
      message: t('commons.closeDialogWithoutSaving'),
      buttonLabel: t('commons.actions.quitWithoutSaving'),
      onSubmit: () => setOpen(false),
    })
  }
  return (
    <Dialog maxWidth={maxWidth} fullWidth={true} open={open} onClose={handleClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 'h3.fontSize',
            fontWeight: 'bold',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
            }}
          >
            {title}
          </Box>
          <IconButton aria-label="close dialog button" onClick={() => setOpen(false)} autoFocus size="large">
            <Cancel />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{form}</DialogContent>
    </Dialog>
  )
}
