import { isNil } from 'lodash-es'
import { useEffect, useState } from 'react'

const getStorageValue = <T>(key: string, defaultValue: T): T => {
  const saved = localStorage.getItem(key)
  const localStorageValue = !isNil(saved) ? JSON.parse(saved) : {}
  return { ...defaultValue, ...localStorageValue }
}

export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
  excludeSubKeys: string[] = []
): [value: T, setValue: (newValue: T) => void] => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue))

  useEffect(() => {
    const valueToStore = { ...value } as Record<string, unknown>
    excludeSubKeys.forEach((k) => {
      delete valueToStore[k]
    })
    localStorage.setItem(key, JSON.stringify(valueToStore))
  }, [key, value])

  return [value, setValue]
}

export const removeCostDetailsFilters = (): void => {
  // To avoid bug if user is admin and change resellerId field in filters from '/home/report' and then connect with a normal user.
  localStorage.removeItem('costDetails.filters')
}
