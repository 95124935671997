import { Grid } from '@mui/material'
import React from 'react'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import { FieldsProps } from '.'
import { useFormikContext } from 'formik'
import { displayDateAsText } from '../../../../utils/displayDateAsText'
import dayjs from 'dayjs'
import { AdgroupExtendedSettingsFormInterface } from '../Settings'

const Dates: React.FC<FieldsProps> = ({ adgroup, isWizard = false, canEdit = true }): JSX.Element => {
  const {
    values: { startDate },
  } = useFormikContext<AdgroupExtendedSettingsFormInterface>()
  const DATE_FORMAT = 'DD/MM/YYYY'
  return (
    <Grid container item xs={3} spacing={1}>
      <Grid item xs={5}>
        <TransformDisplayToEdition
          type="date"
          inputType="datepicker"
          name="startDate"
          label="adgroup.budgetAndDates.startDate"
          text={displayDateAsText(adgroup.startDate, DATE_FORMAT)}
          InputLabelProps={{
            disableAnimation: true,
            shrink: true,
          }}
          tooltipText="adgroup.budgetAndDates.dateHelper"
          clearable
          disableHoverEdition={isWizard}
          editable={canEdit}
        />
      </Grid>
      <Grid item xs={5}>
        <TransformDisplayToEdition
          type="date"
          inputType="datepicker"
          minDate={startDate !== undefined ? dayjs(startDate) : undefined}
          name="endDate"
          label="adgroup.budgetAndDates.endDate"
          text={displayDateAsText(adgroup.endDate, DATE_FORMAT)}
          InputLabelProps={{
            disableAnimation: true,
            shrink: true,
          }}
          tooltipText="adgroup.budgetAndDates.dateHelper"
          clearable
          disableHoverEdition={isWizard}
          editable={canEdit}
        />
      </Grid>
    </Grid>
  )
}
export default Dates
