import { DialogContentClasses, DialogTitleClasses, DialogContentTextClasses } from '@mui/material'
import { StyleRules } from '@mui/styles'
import { theme } from './theme'

export const dialogTitleTheme: Partial<StyleRules<DialogTitleClasses>> = {
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.header,
      '& button': {
        marginLeft: '2rem',
      },
      '& div > span > svg': {
        verticalAlign: 'text-bottom',
        fontSize: '4rem',
        marginRight: '2rem',
      },
    },
  },
}

export const dialogContentTheme: Partial<StyleRules<DialogContentClasses>> = {
  styleOverrides: {
    root: {
      padding: '28px 35px',
    },
  },
}

export const dialogContentTextTheme: Partial<StyleRules<DialogContentTextClasses>> = {
  styleOverrides: {
    root: {
      color: theme.palette.text.primary,
    },
  },
}
