import React from 'react'
import { useParams } from 'react-router-dom'
import ErrorAlert from '../../../components/error/ErrorAlert'
import { StaticItem } from '../../../components/form/DisplayedItem'
import SectionTitle from '../../../components/form/SectionTitle'
import Loader from '../../../components/loader/Loader'
import { EditionModeContextProvider } from '../../../utils/providers/editionModeContext'
import { useProgrammaticCampaignQuery } from '../../../__generated__/graphql'
import { CampaignProgrammaticRoute } from '../../routeParams'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'

const ProgrammaticCampaignSummary: React.FC = () => {
  const { campaignProgrammaticId } = useParams<CampaignProgrammaticRoute>()
  const {
    data: programmaticCampaignData,
    loading,
    error,
  } = useProgrammaticCampaignQuery({ variables: { id: campaignProgrammaticId } })
  const t = useAppTranslations()

  const programmaticCampaign = programmaticCampaignData?.campaignProgrammatic

  if (loading) return <Loader />
  if (error) return <ErrorAlert message={error.message} />
  return (
    <EditionModeContextProvider>
      <SectionTitle title="programmaticCampaign.sectionTitle" isFirst />
      <StaticItem label={t('commons.name')}>{programmaticCampaign?.name}</StaticItem>
    </EditionModeContextProvider>
  )
}

export default ProgrammaticCampaignSummary
