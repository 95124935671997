import React, { Dispatch, SetStateAction } from 'react'
import { Typography, styled } from '@mui/material'
import { ColumnDef, Row } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import {
  WebsiteRouteBuilder,
  PositionRouteBuilder,
  AdGroupRouteBuilder,
  AdCopyRouteBuilder,
  AllRoute,
  AdTemplateRouteBuilder,
} from '../../../pages/routeParams'
import { StatsConf } from '../../statsConf'
import { SelectItemInterface } from '../../mapEnumForSelect'
import {
  ADGROUP_DATA_TYPE,
  CAMPAIGN_DATA_TYPE,
  COMPANY_BASE_STAT_DATA_TYPE,
  POSITION_DATA_TYPE,
  WEBSITE_DATA_TYPE,
} from '../../../interfaces/stats'
export { useAdvertiserStatsColumns } from './useAdvertiserStatsColumns'
export { usePublisherStatsColumns } from './usePublisherStatsColumns'

export const FirstColumn = styled('div')(() => ({
  width: '300px',
}))

export const OtherColumn = styled('div')(() => ({
  minWidth: '40px',
}))

export interface GroupColumnsType {
  group: number
  columnsName: Array<string>
}

interface MakeLinkInterface<T> {
  type: string
  id?: string
  row: Row<T>
}

export type HookOptions<T extends string> = {
  dateFormat: string
  selectedDataType: T
  selectedDataTypeChange: Dispatch<SetStateAction<T>>
  allDataTypes: SelectItemInterface[]
}

export const makeLink = <T,>(options: MakeLinkInterface<T>): string => {
  const { slug, adgroupId, positionId } = useParams<AllRoute>()
  const { type, id, row } = options
  if (!type || !id || !row) return ''
  if (type === COMPANY_BASE_STAT_DATA_TYPE.WEBSITES_AND_POSITIONS && row.depth === 0)
    return WebsiteRouteBuilder(slug, id)
  else if (type === COMPANY_BASE_STAT_DATA_TYPE.WEBSITES_AND_POSITIONS && row.depth === 1)
    return PositionRouteBuilder(slug, id)
  else if (type === WEBSITE_DATA_TYPE.POSITIONS) return PositionRouteBuilder(slug, id)
  if (type === POSITION_DATA_TYPE.ADTEMPLATES && positionId) return AdTemplateRouteBuilder(slug, positionId, id)
  if (type === CAMPAIGN_DATA_TYPE.ADGROUPS) return AdGroupRouteBuilder(slug, id)
  if (type === ADGROUP_DATA_TYPE.ADCOPIES && adgroupId) return AdCopyRouteBuilder(slug, adgroupId, id)
  else return ''
}

export const formatNumber = (value: string | number, style?: string): string => {
  if (typeof value !== 'number') return value
  const valueToDisplay = style === 'percent' ? value / 100 : value

  const maximumFractionDigits = 2
  const minimumFractionDigits = style === 'percent' ? maximumFractionDigits : 0

  return Intl.NumberFormat('fr-FR', {
    style: style,
    useGrouping: true,
    minimumFractionDigits,
    maximumFractionDigits,
    currency: 'EUR',
  }).format(valueToDisplay)
}

export const createNumericColumn = <T,>(
  columnName: string,
  header: string,
  group: number | undefined,
  total: T
): ColumnDef<T> => {
  return {
    accessorKey: columnName,
    header: () => <OtherColumn>{header}</OtherColumn>,
    cell: (info) => (
      <>{formatNumber((info.getValue() ?? 0) as number, StatsConf[columnName as keyof typeof StatsConf]?.style)}</>
    ),
    footer: () => (
      <>
        {formatNumber(
          (total[columnName as keyof typeof total] ?? 0) as number,
          StatsConf[columnName as keyof typeof StatsConf]?.style
        )}
      </>
    ),
    meta: group ? { group } : undefined,
  }
}

export const formatNameWithAdditionalInfo = (value: string, additionalInfo?: string): JSX.Element => {
  return (
    <Typography variant="body2" display={'inline-block'}>
      <span>{value}</span>
      {additionalInfo && (
        <>
          {' '}
          <span style={{ opacity: '0.4' }}>({additionalInfo})</span>
        </>
      )}
    </Typography>
  )
}
