import React, { createContext, useContext, useState } from 'react'

export enum EditionModes {
  Display,
  Edition,
}

type ContextValue = [EditionModes, React.Dispatch<React.SetStateAction<EditionModes>>]

const EditionModeContext = createContext<ContextValue | null>(null)
EditionModeContext.displayName = 'EditionModeContext'

export type EditionModeContextProviderProps = {
  defaultMode?: EditionModes
}

export const EditionModeContextProvider: React.FC<EditionModeContextProviderProps> = ({
  children,
  defaultMode = EditionModes.Display,
}): JSX.Element => {
  const state = useState<EditionModes>(defaultMode)

  return <EditionModeContext.Provider value={state}>{children}</EditionModeContext.Provider>
}

export function useEditionMode(): ContextValue {
  const value = useContext(EditionModeContext)
  if (!value) throw new Error('EditionMode was not used')
  return value
}
