import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import React, { Dispatch, useState } from 'react'
import { FormikValues } from 'formik'
import { FormikStepper } from '../../../components/form/wizzard/FormikStepper'
import prepareFormValues from '../../../utils/prepareFormValues'
import {
  ChannelType,
  CreatePositionMutation,
  Iab,
  Network,
  PositionCreateInput,
  SubChannelType,
  useCreatePositionMutation,
  WebsiteQuery,
} from '../../../__generated__/graphql'
import { fromPercentToFloat } from '../settings/Summary/SettingsSummary'
import CreatePositionDetailsStep, { summarySchema } from './steps/CreatePositionDetailsStep'
import CreatePositionSettingsStep, { settingsSchema } from './steps/CreatePositionSettingsStep'
import { Edit } from '@mui/icons-material'
import { useNavigation } from '../../../utils/hooks/useNavigation'
import CreatePositionWebsiteStep, { websiteSchema } from './steps/CreatePositionWebsiteStep'

const CreatePositionStepper: React.FC<{
  website?: WebsiteQuery['website']
  setOpen: Dispatch<React.SetStateAction<boolean>>
  onSuccess?: () => void
}> = ({ website, setOpen, onSuccess }) => {
  const t = useAppTranslations()
  const [error, setError] = React.useState(undefined)
  const [createdPosition, setCreatedPosition] = useState<CreatePositionMutation['createPosition'] | null>()
  const [createPosition] = useCreatePositionMutation()
  const navigation = useNavigation()

  const initialValues: PositionCreateInput = {
    name: '',
    iabCategory: website?.iabCategory || Iab.Iab24,
    channelType: ChannelType.Email,
    nbZone: 2,
    revenueShare: 60,
    websiteId: website?.id || '',
    network: Network.Media,
    subchannelType: SubChannelType.Newsletter,
    cpmFloor: 0,
  }

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const valuesWithFloatRevShare = Object.assign({}, values, {
      revenueShare: fromPercentToFloat(values.revenueShare),
    })
    await createPosition({
      variables: {
        newPosition: prepareFormValues(valuesWithFloatRevShare) as PositionCreateInput,
      },
    })
      .then((res) => {
        const createdPosition = res?.data?.createPosition
        if (createdPosition) {
          if (typeof onSuccess === 'function') onSuccess()
          setCreatedPosition(createdPosition)
        }
      })
      .catch((err) => setError(err))
  }

  const successButton = {
    label: t('position.goToPosition', { campaignName: createdPosition?.name }),
    startIcon: <Edit />,
    onClick: () => {
      if (createdPosition) navigation.goToPosition(createdPosition?.website.publisher.slug, createdPosition?.id)
      setOpen(false)
    },
  }

  return (
    <FormikStepper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      enableReinitialize={true}
      successMessage={t('position.create.successMessage')}
      setOpen={setOpen}
      successActionButton={successButton}
      mergeSteps={true}
    >
      {!initialValues.websiteId && (
        <CreatePositionWebsiteStep validationSchema={websiteSchema} label={t('position.create.websiteStep.title')} />
      )}

      <CreatePositionDetailsStep validationSchema={summarySchema} label={t('position.create.detailStep')} />
      <CreatePositionSettingsStep validationSchema={settingsSchema} label={t('commons.settings')} />
    </FormikStepper>
  )
}

export default CreatePositionStepper
