import { useHistory, useLocation } from 'react-router-dom'
import { CGU_VERSION } from '../../../env'
import { PWS_COMPANY_ID, useCurrentUser, useIsPwsUser } from '../useUserContext'
import { useEffect } from 'react'
import { CompanyType } from '../../../__generated__/graphql'

export const useCguRedirection = (): void => {
  const { me } = useCurrentUser()
  const { company } = me
  const { slug, approvedTerms, agencyId, resellerId, type } = company
  const history = useHistory()
  const location = useLocation()
  const isPwsUser = useIsPwsUser()

  useEffect(() => {
    const getShouldRedirect = (): boolean => {
      if (isPwsUser) return false
      switch (type) {
        case CompanyType.Advertiser:
          return resellerId === PWS_COMPANY_ID && !agencyId && (!approvedTerms || approvedTerms.version !== CGU_VERSION)
          break

        case CompanyType.Publisher:
          return resellerId === PWS_COMPANY_ID && (!approvedTerms || approvedTerms.version !== CGU_VERSION)
          break

        case CompanyType.Agency:
          return resellerId === PWS_COMPANY_ID && (!approvedTerms || approvedTerms.version !== CGU_VERSION)
          break

        case CompanyType.Reseller:
          return !approvedTerms || approvedTerms.version !== CGU_VERSION
          break

        default:
          return false
      }
    }

    const shouldRedirect = getShouldRedirect()

    if (shouldRedirect) history.push(`/${type.toLowerCase()}/${slug}/terms`)
  }, [location.pathname, approvedTerms, slug, slug])
}
