import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { listOption } from '../../../components/form/AutoCompleteField'
import {
  useCampaignLazyQuery,
  useSearchCampaignsLazyQuery,
  CampaignQuery,
  SearchCampaignsQuery,
  useGetCompanyQuery,
} from '../../../__generated__/graphql'
import AsyncAutoCompleteField from '../../../components/form/AsyncAutoCompleteField'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { AdvertiserRoute } from '../../routeParams'
import { useParams } from 'react-router-dom'

const AsyncAutoCompleteCampaignField: React.FC = () => {
  const t = useAppTranslations()
  const [queryName, setQueryName] = useState<string>('')
  const [campaignList, setCampaignList] = useState<listOption[]>([])
  const [field] = useField('campaignId')
  const { slug } = useParams<AdvertiserRoute>()
  const { data: currentCompany } = useGetCompanyQuery({
    variables: {
      slug: slug,
    },
  })

  // Query when field value is empty (clean step)
  const [getCampaigns, { data: campaigns }] = useSearchCampaignsLazyQuery()
  // Query when field value is set (back from another step)
  const [getCurrentlySelectedCampaign, { data: currentlySelectedCampaign }] = useCampaignLazyQuery()

  useEffect(() => {
    if (queryName === '' && field.value !== '')
      getCurrentlySelectedCampaign({
        variables: {
          campaignId: field.value,
        },
      })
    else
      getCampaigns({
        variables: {
          searchQuery: {
            isArchived: false,
            companyId: currentCompany?.company.id,
            ...(queryName && { name: queryName }),
          },
          pagination: {},
        },
      })
  }, [queryName, currentCompany])

  type anySourceCampaign = SearchCampaignsQuery['searchCampaigns'][number] | CampaignQuery['campaign']

  const reformatCampaigns = (campaigns: Array<anySourceCampaign>): Array<listOption> => {
    return campaigns.map((campaign) => ({
      value: campaign.id,
      label: campaign.name,
    }))
  }

  // Set proper campaignList depending on which campaign query was called
  useEffect(() => {
    if (campaigns) setCampaignList(reformatCampaigns(campaigns.searchCampaigns))
    else if (currentlySelectedCampaign && currentlySelectedCampaign.campaign)
      setCampaignList(reformatCampaigns([currentlySelectedCampaign.campaign]))
  }, [campaigns, currentlySelectedCampaign])

  return (
    <AsyncAutoCompleteField
      getValue={(value) => setQueryName(value)}
      label={t('adgroup.details.campaignId')}
      name="campaignId"
      value={''}
      options={campaignList}
      disableClearable={true}
      required
    />
  )
}

export default AsyncAutoCompleteCampaignField
