import React from 'react'
import { Box, Fade } from '@mui/material'

const FadeWrapper: React.FC<{ appear?: boolean; timeout?: number }> = ({ appear = true, children, timeout = 750 }) => {
  return (
    <Fade in appear={appear} timeout={timeout}>
      <Box>{children}</Box>
    </Fade>
  )
}

export default FadeWrapper
