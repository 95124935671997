import DialogTitle from '@mui/material/DialogTitle'
import { EditLocation } from '@mui/icons-material'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'
import React from 'react'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import InputField from '../../../../components/form/InputField'
import { useField } from 'formik'

type DialogCustomProps = {
  closeDialog: () => void
  open: boolean
  cityFieldName: string
}

export const UpdateRadiusDialog: React.FC<DialogCustomProps> = ({
  closeDialog,
  cityFieldName: name,
  open,
}): JSX.Element => {
  const [, meta] = useField({ name })
  const t = useAppTranslations()
  return (
    <Dialog open={open} onClose={closeDialog}>
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.6rem', fontWeight: 'bold' }}>
          <span style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <EditLocation color="secondary" />
            {t('adgroup.geoTargeting.radiusDialogTitle', { city: meta.value.name })}
          </span>
          <Button onClick={closeDialog} autoFocus variant="contained" type="button">
            {t('commons.actions.close')}
          </Button>
        </div>
      </DialogTitle>
      <DialogContent>
        <InputField name={`${name}.radius`} label={'Radius'} type={'number'} />
      </DialogContent>
    </Dialog>
  )
}
