import React, { useMemo, useState } from 'react'
import { CostDetailsTable } from '../../../../components/stats/table/CostDetailsTable'
import { FormattedInventoryCostDetails, INVENTORY_DATA_TYPE } from '../../../../interfaces/stats'
import {
  mapTotalData,
  mapData,
  mapTimeSeriesData,
  mapPositionsAndWebsitesData,
} from '../../../../utils/costDetailsDataMap'
import { useAdvertiserStatsColumns } from '../../../../utils/hooks/costDetails/useStatsColumns'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import { useUserPermissions } from '../../../company/currentCompanyDisplayedContext'
import { withTableSkeletonAndErrorHandling } from '../../../../components/layout/withTableSkeletonAndErrorHandling'

const InventoryCostDetailsTable: React.FC<{
  costDetails: FormattedInventoryCostDetails | null
  dateFormat: string
}> = ({ costDetails, dateFormat }): JSX.Element => {
  const permissions = useUserPermissions()
  const allDataTypes = mapEnumForSelect(INVENTORY_DATA_TYPE).filter((type) =>
    type.value === 'resellers' ? permissions.superAdmin : true
  )
  const [selectedDataType, setSelectedDataType] = useState(INVENTORY_DATA_TYPE.TIMESERIES)

  const totalData = useMemo(() => mapTotalData(costDetails?.total), [costDetails])
  const deviceData = useMemo(() => mapData(costDetails?.devices), [costDetails])
  const timeSeriesData = useMemo(() => mapTimeSeriesData(costDetails?.timeseries), [costDetails])
  const resellerData = useMemo(() => mapData(costDetails?.resellers ?? []), [costDetails])
  const positionsAndWebsitesData = useMemo(() => mapPositionsAndWebsitesData(costDetails?.websites), [costDetails])

  const dataTypeMap = useMemo(
    () => ({
      [INVENTORY_DATA_TYPE.TIMESERIES]: timeSeriesData,
      [INVENTORY_DATA_TYPE.RESELLERS]: resellerData,
      [INVENTORY_DATA_TYPE.WEBSITES_AND_POSITIONS]: positionsAndWebsitesData,
      [INVENTORY_DATA_TYPE.DEVICES]: deviceData,
    }),
    [selectedDataType, costDetails]
  )

  const dataToDisplay = useMemo(() => dataTypeMap[selectedDataType] || deviceData, [dataTypeMap, selectedDataType])

  const columns = useAdvertiserStatsColumns(selectedDataType, totalData, {
    dateFormat,
    allDataTypes,
    selectedDataType,
    selectedDataTypeChange: setSelectedDataType,
  })
  return <CostDetailsTable columns={columns} toDisplay={dataToDisplay} />
}

export default withTableSkeletonAndErrorHandling(InventoryCostDetailsTable)
