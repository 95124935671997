import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useCostDetailsFiltersStorage } from '../../../../components/stats/filters/useStatsFilterStorage'
import {
  useProgrammaticCampaignCostDetailsQuery,
  useProgrammaticCampaignTimeseriesQuery,
} from '../../../../__generated__/graphql'
import { CampaignProgrammaticRoute } from '../../../routeParams'
import { FormattedProgrammaticCampaignCostDetails, StatsEntity } from '../../../../interfaces/stats'
import SectionTitle from '../../../../components/form/SectionTitle'
import { StatsFilters } from '../../../../components/stats/filters/StatsFilters'
import ProgrammaticCampaignCostDetailsTable from './ProgrammaticCampaignCostDetailsTable'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { flatResellersWebsites } from '../../../../utils/hooks/costDetails/costDetailsUtils'
import { useTimeseries } from '../../../../utils/hooks/costDetails/useTimeseries'
import Timeseries from '../../../../components/stats/timeseries/Timeseries'

const Stats: React.FC = () => {
  const t = useAppTranslations()
  const { campaignProgrammaticId } = useParams<CampaignProgrammaticRoute>()
  const [costDetailsDatesFilters, setCostDetailsDatesFilters] = useCostDetailsFiltersStorage()
  const statsVariables = {
    id: campaignProgrammaticId,
    startDate: costDetailsDatesFilters.startDate,
    endDate: costDetailsDatesFilters.endDate,
  }
  const {
    data: costDetailsQueryData,
    loading: costDetailsLoading,
    error: costDetailsError,
  } = useProgrammaticCampaignCostDetailsQuery({
    variables: statsVariables,
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: timeSeriesQueryData,
    loading: timeseriesLoading,
    error: timeseriesError,
  } = useProgrammaticCampaignTimeseriesQuery({
    variables: {
      ...statsVariables,
      granularity: costDetailsDatesFilters.granularity,
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    timeseriesData,
    chartItemsVisibility,
    toggleVisibility,
    chartOptions,
    setChartOptions,
    chartItems,
    dateFormat,
    setDateFormat,
  } = useTimeseries(timeSeriesQueryData?.campaignProgrammatic.timeseries, {
    filters: costDetailsDatesFilters,
    entity: StatsEntity.ProgrammaticCampaign,
  })

  const formattedCostsDetails: FormattedProgrammaticCampaignCostDetails | null = useMemo(() => {
    if (!costDetailsQueryData?.campaignProgrammatic?.costDetails) return null
    const websites = flatResellersWebsites(costDetailsQueryData?.campaignProgrammatic?.costDetails.resellers)

    return {
      ...costDetailsQueryData?.campaignProgrammatic.costDetails,
      websites,
      timeseries: timeseriesData?.timeseries ?? [],
    }
  }, [costDetailsQueryData, timeseriesData])

  return (
    <>
      <SectionTitle title={t('stats.title')} />
      <StatsFilters
        filters={costDetailsDatesFilters}
        onChange={setCostDetailsDatesFilters}
        setChartDateFormat={setDateFormat}
        chartOptions={chartOptions}
        setChartOptions={setChartOptions}
      />
      <Timeseries
        timeseriesData={timeseriesData}
        chartItems={chartItems}
        chartItemsVisibility={chartItemsVisibility}
        toggleVisibility={toggleVisibility}
        isPublisherStats={false}
        dateFormat={dateFormat}
        chartOptions={chartOptions}
        loading={timeseriesLoading}
        error={timeseriesError}
      />
      <ProgrammaticCampaignCostDetailsTable
        costDetails={formattedCostsDetails}
        dateFormat={dateFormat}
        error={costDetailsError}
        loading={costDetailsLoading || timeseriesLoading}
        tableSkeletonProps={{
          colNumbers: 7,
          lineNumbers: 5,
        }}
      />
    </>
  )
}

export default Stats
