import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useCostDetailsFiltersStorage } from '../../../../components/stats/filters/useStatsFilterStorage'
import { useInventoryCostDetailsQuery, useInventoryTimeseriesQuery } from '../../../../__generated__/graphql'
import { CampaignProgrammaticRoute } from '../../../routeParams'
import { StatsEntity } from '../../../../interfaces/stats'
import FadeWrapper from '../../../../components/transitions/FadeWrapper'
import { PaperWithPadding } from '../../../../components/layout/PaperWithPadding'
import { Box } from '@mui/material'
import SectionTitle from '../../../../components/form/SectionTitle'
import { StatsFilters } from '../../../../components/stats/filters/StatsFilters'
import InventoryCostDetailsTable from './InventoryCostDetailsTable'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { flatResellersWebsites } from '../../../../utils/hooks/costDetails/costDetailsUtils'
import { useTimeseries } from '../../../../utils/hooks/costDetails/useTimeseries'
import Timeseries from '../../../../components/stats/timeseries/Timeseries'

const Stats: React.FC = () => {
  const t = useAppTranslations()
  const { inventoryId } = useParams<CampaignProgrammaticRoute>()
  const [costDetailsDatesFilters, setCostDetailsDatesFilters] = useCostDetailsFiltersStorage()
  const statsVariables = {
    id: inventoryId,
    startDate: costDetailsDatesFilters.startDate,
    endDate: costDetailsDatesFilters.endDate,
  }
  const {
    data: costDetailsData,
    loading: costDetailsLoading,
    error: costDetailsError,
  } = useInventoryCostDetailsQuery({
    variables: statsVariables,
    notifyOnNetworkStatusChange: true,
  })

  const {
    data: timeseriesQueryData,
    loading: timeseriesLoading,
    error: timeseriesError,
  } = useInventoryTimeseriesQuery({
    variables: {
      ...statsVariables,
      granularity: costDetailsDatesFilters.granularity,
    },
    notifyOnNetworkStatusChange: true,
  })

  const {
    timeseriesData,
    chartItemsVisibility,
    toggleVisibility,
    chartOptions,
    setChartOptions,
    chartItems,
    dateFormat,
    setDateFormat,
  } = useTimeseries(timeseriesQueryData?.inventory.timeseries, {
    filters: costDetailsDatesFilters,
    entity: StatsEntity.Inventory,
  })

  const formattedCostsDetails = useMemo(() => {
    if (!costDetailsData?.inventory.costDetails) return null
    const websites = flatResellersWebsites(costDetailsData?.inventory?.costDetails.resellers)

    return {
      ...costDetailsData?.inventory.costDetails,
      websites,
      timeseries: timeseriesData?.timeseries ?? [],
    }
  }, [costDetailsData])

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <PaperWithPadding>
        <FadeWrapper>
          <>
            <SectionTitle title={t('stats.title')} isFirst />
            <StatsFilters
              filters={costDetailsDatesFilters}
              onChange={setCostDetailsDatesFilters}
              setChartDateFormat={setDateFormat}
              chartOptions={chartOptions}
              setChartOptions={setChartOptions}
            />
            <Timeseries
              timeseriesData={timeseriesData}
              chartItems={chartItems}
              chartItemsVisibility={chartItemsVisibility}
              toggleVisibility={toggleVisibility}
              isPublisherStats={false}
              dateFormat={dateFormat}
              chartOptions={chartOptions}
              loading={timeseriesLoading}
              error={timeseriesError}
            />
            <InventoryCostDetailsTable
              costDetails={formattedCostsDetails}
              dateFormat={dateFormat}
              loading={costDetailsLoading || timeseriesLoading}
              error={costDetailsError}
              tableSkeletonProps={{
                colNumbers: 7,
                lineNumbers: 5,
              }}
            />
          </>
        </FadeWrapper>
      </PaperWithPadding>
    </Box>
  )
}

export default Stats
