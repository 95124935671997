import { FormLabelClasses } from '@mui/material'
import { StyleRules } from '@mui/styles'
import { theme } from './theme'

export const formLabelTheme: Partial<StyleRules<FormLabelClasses>> = {
  styleOverrides: {
    asterisk: {
      color: theme.palette.error.main,
    },
  },
}
