import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert, AlertTitle, Slide } from '@mui/material'
import MuiSnackbar from '@mui/material/Snackbar'
import { useSnackbarManager } from '../../utils/providers/snackbarContext'
import { SlideProps } from '@mui/material/Slide'

type TransitionProps = Omit<SlideProps, 'direction'>
const TRANSITION_DURATION = 400

const TransitionRight = (props: TransitionProps): JSX.Element => {
  return <Slide {...props} direction="down" />
}

const Snackbar: React.FC = () => {
  const [snackbar, setSnackbar] = useSnackbarManager()
  const [showSnackbar, setShowSnackbar] = useState(false)

  useEffect(() => setShowSnackbar(!!snackbar), [snackbar, setSnackbar])

  useEffect(() => {
    let timeout: NodeJS.Timeout

    if (!showSnackbar)
      timeout = setTimeout(() => {
        setSnackbar(undefined)
      }, TRANSITION_DURATION)

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [showSnackbar])

  const isError = useMemo(() => {
    return snackbar?.severity === 'error'
  }, [snackbar])

  const onSnackbarClose = useCallback((_, reason) => {
    if (reason === 'clickaway') return
    setShowSnackbar(false)
  }, [])

  return (
    <MuiSnackbar
      open={showSnackbar}
      onClose={onSnackbarClose}
      autoHideDuration={isError ? undefined : 5000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      TransitionComponent={TransitionRight}
      transitionDuration={TRANSITION_DURATION}
    >
      <Alert
        severity={snackbar?.severity}
        elevation={1}
        sx={isError ? { p: 1 } : undefined}
        onClose={() => setShowSnackbar(false)}
        data-testid="snackbarAlert"
      >
        {isError && <AlertTitle>Error</AlertTitle>}
        {snackbar?.message &&
          (Array.isArray(snackbar.message) ? (
            snackbar.message.map((line, index) => <div key={index}>{line}</div>)
          ) : (
            <>{snackbar.message}</>
          ))}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
