import { Button, Grid, MenuItem } from '@mui/material'
import { useField } from 'formik'
import React, { useMemo, useState } from 'react'
import { getLocationType } from '.'
import { StyledDescription } from '../../../../components/form/styled'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import { useAdgroupContext } from '../../../../utils/hooks/adGroup/useAdgroupContext'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { useCountries } from '../../../../utils/hooks/useCountries'
import mapEnumForSelect from '../../../../utils/mapEnumForSelect'
import { AdGroupTargeting, Country } from '../../../../__generated__/graphql'
import ToggleDisplay from '../../../../components/layout/collapsible/ShowMoreButtonToggle'
import CSVAddressesUploadForm from './CSVAddressesUploadForm'
import { FormDialog } from '../../../../components/layout/FormDialog'
import { useCanEditAdGroup } from '../../../../utils/hooks/adGroup/useCanEditAdGroup'

export interface GeoTargetingFieldsProps {
  adgroupTargeting: AdGroupTargeting
  countryCode?: string
}

export enum LocationTypesEnum {
  Cities = 'cities',
  Departements = 'departements',
  Regions = 'regions',
  Countries = 'countries',
}

const GeoTargetingFields: React.FC<GeoTargetingFieldsProps> = ({ adgroupTargeting, countryCode }): JSX.Element => {
  const canEditAdGroup = useCanEditAdGroup()
  const t = useAppTranslations()
  const { data: countryData, countryCodeToCountryObjectArray } = useCountries()
  const [, typeMeta] = useField('targeting.type')
  const { cities, departements, regions, countries, geoExclusive } = adgroupTargeting.geoTargeting
  const [adgroup] = useAdgroupContext()
  const [ruleExceptionIsActive, setRuleExceptionIsActive] = useState(false)
  const [uploadAddressesModal, setUploadAddressesModal] = useState(false)

  const geographicRuleExclusionContainerStyle = {
    marginLeft: '35px',
    width: '70%',
  }
  const geoExclusiveOptions = [
    { value: false, label: 'commons.include' },
    { value: true, label: 'commons.exclude' },
  ]

  const locationTypesOptions = useMemo(
    () =>
      mapEnumForSelect(LocationTypesEnum).map((type, i) => (
        <MenuItem value={type.value} key={i}>
          {t(type.label)}
        </MenuItem>
      )),
    []
  )

  const countriesOptions = useMemo(
    () =>
      countryData?.getCountries.map((country) => (
        <MenuItem value={country.code} key={country.code}>
          {country.label}
        </MenuItem>
      )),
    [countryData]
  )

  const locationsTextToDisplay = useMemo((): string => {
    let textArray
    switch (getLocationType(adgroupTargeting.geoTargeting)) {
      case LocationTypesEnum.Cities:
        textArray = cities?.map((city) => `${city.name} - ${city.radius}km`)
        break
      case LocationTypesEnum.Departements:
        textArray = departements?.map((departement) => departement.name)
        break
      case LocationTypesEnum.Regions:
        textArray = regions?.map((region) => region.name)
        break
      case LocationTypesEnum.Countries:
        textArray = (countryCodeToCountryObjectArray(countries) as Country[]).map((country) => country.label)
        break
    }
    return textArray?.join('\n') || ''
  }, [adgroupTargeting])

  return (
    <Grid item container>
      <Grid item xs={10}>
        <TransformDisplayToEdition
          label="adgroup.geoTargeting.rule"
          options={geoExclusiveOptions}
          name="targeting.geoExclusive"
          text={geoExclusive ? t('commons.exclude') : t('commons.include')}
          inputType={'radio'}
          type={'text'}
          disableHoverEdition
          editable={canEditAdGroup}
        />
      </Grid>
      <Grid item xs={10}>
        <TransformDisplayToEdition
          type="text"
          inputType="select"
          withNone={false}
          name="targeting.type"
          label="adgroup.geoTargeting.locationType"
          text={t(getLocationType(adgroupTargeting.geoTargeting))}
          tooltipText="adgroup.geoTargeting.locationTypeHelper"
          disableHoverEdition
          variant="outlined"
          editable={canEditAdGroup}
        >
          {locationTypesOptions}
        </TransformDisplayToEdition>
      </Grid>
      <Grid item xs={10}>
        <TransformDisplayToEdition
          label="adgroup.geoTargeting.location"
          name={'targeting.' + typeMeta.value}
          text={locationsTextToDisplay}
          inputType={'autoCompleteGoogleMap'}
          type={'text'}
          locationType={typeMeta.value}
          countryCode={countryCode}
          disableHoverEdition
          editable={canEditAdGroup}
        />
      </Grid>
      <Grid item xs={10}>
        <Button
          onClick={() => setUploadAddressesModal(true)}
          variant="text"
          color="secondary"
          sx={{ mt: 3 }}
          disabled={!canEditAdGroup}
        >
          {t('adgroup.geoTargeting.importCSV')}
        </Button>
      </Grid>
      {uploadAddressesModal && (
        <FormDialog
          open={uploadAddressesModal}
          setOpen={() => setUploadAddressesModal(false)}
          title={t('adgroup.geoTargeting.importCSV')}
          form={<CSVAddressesUploadForm setOpen={() => setUploadAddressesModal(false)} />}
          maxWidth="sm"
        />
      )}
      <ToggleDisplay
        handleClick={() => setRuleExceptionIsActive(!ruleExceptionIsActive)}
        isDisplayed={ruleExceptionIsActive}
      >
        {t('adgroup.rulesExceptionsLabel')}
      </ToggleDisplay>
      {ruleExceptionIsActive && (
        <Grid item xs={12}>
          <div style={geographicRuleExclusionContainerStyle}>
            <StyledDescription>{t(`adgroup.rulesExceptions`)}</StyledDescription>
            <Grid container>
              <Grid item xs={8}>
                <TransformDisplayToEdition
                  label="adgroup.geoTargeting.acceptedCountries"
                  data-testid="acceptedCountries"
                  name={'authorizedCountries'}
                  text={adgroup.authorizedCountries.map((country) => country.label).join('\n')}
                  inputType={'select'}
                  type={'text'}
                  multiple={true}
                  disableHoverEdition
                  variant="outlined"
                  editable={canEditAdGroup}
                >
                  {countriesOptions}
                </TransformDisplayToEdition>
              </Grid>
              <Grid item xs={8}>
                <TransformDisplayToEdition
                  type={'text'}
                  data-testid="redirectUrl"
                  name={'countryBlacklistRedirectUrl'}
                  label={'adgroup.redirectUrl'}
                  text={adgroup.countryBlacklistRedirectUrl}
                  multiline={true}
                  disableHoverEdition
                  variant="outlined"
                  editable={canEditAdGroup}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      )}
    </Grid>
  )
}
export default GeoTargetingFields
