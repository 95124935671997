import React, { Fragment } from 'react'
import { Divider, Grid, Skeleton } from '@mui/material'

export interface TableSkeletonProps {
  colNumbers: number
  lineNumbers?: number
  lineHeight?: number
}

const TableSkeleton: React.FC<TableSkeletonProps> = ({ colNumbers, lineNumbers = 15, lineHeight = 35 }) => {
  return (
    <>
      <Grid container spacing={1} sx={{ bgcolor: 'grey.100', p: 1, mt: 1 }}>
        {[...Array(colNumbers)].map((_, i) => (
          <Grid item xs key={`header-${i}`}>
            <Skeleton width={'30%'} height={35} />
          </Grid>
        ))}
      </Grid>
      <Divider />
      {[...Array(lineNumbers)].map((_, i) => (
        <Fragment key={`line-${i}`}>
          <Grid container spacing={1} mb={1}>
            {[...Array(colNumbers)].map((_, i) => (
              <Grid item xs key={i}>
                <Skeleton width={'80%'} height={lineHeight} />
              </Grid>
            ))}
          </Grid>
          <Divider />
        </Fragment>
      ))}
    </>
  )
}

export default TableSkeleton
