import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { usePositionLazyQuery } from '../../../__generated__/graphql'
import { WebsiteRoute, PositionRoute } from '../../../pages/routeParams'
import { isString } from 'lodash-es'

export const useWebsiteIdFinder = (): number | string | null => {
  const { siteId, positionId } = useParams<WebsiteRoute | PositionRoute>()
  const [getCurrentPositionData, { data: currentPositionData }] = usePositionLazyQuery()

  useEffect(() => {
    if (isString(positionId) && positionId.length > 0) getCurrentPositionData({ variables: { id: positionId } })
  }, [positionId])

  const maybeCurrentWebsiteId = useMemo(() => {
    if (siteId) return siteId
    else if (currentPositionData) return currentPositionData?.position.website.id
    else return null
  }, [siteId, currentPositionData])

  return maybeCurrentWebsiteId
}
