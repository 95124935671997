import React from 'react'
import { Skeleton } from '@mui/material'

const SidebarSkeleton: React.FC<{ itemsNumber?: number }> = ({ itemsNumber = 5 }) => {
  return (
    <>
      {[...Array(itemsNumber)].map((_, i) => (
        <Skeleton key={`item-${i}`} width={150} height={26} sx={{ my: 1, mx: 2 }} />
      ))}
    </>
  )
}

export default SidebarSkeleton
