import React from 'react'
import FadeWrapper from '../../../components/transitions/FadeWrapper'
import { PaperWithPadding } from '../../../components/layout/PaperWithPadding'
import InventorySummary from '../summary/InventorySummary'

import Stats from './stats/Stats'
import InactiveCompanyWarning from '../../../components/warning/InactiveCompanyWarning'

const Inventory: React.FC = () => {
  return (
    <FadeWrapper>
      <InactiveCompanyWarning />
      <PaperWithPadding>
        <InventorySummary />
        <Stats />
      </PaperWithPadding>
    </FadeWrapper>
  )
}

export default Inventory
