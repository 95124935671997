import { TableFooter, TableRow, TablePagination } from '@mui/material'
import React from 'react'
import { usePagination } from '../../utils/providers/paginationContext'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

type DatatablePaginationProps = {
  rowsDisplayed: number
  colSpan?: number
}

const DatatablePagination: React.FC<DatatablePaginationProps> = ({ colSpan = 1, rowsDisplayed }) => {
  const t = useAppTranslations()
  const [paginationContext, setPaginationContext] = usePagination()

  const onChangePage = React.useCallback(
    (_, page) => setPaginationContext({ ...paginationContext, page }),
    [paginationContext, setPaginationContext]
  )

  const onChangeRowsPerPage = React.useCallback(
    (event) =>
      setPaginationContext({
        ...paginationContext,
        page: 0,
        rowsPerPage: event?.target?.value ? parseInt(event.target.value, 10) : 10,
      }),
    [paginationContext, setPaginationContext]
  )

  const calculatedCount = React.useMemo(() => {
    if (rowsDisplayed < paginationContext.rowsPerPage)
      return paginationContext.page * paginationContext.rowsPerPage + rowsDisplayed
    else return -1
  }, [rowsDisplayed, paginationContext])

  function labelPageNumber(): React.ReactElement<HTMLParagraphElement> {
    return (
      <span data-testid="pageNumber" style={{ marginRight: '20px' }}>
        {t('commons.pageNumber', { currentPage: paginationContext.page + 1 })}
      </span>
    )
  }
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={paginationContext.rowsPerPageOptions}
          colSpan={colSpan}
          count={paginationContext.count === -1 ? calculatedCount : paginationContext.count}
          rowsPerPage={paginationContext.rowsPerPage}
          page={paginationContext.page}
          labelDisplayedRows={labelPageNumber}
          SelectProps={{
            inputProps: { 'aria-label': t('commons.rowsPerPage') },
            native: true,
          }}
          style={{ borderRight: 'none' }}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
        />
      </TableRow>
    </TableFooter>
  )
}
export default DatatablePagination
