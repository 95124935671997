import React from 'react'
import { ApolloError } from '@apollo/client'
import ErrorAlert from '../error/ErrorAlert'
import TableSkeleton, { TableSkeletonProps } from '../loader/TableSkelton'
import { ErrorBoundary } from '../error/ErrorBoundary'
import FadeWrapper from '../transitions/FadeWrapper'

export interface TableSkeletonAndErrorProps {
  error?: ApolloError
  loading: boolean
  tableSkeletonProps: TableSkeletonProps
}

export function withTableSkeletonAndErrorHandling<P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & TableSkeletonAndErrorProps> {
  return ({ error, loading, tableSkeletonProps, ...props }: P & TableSkeletonAndErrorProps) => {
    if (loading) return <TableSkeleton {...tableSkeletonProps} />
    if (error) return <ErrorAlert message={error.message} />
    return (
      <ErrorBoundary>
        <FadeWrapper appear>
          <Component {...(props as P)} />
        </FadeWrapper>
      </ErrorBoundary>
    )
  }
}
