import React, { useCallback } from 'react'
import { Box, SxProps, TextField, TextFieldProps, Theme } from '@mui/material'
import { useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import HelperTooltip from './HelperTooltip'

export interface InputFieldProps {
  name: string
  label: string
  type: string
  autoFocus?: boolean
  fullWidth?: boolean
  multiline?: boolean
  rows?: number
  margin?: TextFieldProps['margin']
  defaultHelperText?: string
  placeholder?: string
  required?: boolean
  InputProps?: TextFieldProps['InputProps']
  InputLabelProps?: TextFieldProps['InputLabelProps']
  sx?: SxProps<Theme>
  onMouseLeave?: () => void
  tooltipText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  onFocus?: () => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  label,
  type,
  defaultHelperText = '',
  margin = 'normal',
  placeholder,
  onMouseLeave,
  InputLabelProps,
  tooltipText,
  variant = 'standard',
  onChange,
  ...props
}) => {
  const t = useAppTranslations()
  const [field, meta] = useField({ name })

  const hasError = Boolean(meta.touched && meta.error)
  const helperText = (meta.error && hasError && t(meta.error)) || defaultHelperText

  const handleChange = useCallback(
    (event) => {
      field.onChange(event)
      if (onChange !== undefined) onChange(event)
    },
    [onchange]
  )

  return (
    <Box onMouseLeave={onMouseLeave}>
      <TextField
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        id={name}
        type={type}
        label={
          <>
            {t(label)}
            {tooltipText && <HelperTooltip helperText={tooltipText} />}
          </>
        }
        margin={margin}
        variant={variant}
        size="small"
        error={hasError}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth
        {...field}
        {...props}
        onChange={handleChange}
      />
    </Box>
  )
}

export default InputField
