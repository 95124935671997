import { useHistory } from 'react-router-dom'
import {
  AdCopyRouteBuilder,
  AdGroupRouteBuilder,
  AdTemplateRouteBuilder,
  CampaignRouteBuilder,
  CompanyRouteBuilder,
  PositionRouteBuilder,
  WebsiteRouteBuilder,
} from '../../pages/routeParams'
import { CompanyType } from '../../__generated__/graphql'

export function useNavigation() {
  const history = useHistory()

  return {
    goToCompany: (slug: string, type: CompanyType) => history.push(CompanyRouteBuilder(slug, type)),

    goToAdgroup: (slug: string, adgroupId: string) => history.push(AdGroupRouteBuilder(slug, adgroupId)),

    goToCampaign: (slug: string, campaignId: string) => history.push(CampaignRouteBuilder(slug, campaignId)),

    goToWebsite: (slug: string, websiteId: string) => history.push(WebsiteRouteBuilder(slug, websiteId)),

    goToPosition: (slug: string, positionId: string) => history.push(PositionRouteBuilder(slug, positionId)),

    goToAdcopy: (slug: string, adgroupId: string, adcopyId: string) =>
      history.push(AdCopyRouteBuilder(slug, adgroupId, adcopyId)),

    goToAdTemplate: (slug: string, positionId: string, adTemplateId: string) =>
      history.push(AdTemplateRouteBuilder(slug, positionId, adTemplateId)),
  }
}
