import { omit } from './omit'

/**
 * @deprecated This function is deprecated, it should returns type fo toUpdate object. Use generateMutationInput instead.
 */
export const filterMutationInput = <T, U>(toUpdate: T, newData: Partial<U>, toDelete: string[] = []): U => {
  const returnedObject = Object.assign({}, toUpdate, newData)
  return omit(returnedObject, toDelete) as U
}

export const generateMutationInput = <T, U>(toUpdate: T, newData: Partial<U>, toDelete: string[] = []): T => {
  const returnedObject = Object.assign({}, toUpdate, newData)
  return omit(returnedObject, toDelete) as T
}
