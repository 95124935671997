import React from 'react'
import { Help } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { Theme } from '@emotion/react'
import { SxProps } from '@mui/material'

const HelperTooltip: React.FC<{ helperText: string; sx?: SxProps<Theme> }> = ({ helperText, sx }) => {
  const t = useAppTranslations()
  return (
    <Tooltip title={t(helperText) as string}>
      <Help sx={{ fontSize: '15px', color: 'primary.main', marginLeft: '6.5px', ...sx }} />
    </Tooltip>
  )
}

export default HelperTooltip
