export const sanitizeUrl = (url: string): string => {
  return url.replace(/(\r\n|\n|\r)/gm, '')
}

// encode url does not work with some urls (cf https://welcoming-group.atlassian.net/browse/PWSF-694)
export const encodeSubUrls = (url: string): string => {
  const [baseUrl, queryParams] = url.split('?')
  if (!queryParams) return url
  const encodedParams = queryParams.replace(/(https?:\/\/[^\s&]+)/g, (subUrl) => encodeURIComponent(subUrl))
  return `${baseUrl}?${encodedParams}`
}

export const sanitizeAndEncodeUrl = (url: string): string => {
  const sanitizedUrl = sanitizeUrl(url)
  return encodeSubUrls(sanitizedUrl)
}
