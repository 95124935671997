import React, { useCallback } from 'react'
import * as Yup from 'yup'
import { Grid } from '@mui/material'
import { EditionModeContextProvider, EditionModes, useEditionMode } from '../../../utils/providers/editionModeContext'
import { Inventory, Status, useInventoryQuery } from '../../../__generated__/graphql'
import { FormikValues, Formik } from 'formik'
import SectionTitle from '../../../components/form/SectionTitle'
import TransformDisplayToEdition from '../../../components/form/TransformDisplayToEdition'
import HasPermission from '../../../components/permissions/HasPermission'
import { PageStructure } from '../../../utils/interfaces/PageStructure'
import { useParams } from 'react-router-dom'
import { InventoryRoute } from '../../routeParams'
import ErrorAlert from '../../../components/error/ErrorAlert'
import Loader from '../../../components/loader/Loader'
import DisplayedItem from '../../../components/form/DisplayedItem'
import { initialTouchedForAll } from '../../../utils/initialTouchedForAll'
import FormWithRouteLeavingGuard from '../../../components/form/FormWIthRouteLeavingGuard'
import { useCanEditInventory } from '../../../utils/hooks/inventory/useCanEditInventory'

export interface InventoryInfosFormProps {
  inventory: Pick<Inventory, 'name' | 'isActive' | 'format'>
}

export interface InventoryInfosFormInterface extends FormikValues {
  name: string
  isActive: boolean
}

export const InventoryInfosFormStructure: PageStructure = {
  title: {
    display: 'adgroup.sectionTitle',
    edit: 'adgroup.edit.sectionTitle',
  },
  button: {
    cancel: 'cancelAdgroupInfos',
    save: 'saveAdgroupInfos',
    edit: 'editAdgroupInfos',
  },
  input: {
    name: 'adgroup.name',
    status: 'commons.status',
    format: 'adgroup.format',
  },
}

export const InventoryInfosForm: React.FC<InventoryInfosFormProps> = ({ inventory }) => {
  const canEditInventory = useCanEditInventory()
  const structure = InventoryInfosFormStructure

  const [editionModeContext, setEditionModeContext] = useEditionMode()
  const initialValues: InventoryInfosFormInterface = {
    name: inventory.name,
    isActive: inventory.isActive,
  }

  const inventoryUpdateSchema = Yup.object().shape({
    name: Yup.string().min(3).required('commons.required'),
    isActive: Yup.boolean().required('commons.required'),
  })

  const editHandler = useCallback(
    (values: InventoryInfosFormInterface) => {
      console.log(values)
    },
    [inventory, setEditionModeContext]
  )

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={editHandler}
      validationSchema={inventoryUpdateSchema}
      initialTouched={initialTouchedForAll({ ...initialValues })}
      validateOnMount={true}
    >
      {(formik) => (
        <FormWithRouteLeavingGuard>
          <SectionTitle
            canSubmit={formik.isValid && formik.dirty}
            title={editionModeContext === EditionModes.Display ? structure.title.display : structure.title.edit}
            defaultButtons
            isFirst
          />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TransformDisplayToEdition
                type={'text'}
                name={'name'}
                required={true}
                label={structure.input.name}
                text={inventory.name}
                editable={canEditInventory}
              />
            </Grid>
            <Grid item xs={6}>
              <TransformDisplayToEdition
                inputType={'switch'}
                type={'text'}
                name={'isActive'}
                required={true}
                label={structure.input.status}
                text={inventory.isActive ? Status.Active : Status.Inactive}
                editable={canEditInventory}
              />
            </Grid>
            <HasPermission permissionRequired="banner" roleRequired="read">
              <Grid item xs={4}>
                <DisplayedItem label={structure.input.format} name="format">
                  {inventory?.format}
                </DisplayedItem>
              </Grid>
            </HasPermission>
          </Grid>
        </FormWithRouteLeavingGuard>
      )}
    </Formik>
  )
}

const InventorySummary: React.FC = () => {
  const { inventoryId } = useParams<InventoryRoute>()
  const { data: inventoryData, loading, error } = useInventoryQuery({ variables: { id: inventoryId } })

  if (loading) return <Loader />
  if (error) return <ErrorAlert message={error.message} />

  return (
    <>
      <EditionModeContextProvider>
        {inventoryData?.inventory && <InventoryInfosForm inventory={inventoryData?.inventory} />}
      </EditionModeContextProvider>
    </>
  )
}

export default InventorySummary
