import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent, styled } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import mapEnumForSelect from '../../../utils/mapEnumForSelect'
import dayjs from 'dayjs'

export enum Timeframe {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_DAY_LAST_WEEK = 'thisDayLastWeek',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_YEAR = 'thisYear',
  LAST_3_DAYS = 'last3Days',
  LAST_30_DAYS = 'last30Days',
  LAST_60_DAYS = 'last60Days',
  LAST_MONTH = 'lastMonth',
  LAST_3_MONTH = 'last3Month',
  LAST_YEAR = 'lastYear',
  CUSTOM = 'custom',
}

export const DateFormat = 'YYYY-MM-DD'

export const computeTimeframeValues = (): Record<Timeframe, { startDate: string; endDate: string }> => ({
  [Timeframe.TODAY]: { startDate: dayjs().format(DateFormat), endDate: dayjs().format(DateFormat) },
  [Timeframe.YESTERDAY]: {
    startDate: dayjs().subtract(1, 'days').format(DateFormat),
    endDate: dayjs().subtract(1, 'days').format(DateFormat),
  },
  [Timeframe.LAST_3_DAYS]: {
    startDate: dayjs().subtract(2, 'days').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.THIS_DAY_LAST_WEEK]: {
    startDate: dayjs().subtract(7, 'days').format(DateFormat),
    endDate: dayjs().subtract(7, 'days').format(DateFormat),
  },
  [Timeframe.THIS_WEEK]: {
    startDate: dayjs().startOf('week').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.LAST_30_DAYS]: {
    startDate: dayjs().subtract(29, 'days').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.LAST_60_DAYS]: {
    startDate: dayjs().subtract(59, 'days').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.LAST_MONTH]: {
    startDate: dayjs().subtract(1, 'month').startOf('month').format(DateFormat),
    endDate: dayjs().subtract(1, 'month').endOf('month').format(DateFormat),
  },
  [Timeframe.LAST_3_MONTH]: {
    startDate: dayjs().subtract(3, 'month').startOf('month').format(DateFormat),
    endDate: dayjs().subtract(1, 'month').endOf('month').format(DateFormat),
  },
  [Timeframe.THIS_MONTH]: {
    startDate: dayjs().startOf('month').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.THIS_YEAR]: {
    startDate: dayjs().startOf('year').format(DateFormat),
    endDate: dayjs().format(DateFormat),
  },
  [Timeframe.LAST_YEAR]: {
    startDate: dayjs().subtract(1, 'year').startOf('year').format(DateFormat),
    endDate: dayjs().subtract(1, 'year').endOf('year').format(DateFormat),
  },
  [Timeframe.CUSTOM]: {
    startDate: '',
    endDate: '',
  },
})

const StyledFormControl = styled(FormControl)({
  width: '100%',
})

export const DateRangeSelect: React.FC = (): JSX.Element => {
  const t = useAppTranslations()
  const formik = useFormikContext()
  const [timeFrame, setTimeFrame] = useState(Timeframe.CUSTOM)
  const startDate = dayjs(formik.getFieldMeta<string>('startDate').value).format(DateFormat)
  const endDate = dayjs(formik.getFieldMeta<string>('endDate').value).format(DateFormat)
  const allTimeFrame = Object.keys(computeTimeframeValues()) as Timeframe[]

  useEffect(() => {
    const timeframe = allTimeFrame.find(
      (key) =>
        computeTimeframeValues()[key as Timeframe].startDate === startDate &&
        computeTimeframeValues()[key as Timeframe].endDate === endDate
    )
    if (timeframe) setTimeFrame(timeframe)
    else setTimeFrame(Timeframe.CUSTOM)
  }, [startDate, endDate])

  const onChange = (event: SelectChangeEvent<Timeframe>): void => {
    const {
      target: { value },
    } = event

    const selectedTimeframe = computeTimeframeValues()[value as Timeframe]
    if (value === Timeframe.CUSTOM) return

    formik.setFieldValue('startDate', selectedTimeframe.startDate)
    formik.setFieldValue('endDate', selectedTimeframe.endDate)
    formik.validateForm()
  }

  const timeframes = mapEnumForSelect(Timeframe)
  const timeframesOptions = timeframes.map((type, i) => (
    <MenuItem value={type.value as Timeframe} key={i}>
      {t(`stats.filter.timeframeValues.${type.label}`)}
    </MenuItem>
  ))

  return (
    <StyledFormControl size="small" sx={{ marginTop: '0' }}>
      <InputLabel id="cost-details-timeframe">{t('stats.filter.timeframe')}</InputLabel>
      <Select
        labelId="cost-details-timeframe"
        value={timeFrame}
        onChange={onChange}
        input={<OutlinedInput label={t('stats.filter.timeframe')} />}
        renderValue={(selected) => <>{t(`stats.filter.timeframeValues.${selected}`)}</>}
        autoWidth={true}
      >
        {timeframesOptions}
      </Select>
    </StyledFormControl>
  )
}
