export interface SelectItemInterface {
  label: string
  value: string
}

const mapEnumForSelect = (enumToUse: Record<string, string>): Array<SelectItemInterface> => {
  return Object.values(enumToUse).map((value) => ({ label: value, value: value }))
}

export default mapEnumForSelect
