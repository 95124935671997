import React from 'react'
import Button from '@mui/material/Button'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { IconProps, styled, SxProps, Theme } from '@mui/material'
import { useEditionMode, EditionModes } from '../../utils/providers/editionModeContext'
import Tooltip from '@mui/material/Tooltip'
import { Cancel, Help, Save } from '@mui/icons-material'
import { H2SectionTitle, SectionHeader } from './styled'
import { isNil } from 'lodash-es'
import { useFormikContext } from 'formik'
import theme from '../../styles/theme'
import FormikErrorsTooltip from './FormikErrorsTooltipWrapper'

const helpStyle = {
  fontSize: '1.2rem',
  color: 'primary.main',
  marginLeft: '0.5rem',
}

const SpanSecondaryText = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  fontSize: '1rem',
}))

const HelperTooltip: React.FC<{ helperText: string }> = ({ helperText }) => {
  return (
    <Tooltip title={helperText}>
      <Help sx={helpStyle} />
    </Tooltip>
  )
}

export interface actionButton {
  startIcon: IconProps
  onClick?: () => void | undefined | Promise<void>
  label: string
  cancelButton?: boolean
  buttonType?: 'button' | 'reset' | 'submit' | undefined
  externalButton?: boolean
  testingId?: string
}

interface SectionTitleProps {
  canSubmit?: boolean
  title: string
  actionButtons?: Array<actionButton>
  helperText?: string
  secondaryText?: string
  sx?: SxProps<Theme>
  isFirst?: boolean
  defaultButtons?: boolean
  withFormik?: boolean
  globalSave?: boolean
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  actionButtons,
  canSubmit,
  helperText,
  secondaryText,
  isFirst,
  sx,
  defaultButtons = false,
  withFormik = true,
  globalSave = false,
  children,
}) => {
  const t = useAppTranslations()
  const margin = !isNil(isFirst) && isFirst ? { marginTop: 0 } : {}

  const globalSaveStyle = { position: globalSave ? 'fixed' : undefined, top: globalSave ? '106px' : undefined } as const

  return (
    <SectionHeader sx={{ ...sx, ...margin }}>
      <H2SectionTitle style={{ marginTop: isFirst ? 0 : theme.spacing(2) }}>
        {t(title)}
        {helperText && <HelperTooltip helperText={helperText} />}
        {secondaryText && <SpanSecondaryText>{` - ${secondaryText}`}</SpanSecondaryText>}
      </H2SectionTitle>
      <div style={{ marginTop: isFirst ? -24 : 0, ...globalSaveStyle }}>
        {actionButtons && <ActionButtons actionButtons={actionButtons} canSubmit={canSubmit} />}
        {withFormik && defaultButtons && <HoverModeActionButtons actionButtons={actionButtons} canSubmit={canSubmit} />}
        {children}
      </div>
    </SectionHeader>
  )
}

const ActionButtons: React.FC<{
  actionButtons: Array<actionButton>
  canSubmit: SectionTitleProps['canSubmit']
}> = ({ actionButtons, canSubmit }) => {
  const t = useAppTranslations()
  const [editionModeContext] = useEditionMode()

  const displayedButtonsByEditionMode = actionButtons.filter(
    (button) =>
      button.externalButton ||
      (editionModeContext === EditionModes.Edition
        ? button.label.includes('save') || button.label.includes('cancel')
        : button.label.includes('edit') || button.label.includes('add'))
  )

  return (
    <>
      {displayedButtonsByEditionMode.map((button) => {
        const { cancelButton = false, buttonType = 'button' } = button
        return (
          <Button
            data-testid={button.testingId}
            key={button.label}
            color={cancelButton ? 'inherit' : 'primary'}
            type={buttonType}
            disabled={buttonType === 'submit' && !canSubmit}
            size="small"
            onClick={button.onClick || undefined}
            startIcon={button.startIcon}
          >
            {t(button.label)}
          </Button>
        )
      })}
    </>
  )
}

const HoverModeActionButtons: React.FC<{
  actionButtons?: Array<actionButton>
  canSubmit: SectionTitleProps['canSubmit']
}> = ({ actionButtons, canSubmit }) => {
  const t = useAppTranslations()
  const formik = useFormikContext()

  const defaultButtons: actionButton[] = [
    {
      startIcon: <Cancel />,
      onClick: () => {
        formik.resetForm()
      },
      label: 'commons.actions.cancel',
      cancelButton: true,
      testingId: 'cancel',
    },
    {
      startIcon: <Save />,
      label: 'commons.actions.save',
      buttonType: 'submit',
      testingId: 'save',
    },
  ]

  const displayedButtonsByFormState = (actionButtons || defaultButtons).filter(
    (button) =>
      button.externalButton || (formik.dirty && (button.label.includes('save') || button.label.includes('cancel')))
  )

  return (
    <>
      {displayedButtonsByFormState.map((button) => {
        const { cancelButton = false, buttonType = 'button' } = button
        if (buttonType === 'submit')
          return (
            <FormikErrorsTooltip key={button.label} formikErrors={formik.errors}>
              <Button
                data-testid={button.testingId}
                color={'primary'}
                type={buttonType}
                disabled={!canSubmit}
                size="small"
                startIcon={button.startIcon}
              >
                {t(button.label)}
              </Button>
            </FormikErrorsTooltip>
          )

        return (
          <Button
            key={button.label}
            data-testid={button.testingId}
            color={cancelButton ? 'inherit' : 'primary'}
            type={buttonType}
            size="small"
            onClick={button.onClick || undefined}
            startIcon={button.startIcon}
          >
            {t(button.label)}
          </Button>
        )
      })}
    </>
  )
}

export default SectionTitle
