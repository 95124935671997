import React, { useContext } from 'react'
import { GetCompanyDetailsQuery, useGetCompanyDetailsQuery } from '../../__generated__/graphql'

const CompanyDetailsContext = React.createContext<undefined | GetCompanyDetailsQuery>(undefined)

export const CompanyDetailsContextProvider: React.FC<{ companySlug: string }> = ({ companySlug, children }) => {
  const { data: companyDetails } = useGetCompanyDetailsQuery({ variables: { slug: companySlug } })
  if (!companyDetails) return null
  return <CompanyDetailsContext.Provider value={companyDetails}>{children}</CompanyDetailsContext.Provider>
}

export const useCompanyDetailsContext = (): GetCompanyDetailsQuery => {
  const value = useContext(CompanyDetailsContext)
  if (!value) throw new Error('Value not found in company details context')
  return value
}
