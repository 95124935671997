import { Country, GetCountriesQuery, useGetCountriesQuery } from '../../__generated__/graphql'
import { compact, find } from 'lodash-es'

type useCountriesInterface = {
  data: GetCountriesQuery | undefined
  loading: boolean
  countryCodeToCountryObjectArray: (codeArray: string[]) => Country[] | []
}

export const useCountries = (): useCountriesInterface => {
  const { data, loading } = useGetCountriesQuery()

  const countryCodeToCountryObjectArray = (codeArray: string[]): Country[] | [] => {
    if (!data) return []
    return compact(codeArray.map((countryCode) => find(data.getCountries, ['code', countryCode])))
  }

  return { data, loading, countryCodeToCountryObjectArray }
}
