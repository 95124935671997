import React, { useContext } from 'react'
import { PublisherConfigQuery, usePublisherConfigQuery } from '../../../__generated__/graphql'
import { useCurrentCompanyDisplayedContext } from '../../../pages/company/currentCompanyDisplayedContext'
import PwsSpinner from '../../../components/loader/PwsSpinner'

const PublisherConfigContext = React.createContext<undefined | PublisherConfigQuery['publisher']>(undefined)
PublisherConfigContext.displayName = 'PublisherConfigContext'

export const PublisherConfigContextProvider: React.FC = ({ children }) => {
  const {
    company: { id },
  } = useCurrentCompanyDisplayedContext()
  const { data: publisherConfig, loading } = usePublisherConfigQuery({ variables: { id } })

  return (
    <PublisherConfigContext.Provider value={publisherConfig?.publisher}>
      {loading ? <PwsSpinner /> : children}
    </PublisherConfigContext.Provider>
  )
}

export const usePublisherConfigContext = (): PublisherConfigQuery['publisher'] => {
  const value = useContext(PublisherConfigContext)
  if (!value) throw new Error('Value not found in publisher config context')
  return value
}
