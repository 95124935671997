import React from 'react'
import Layout from '../components/layout/Layout'
import { UserContextProvider } from '../utils/hooks/useUserContext'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import Sidebar from '../components/layout/sidebar/Sidebar'
import Grid from '@mui/material/Grid'
import { CurrentCompanyDisplayedProvider } from './company/currentCompanyDisplayedContext'
import { ThemeContext } from '../utils/providers/themeContext'
import {
  AdvertiserRouteUrl,
  AdGroupRouteUrl,
  AllRoute,
  CampaignRouteUrl,
  AgencyRouteUrl,
  PositionRouteUrl,
  WebsiteRouteUrl,
  PublisherRouteUrl,
  TradingDeskRouteUrl,
  InventoryRouteUrl,
  CampaignProgrammaticRouteUrl,
  ActivityRouteUrl,
  AdCopyRouteUrl,
  ResellerRouteUrl,
  ExternalAdGroupLinkUrl,
} from './routeParams'
import { Box } from '@mui/material'
import { ConfirmDialogContextProvider } from '../utils/providers/confirmDialogContext'
import CampaignProgrammatic from './programmaticCampaign/dashboard/Dashboard'
import Inventory from './inventory/dashboard/Dashboard'
import Activity from './activity/Activity'
import { CompanyDetailsContextProvider } from '../utils/hooks/useCompanyDetailsContext'
import Loader from '../components/loader/Loader'
import { ErrorBoundary } from '../components/error/ErrorBoundary'
import { AdvertiserConfigContextProvider } from '../utils/hooks/advertiser/useAdvertiserConfigContext'
import { PublisherConfigContextProvider } from '../utils/hooks/publisher/usePublisherConfigContext'
import { useCguRedirection } from '../utils/hooks/company/useCguCheck'
import AdGroupToFullLinkRedirect from './adgroup/AdGroupToFullLinkRedirect'

const Home = React.lazy(() => import('./home/Home'))
const Website = React.lazy(() => import('./website/Website'))
const Position = React.lazy(() => import('./position/Position'))
const Campaign = React.lazy(() => import('./campaign/Campaign'))
const Adgroup = React.lazy(() => import('./adgroup/Adgroup'))
const Agency = React.lazy(() => import('./agency/Agency'))
const Publisher = React.lazy(() => import('./publisher/Publisher'))
const Advertiser = React.lazy(() => import('./advertiser/Advertiser'))
const TradingDesk = React.lazy(() => import('./tradingDesk/TradingDesk'))
const Reseller = React.lazy(() => import('./reseller/Reseller'))

const AdvertiserRoutes = (): JSX.Element => {
  const { campaignId, adgroupId, campaignProgrammaticId, inventoryId } = useParams<AllRoute>()

  return (
    <AdvertiserConfigContextProvider>
      <Switch>
        {campaignProgrammaticId && (
          <Route>
            <React.Suspense fallback={<Loader />}>
              <CampaignProgrammatic />
            </React.Suspense>
          </Route>
        )}
        {campaignId && (
          <Route exact>
            <React.Suspense fallback={<Loader />}>
              <Campaign />
            </React.Suspense>
          </Route>
        )}
        {inventoryId && (
          <Route>
            <React.Suspense fallback={<Loader />}>
              <Inventory />
            </React.Suspense>
          </Route>
        )}
        {adgroupId && (
          <Route>
            <React.Suspense fallback={<Loader />}>
              <Adgroup />
            </React.Suspense>
          </Route>
        )}
        <Route path={AdvertiserRouteUrl}>
          <React.Suspense fallback={<Loader />}>
            <Advertiser />
          </React.Suspense>
        </Route>
        <Route path={TradingDeskRouteUrl}>
          <React.Suspense fallback={<Loader />}>
            <TradingDesk />
          </React.Suspense>
        </Route>
        <Route path={AgencyRouteUrl}>
          <React.Suspense fallback={<Loader />}>
            <Agency />
          </React.Suspense>
        </Route>
      </Switch>
    </AdvertiserConfigContextProvider>
  )
}

const PublisherRoutes = (): JSX.Element => {
  const { siteId, positionId } = useParams<AllRoute>()

  return (
    <PublisherConfigContextProvider>
      <Switch>
        {siteId && (
          <Route>
            <React.Suspense fallback={<Loader />}>
              <Website />
            </React.Suspense>
          </Route>
        )}
        {positionId && (
          <Route>
            <React.Suspense fallback={<Loader />}>
              <Position />
            </React.Suspense>
          </Route>
        )}
        <Route path={PublisherRouteUrl}>
          <React.Suspense fallback={<Loader />}>
            <Publisher />
          </React.Suspense>
        </Route>
      </Switch>
    </PublisherConfigContextProvider>
  )
}

const LayoutWithSidebar = (): JSX.Element => {
  useCguRedirection()
  const { slug } = useParams<AllRoute>()

  return (
    <Grid container wrap="nowrap">
      <CurrentCompanyDisplayedProvider>
        <Grid item style={{ height: '100%' }}>
          <Sidebar companySlug={slug} />
        </Grid>

        <Grid item style={{ height: '100%', flex: 1, overflow: 'hidden' }}>
          <ErrorBoundary>
            <Switch>
              <Route path={ActivityRouteUrl}>
                <React.Suspense fallback={<Loader />}>
                  <CompanyDetailsContextProvider companySlug={slug}>
                    <Activity />
                  </CompanyDetailsContextProvider>
                </React.Suspense>
              </Route>
              <Route path={[WebsiteRouteUrl, PositionRouteUrl, PublisherRouteUrl]} component={PublisherRoutes} />
              <Route
                path={[
                  CampaignProgrammaticRouteUrl,
                  CampaignRouteUrl,
                  InventoryRouteUrl,
                  AdGroupRouteUrl,
                  ExternalAdGroupLinkUrl,
                  AdCopyRouteUrl,
                  AdvertiserRouteUrl,
                  TradingDeskRouteUrl,
                  AgencyRouteUrl,
                ]}
                component={AdvertiserRoutes}
              />
            </Switch>
          </ErrorBoundary>
        </Grid>
      </CurrentCompanyDisplayedProvider>
    </Grid>
  )
}

const AppRouter = (): JSX.Element => {
  return (
    <UserContextProvider>
      <ConfirmDialogContextProvider>
        <ThemeContext>
          <Layout>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'row',
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <Switch>
                <Route path={ExternalAdGroupLinkUrl}>
                  <React.Suspense fallback={<Loader />}>
                    <AdGroupToFullLinkRedirect />
                  </React.Suspense>
                </Route>
                <Route
                  path={[
                    ActivityRouteUrl,
                    WebsiteRouteUrl,
                    PositionRouteUrl,
                    PublisherRouteUrl,
                    CampaignProgrammaticRouteUrl,
                    CampaignRouteUrl,
                    InventoryRouteUrl,
                    AdGroupRouteUrl,
                    ExternalAdGroupLinkUrl,
                    AdCopyRouteUrl,
                    AdvertiserRouteUrl,
                    TradingDeskRouteUrl,
                    AgencyRouteUrl,
                  ]}
                  component={LayoutWithSidebar}
                />

                <Route path="/home">
                  <React.Suspense fallback={<Loader />}>
                    <Home />
                  </React.Suspense>
                </Route>

                <Route path={ResellerRouteUrl}>
                  <React.Suspense fallback={<Loader />}>
                    <Reseller />
                  </React.Suspense>
                </Route>

                <Route>
                  <Redirect to={'/home'} />
                </Route>
              </Switch>
            </Box>
          </Layout>
        </ThemeContext>
      </ConfirmDialogContextProvider>
    </UserContextProvider>
  )
}

export default AppRouter
