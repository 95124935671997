import { Button, FormControlLabel, Grid, MenuItem, Switch, styled } from '@mui/material'
import { Form, Formik, FormikValues, useField } from 'formik'
import React, { PropsWithChildren, useEffect, useMemo } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import {
  ChannelType,
  CostDetailsDimensions,
  GraphGranularity,
  Network,
  useGetCompanyDetailsQuery,
} from '../../../__generated__/graphql'
import SelectField from '../../form/SelectField'
import * as Yup from 'yup'
import { DateFormat, DateRangeSelect, Timeframe } from './StatsDateRange'
import dayjs, { OpUnitType, QUnitType } from 'dayjs'
import DateField from '../../form/DateField'
import { CostDetailsTimeFilters } from './useStatsFilterStorage'
import { GRAPH_PERIOD_LENGTH } from '../../../env'
import { ChartOptionsType, CHART_DATE_FORMAT } from '../../../utils/hooks/costDetails/useCostDetails'
import { useCurrentUser, useIsPwsUser } from '../../../utils/hooks/useUserContext'
import { useParams } from 'react-router-dom'
import { AllRoute } from '../../../pages/routeParams'
import { PWS_RESELLER_ID } from '../../../utils/hooks/reseller/useIsPowerspaceReseller'

export const StyledStatsFiltersGridItem = styled(Grid)({
  width: '200px',
})

export type StatsFiltersProps<T extends CostDetailsTimeFilters> = {
  filters: T
  onChange: (update: T) => void
  setChartDateFormat: React.Dispatch<React.SetStateAction<string>>
  chartOptions: ChartOptionsType
  setChartOptions: React.Dispatch<React.SetStateAction<ChartOptionsType>>
  costDetailsDimensions?: CostDetailsDimensions[]
}

export const StatsFilters = <T extends CostDetailsTimeFilters>({
  filters,
  onChange,
  setChartDateFormat,
  chartOptions,
  setChartOptions,
  children,
  costDetailsDimensions,
}: PropsWithChildren<StatsFiltersProps<T>>): JSX.Element => {
  const { displayValues } = chartOptions
  const t = useAppTranslations()

  const otherFilters = {
    ...(costDetailsDimensions ?? []).reduce(
      (dimensions, dimension) => ({ ...dimensions, [dimension]: [] }),
      {} as Record<CostDetailsDimensions, string[]>
    ),
    ...filters.otherFilters,
  }

  const initialValues = {
    ...filters,
    otherFilters,
    timeframe: Timeframe.CUSTOM,
  }

  const validationSchema = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date(),
  })

  const onFilterSubmit = (values: FormikValues): void => {
    setChartDateFormat(CHART_DATE_FORMAT[values.granularity as GraphGranularity])
    const endDateIsToday = dayjs(values.endDate).isSame(dayjs(), 'day')
    onChange({
      ...filters,
      ...values,
      endDate: endDateIsToday ? dayjs().format() : values.endDate,
    })
  }

  const handleDisplayValuesSwitch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChartOptions({ ...chartOptions, displayValues: event.target.checked })
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onFilterSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formik) => {
        const endDateIsNotToday = !dayjs().isSame(formik.values.endDate, 'day')

        return (
          <Form>
            <Grid container item spacing={3} justifyContent="flex-start" alignItems="center" xs={12}>
              <StyledStatsFiltersGridItem item>
                <DateRangeSelect />
              </StyledStatsFiltersGridItem>
              <Dates />
              <StyledStatsFiltersGridItem item>
                <AvailableGranularity startDate={formik.values.startDate} endDate={formik.values.endDate} />
              </StyledStatsFiltersGridItem>
              {(costDetailsDimensions ?? []).map((dimension) => (
                <Dimension key={dimension.toString()} dimension={dimension} />
              ))}
              {children}
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ marginTop: '8px' }}
                  disabled={!formik.dirty && endDateIsNotToday}
                >
                  {t('commons.actions.filter')}
                </Button>
              </Grid>
              <Grid item textAlign={'right'}>
                <FormControlLabel
                  control={<Switch color="primary" checked={displayValues} onChange={handleDisplayValuesSwitch} />}
                  label="Display values"
                  sx={{ fontSize: '1.4rem', color: 'rgba(0, 0, 0, 0.6)' }}
                />
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export const AvailableGranularity = ({ startDate, endDate }: { startDate: string; endDate: string }): JSX.Element => {
  const t = useAppTranslations()
  const name = 'granularity'

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta, helpers] = useField<GraphGranularity>({ name })
  const { value: selectedValue } = meta
  const { setValue: setSelectedValue } = helpers

  const granularities = useMemo(() => {
    const endDateMoment = dayjs(endDate).endOf('day')
    const startDateMoment = dayjs(startDate)
    const periods: Array<QUnitType | OpUnitType> = ['month', 'week', 'day', 'hour']

    const isValidPeriod = (period: QUnitType | OpUnitType): boolean =>
      Math.ceil(endDateMoment.diff(startDateMoment, period, true)) <= GRAPH_PERIOD_LENGTH

    const availaibleGranularities: {
      value: GraphGranularity
      label: string
    }[] = [{ value: GraphGranularity.Year, label: t('commons.year') }]

    periods.some((period) => {
      const isValid = isValidPeriod(period)
      if (isValid) {
        const value = (period.charAt(0).toUpperCase() + period.toString().slice(1)) as GraphGranularity
        availaibleGranularities.push({ value, label: t(`commons.${period}`) })
      }
      return !isValid
    })

    const isSelectedGranularityAvailaible =
      availaibleGranularities.findIndex((granularity) => granularity.value === selectedValue) >= 0

    if (!isSelectedGranularityAvailaible)
      setSelectedValue(availaibleGranularities[availaibleGranularities.length - 1].value)

    return availaibleGranularities.reverse().map((type, i) => (
      <MenuItem value={type.value} key={i}>
        {type.label}
      </MenuItem>
    ))
  }, [startDate, endDate])

  return (
    <SelectField name={name} label={'stats.filter.granularity'} withNone={false} variant="outlined">
      {granularities}
    </SelectField>
  )
}

export const Dates = (): JSX.Element => {
  const [, { value: startDate }] = useField<string>({ name: 'startDate' })
  const [, { value: endDate }, { setValue: setEndDate }] = useField<string>({
    name: 'endDate',
  })

  useEffect(() => {
    if (dayjs(startDate).isAfter(dayjs(endDate))) setEndDate(startDate, false)
  }, [startDate])

  return (
    <>
      <StyledStatsFiltersGridItem item>
        <DateField name="startDate" label={'stats.filter.startDate'} valueFormat={DateFormat} variant="outlined" />
      </StyledStatsFiltersGridItem>
      <StyledStatsFiltersGridItem item>
        <DateField
          name="endDate"
          label={'stats.filter.endDate'}
          valueFormat={DateFormat}
          minDate={dayjs(startDate)}
          variant="outlined"
        />
      </StyledStatsFiltersGridItem>
    </>
  )
}

export const Dimension = ({ dimension }: { dimension: CostDetailsDimensions }): JSX.Element | null => {
  const isPwsUser = useIsPwsUser()
  const t = useAppTranslations()
  const PwsName = 'Powerspace SAS'
  const user = useCurrentUser()
  const { slug } = useParams<AllRoute>()
  const { data: companyData } = useGetCompanyDetailsQuery({
    variables: { slug },
    skip: !slug || dimension === CostDetailsDimensions.Channel,
  })

  const options = useMemo((): JSX.Element[] => {
    const opts: { value: string; label: string }[] = []
    switch (dimension) {
      case CostDetailsDimensions.Channel:
        opts.push(
          ...Object.values(ChannelType).map((value) => ({
            value: value.toString(),
            label: t(`enum.ChannelType.${value}`),
          }))
        )
        break
      case CostDetailsDimensions.Network:
        opts.push(
          ...Object.values(Network).map((value) => ({
            value: value.toString(),
            label: t(`enum.Network.${value}`),
          }))
        )
        break
      case CostDetailsDimensions.Market:
        opts.push({ value: 'FR-FR', label: 'Fr' }, { value: 'IT-IT', label: 'It' })
        break
      case CostDetailsDimensions.DemandSource:
        if (!slug && user.me.company.id !== PWS_RESELLER_ID)
          opts.push(
            { value: user.me.company.id, label: user.me.company.name },
            { value: PWS_RESELLER_ID, label: PwsName }
          )
        if (companyData?.company && companyData.company.reseller.id !== PWS_RESELLER_ID)
          opts.push(
            {
              value: companyData.company.reseller.id,
              label: companyData.company.reseller.name,
            },
            { value: PWS_RESELLER_ID, label: PwsName }
          )
        break
      default:
        break
    }
    return opts.map((option, i) => (
      <MenuItem value={option.value.toString()} key={i}>
        {option.label}
      </MenuItem>
    ))
  }, [dimension, slug, user, companyData])

  const renderValue = (selected: unknown): React.ReactNode => {
    if (Array.isArray(selected) && selected.length > 0) return selected.join(', ')
    return t('commons.all')
  }

  if (
    !isPwsUser &&
    (dimension === CostDetailsDimensions.Channel ||
      dimension === CostDetailsDimensions.Market ||
      dimension === CostDetailsDimensions.Network)
  )
    return null
  if (!options.length) return null
  return (
    <StyledStatsFiltersGridItem item>
      <SelectField
        name={`otherFilters.${dimension}`}
        label={`stats.filter.${dimension}`}
        withNone={false}
        variant="outlined"
        multiple
        renderValue={renderValue}
      >
        {options}
      </SelectField>
    </StyledStatsFiltersGridItem>
  )
}
