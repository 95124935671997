import { FormikValues } from 'formik'
import { clone } from 'lodash-es'

const cleanEmptyFormField = (reworkingFormValues: FormikValues): { [key: string]: unknown } => {
  const formFields: FormikValues = clone(reworkingFormValues)
  for (const field in formFields)
    if (formFields.hasOwnProperty(field)) if (formFields[field] === '') formFields[field] = null
  return formFields
}

const trimFormField = (reworkingFormValues: FormikValues): { [key: string]: unknown } => {
  const formFields: FormikValues = clone(reworkingFormValues)
  for (const field in formFields)
    if (formFields.hasOwnProperty(field) && typeof formFields[field] == 'string')
      formFields[field] = formFields[field].trim()
  return formFields
}

const addIdToValues = (reworkingFormValues: FormikValues, idToAssign: string): { [key: string]: unknown } => {
  return Object.assign({}, reworkingFormValues, { id: idToAssign })
}

const prepareFormValues = (formValues: FormikValues, idToAssign?: string): FormikValues => {
  const trimmedData = trimFormField(formValues)
  const dataToPost = cleanEmptyFormField(trimmedData)
  return idToAssign ? addIdToValues(dataToPost, idToAssign) : dataToPost
}

export default prepareFormValues
