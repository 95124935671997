import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

interface CheckboxButtonProps {
  label: string
  className?: string
  name: string
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({ className, label, name }): JSX.Element => {
  const t = useAppTranslations()
  const [field] = useField({ name })
  const stringLabel: string = t(label)

  return (
    <FormControlLabel
      label={stringLabel}
      control={<Checkbox className={className} {...field} />}
      checked={field.value}
    />
  )
}

export default CheckboxButton
