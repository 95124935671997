import { IconButton, styled } from '@mui/material'

export const TransparentButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.action.hoverOpacity,
    color: theme.palette.common.black,
  },
}))
