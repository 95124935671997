import React, { useEffect, useState } from 'react'
import { FormikStepProps } from '../../../../components/form/wizzard/FormikStepper'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { useField } from 'formik'
import {
  PositionInput,
  useWebsiteLazyQuery,
  WebsiteQuery,
  useGetCompanyDetailsLazyQuery,
  useSearchWebsiteForPositionWizzardLazyQuery,
  SearchWebsiteForPositionWizzardQuery,
} from '../../../../__generated__/graphql'
import { Grid } from '@mui/material'
import AsyncAutoCompleteField from '../../../../components/form/AsyncAutoCompleteField'
import * as Yup from 'yup'
import { listOption } from '../../../../components/form/AutoCompleteField'
import { PublisherRoute } from '../../../routeParams'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash-es'

export const websiteSchema = Yup.object().shape({
  websiteId: Yup.string().required('commons.required'),
})

export interface CreatePositionWebsiteInterface extends Partial<Record<keyof PositionInput, unknown>> {
  websiteId: string
}

const CreatePositionWebsiteStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()
  const [queryName, setQueryName] = useState<string>('')
  const [websiteList, setWebsiteList] = useState<listOption[]>([])
  const [field] = useField('websiteId')
  const { slug } = useParams<PublisherRoute>()
  const [getCompany, { data: currentCompany }] = useGetCompanyDetailsLazyQuery()
  // Query when field value is empty (clean step)
  const [getWebsites, { data: websites }] = useSearchWebsiteForPositionWizzardLazyQuery()
  // Query when field value is set (back from another step)
  const [getCurrentlySelectedWebsite, { data: currentlySelectedWebsite }] = useWebsiteLazyQuery()

  useEffect(() => {
    if (!isNil(slug))
      getCompany({
        variables: {
          slug,
        },
      })
  }, [slug])

  useEffect(() => {
    if (queryName === '' && field.value !== '')
      getCurrentlySelectedWebsite({
        variables: {
          id: field.value,
        },
      })
    else
      getWebsites({
        variables: {
          searchQuery: {
            isArchived: false,
            companyId: currentCompany?.company.id,
            ...(queryName && { name: queryName }),
          },
          pagination: {},
        },
      })
  }, [queryName, currentCompany])

  type anySourceWebsite = SearchWebsiteForPositionWizzardQuery['searchWebsites'][number] | WebsiteQuery['website']

  const reformatWebsites = (websites: Array<anySourceWebsite>): Array<listOption> => {
    return websites.map((website) => ({
      value: website.id,
      label: website.name,
    }))
  }

  // Set proper websiteList depending on which website query was called
  useEffect(() => {
    if (websites) setWebsiteList(reformatWebsites(websites.searchWebsites))
    else if (currentlySelectedWebsite && currentlySelectedWebsite.website)
      setWebsiteList(reformatWebsites([currentlySelectedWebsite.website]))
  }, [websites, currentlySelectedWebsite])

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <AsyncAutoCompleteField
          getValue={(value) => setQueryName(value)}
          label={t('position.create.websiteStep.websiteId')}
          name="websiteId"
          value={''}
          options={websiteList}
          disableClearable={true}
          required
        />
      </Grid>
    </Grid>
  )
}

export default CreatePositionWebsiteStep
