import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAdgroupSettingsLazyQuery } from '../../../__generated__/graphql'
import { CampaignRoute, AdGroupRoute } from '../../../pages/routeParams'
import { isString } from 'lodash-es'

export const useCampaignIdFinder = (): number | string | null => {
  const { campaignId } = useParams<CampaignRoute>()
  const { adgroupId } = useParams<AdGroupRoute>()
  const [getCurrentAdgroupData, { data: currentAdgroupData }] = useAdgroupSettingsLazyQuery()

  useEffect(() => {
    if (isString(adgroupId) && adgroupId.length > 0) getCurrentAdgroupData({ variables: { id: adgroupId } })
  }, [adgroupId])

  const maybeCurrentCampaignId = useMemo(() => {
    if (campaignId) return campaignId
    else if (currentAdgroupData) return currentAdgroupData?.adGroup.campaign.id
    else return null
  }, [campaignId, currentAdgroupData])

  return maybeCurrentCampaignId
}
