import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete'
import { isNil } from 'lodash-es'
import { useDebouncedCallback } from 'use-debounce'
import Loader from '../loader/Loader'
import { AutoCompleteFieldProps, listOption } from './AutoCompleteField'
import { DEFAULT_DEBOUNCE_TIME } from '../../utils/defaultDebounceTime'

interface AsyncAutoCompleteFieldProps extends AutoCompleteFieldProps {
  getValue: (value: string) => void
  debounceTime?: number
}

const minWidth = { minWidth: '30rem' }

const AsyncAutoCompleteField: React.FC<AsyncAutoCompleteFieldProps> = ({
  name,
  label,
  options = [],
  getValue,
  required = false,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  ...props
}) => {
  const [field, , helpers] = useField({ name })
  const { setValue } = helpers
  const [optionsList, setOptionsList] = useState<listOption[]>([])
  const [initialListIsReady, setInitialListIsReady] = useState(false)
  const [listLoading, setListLoading] = useState(true)

  const onChange = useCallback((event, nextSelectedOption) => setValue(nextSelectedOption?.value), [setValue])

  const getOptionLabel = useCallback(
    (option) => option?.label || options.find((o) => option === o.value)?.label || '',
    [options]
  )

  useEffect(() => {
    setOptionsList(options)
    setListLoading(false)
  }, [options])

  const updateSearchValueOnlyOnTyping = (value: string, reason: AutocompleteInputChangeReason): void => {
    reason === 'input' && setDebouncedQueryName(value)
  }

  const debounced = useDebouncedCallback((value) => {
    getValue(value)
  }, debounceTime)

  const setDebouncedQueryName = (value: string): void => {
    setListLoading(true)
    setOptionsList([])
    debounced(value)
  }

  useEffect(() => {
    if (optionsList.length > 0 && !initialListIsReady) setInitialListIsReady(true)
  }, [optionsList])

  const optionsWithDefaultEmptyValue = useMemo(() => [{ value: '' }, ...optionsList], [optionsList])

  if (!initialListIsReady) return <Loader />
  return (
    <Autocomplete
      {...props}
      sx={minWidth}
      value={field.value}
      options={optionsWithDefaultEmptyValue} // add empty value for initial value...
      filterOptions={(options) => options.filter((option) => option.value !== '')} // ...then filter it
      onInputChange={(_, value, reason) => updateSearchValueOnlyOnTyping(value, reason)}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => !isNil(option) && option.value === value}
      onChange={onChange}
      loading={listLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

export default AsyncAutoCompleteField
