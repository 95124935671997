import React from 'react'
import List from '@mui/material/List'
import { CompanyType, Status, useSidebarItemsQuery } from '../../../__generated__/graphql'
import { SidebarHeader } from './SidebarHeader'
import { SidebarFooter } from './SidebarFooter'
import { SidebarBody } from './SidebarBody'
import { NetworkStatus } from '@apollo/client'
import { CompanyDetailsContextProvider, useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import { styled } from '@mui/material'
import SidebarSkeleton from '../../loader/SidebarSkeleton'
import AgencySidebarContent from './AgencySidebar'

export const sidebarPalette = {
  text: '#ffffff',
  separator: '#5E6A7A',
  light: '#9f9fa9',
  main: '#455161',
  dark: '#3b4755',
}

const DivSidebar = styled('div')(() => ({
  backgroundColor: sidebarPalette.main,
  width: '24.5rem',
  height: '100%',
  display: 'flex',
  overflow: 'hidden',

  '& hr': {
    borderColor: sidebarPalette.separator,
  },
}))

export const ListSidebarContent = styled(List)(() => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  height: '100%',
  paddingTop: 0,
  color: sidebarPalette.text,
  overflow: 'hidden',
  wordWrap: 'break-word',
}))

const Sidebar: React.FC<{ companySlug: string }> = ({ companySlug }) => {
  return (
    <DivSidebar>
      <CompanyDetailsContextProvider companySlug={companySlug}>
        <SidebarContentType />
      </CompanyDetailsContextProvider>
    </DivSidebar>
  )
}

const SidebarContentType: React.FC = () => {
  const {
    company: { type },
  } = useCompanyDetailsContext()

  if (type === CompanyType.Agency) return <AgencySidebarContent />
  else return <SidebarContent />
}

export interface Item {
  id: string
  name: string
  status: Status
  subItems: Array<{
    id: string
    name: string
    isActive?: boolean
    isArchived?: boolean
    status?: Status
  }>
}

const SidebarContent = (): JSX.Element => {
  const companyDetails = useCompanyDetailsContext()
  const {
    data: itemsData,
    refetch: refetchItems,
    networkStatus,
  } = useSidebarItemsQuery({
    variables: {
      companyId: companyDetails.company.id,
      archived: false,
      isAdvertiser: companyDetails.company.type === CompanyType.Advertiser,
      isTradingDesk: companyDetails.company.type === CompanyType.TradingDesk,
      isPublisher: companyDetails.company.type === CompanyType.Publisher,
    },
    notifyOnNetworkStatusChange: true,
  })

  // TODO: remove hack (auto generated type is Maybe instead of Array)
  const items: Item[] =
    (itemsData?.searchWebsites || itemsData?.searchCampaigns || itemsData?.searchProgrammaticCampaigns) ?? []

  return (
    <ListSidebarContent>
      <SidebarHeader
        updateWithArchived={(showArchived: boolean) => refetchItems({ archived: showArchived ? null : false })}
        updateSidebarItems={() => refetchItems()}
        items={items}
      />

      {networkStatus === NetworkStatus.loading ||
      networkStatus === NetworkStatus.setVariables ||
      networkStatus === NetworkStatus.refetch ? (
        <SidebarSkeleton />
      ) : (
        <SidebarBody items={items} updateSidebarItems={() => refetchItems()} />
      )}

      <SidebarFooter />
    </ListSidebarContent>
  )
}

export default Sidebar
