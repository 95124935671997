import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/system'

const DivContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  height: '100%',
  alignItems: 'center',
  flexGrow: 1,
}))

const DivOverflowChildren = styled('div')(() => ({
  position: 'absolute',
}))

const DivOverride = styled('div')(() => ({
  display: 'none',
}))

export interface LoaderProps {
  overflow?: boolean
  override?: boolean
  alignSelf?: string
}

const Loader: React.FC<LoaderProps> = ({ children, overflow = false, override = false }) => {
  return (
    <DivContainer data-testid="loader">
      <CircularProgress sx={{ zIndex: overflow ? 1 : undefined }} />
      {overflow && <DivOverflowChildren>{children}</DivOverflowChildren>}
      {override && <DivOverride>{children}</DivOverride>}
      {!overflow && !override && children}
    </DivContainer>
  )
}

export default Loader
