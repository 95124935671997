import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, styled } from '@mui/material'
import React from 'react'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { Column } from '../../../table/ReactTable'

const StyledCheckbox = styled(Checkbox)({
  paddingTop: 0,
  paddingBottom: 0,
})

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
}))

interface SelectHeaderProps {
  hiddenColumns: Record<string, boolean>
  onChange: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
  options: Column[]
}

const CostDetailsTableSelectHeaders = ({ hiddenColumns, onChange, options }: SelectHeaderProps): JSX.Element => {
  const t = useAppTranslations()

  const handleClick = (e: any, accessorKey: string): void => {
    onChange((prevState: Record<string, boolean>) => ({
      ...prevState,
      [accessorKey]: e.target.checked,
    }))
  }

  const dataTypesOptions = options
    .filter((column) => column.accessorKey !== 'name')
    .map((column, i) => (
      <MenuItem key={i} value={column.accessorKey as string}>
        <StyledCheckbox
          checked={hiddenColumns[column.accessorKey as string]}
          onClick={(e) => handleClick(e, column.accessorKey)}
        />
        <ListItemText primary={t(column.accessorKey)} />
      </MenuItem>
    ))

  return (
    <StyledFormControl size="small">
      <InputLabel id="cost-details-select-headers">{t('stats.filter.kpi')}</InputLabel>
      <Select
        labelId="cost-details-select-headers"
        multiple
        value={Object.keys(hiddenColumns).filter((key) => hiddenColumns[key])}
        input={<OutlinedInput label={t('stats.filter.kpi')} />}
        renderValue={() =>
          t('stats.filter.kpiSelection', {
            count: Object.values(hiddenColumns).filter((value) => value === true).length,
          })
        }
        autoWidth={true}
      >
        {dataTypesOptions}
      </Select>
    </StyledFormControl>
  )
}

export default CostDetailsTableSelectHeaders
