import React from 'react'
import { TextField } from '@mui/material'
import { useField } from 'formik'
import Autocomplete from '@mui/material/Autocomplete'
import { isNil } from 'lodash-es'

export interface listOption {
  value: unknown
  label: string
}

export interface AutoCompleteFieldProps {
  name: string
  label: string
  options: listOption[]
  value: unknown
  disableClearable?: boolean
  required?: boolean
  multiple?: boolean
}

const minWidth = { minWidth: '30rem' }

const AutoCompleteField: React.FC<AutoCompleteFieldProps> = ({
  name,
  label,
  options = [],
  required = false,
  multiple = false,
  ...props
}) => {
  const [field, , helpers] = useField({ name })
  const { setValue } = helpers

  const onChange = React.useCallback(
    (event, nextSelectedOption) => {
      if (multiple) setValue(nextSelectedOption)
      else setValue(nextSelectedOption?.value)
    },
    [setValue, multiple]
  )

  const getOptionLabel = React.useCallback(
    (option) => option?.label || options.find((o) => option === o.value)?.label || '',
    [options]
  )

  return (
    <Autocomplete
      {...props}
      value={field.value}
      sx={{ minWidth }}
      options={options}
      multiple={multiple}
      disableCloseOnSelect={multiple}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, value) => !isNil(option) && option.value === value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          variant="outlined"
          size="small"
          margin="normal"
          fullWidth
        />
      )}
    />
  )
}

export default AutoCompleteField
