import { ApolloClient } from '@apollo/client'
import { isLoggedInVar } from '../apollo'
import { removeCostDetailsFilters } from './hooks/useLocalStorage'
const tokenName = 'token'

export const getUserToken = (): string | null => localStorage.getItem(tokenName)
export const hasToken = (): boolean => !!getUserToken

export const saveToken = (token: string): void => {
  localStorage.setItem(tokenName, token)
  isLoggedInVar(true)
}

export const removeToken = (): void => {
  localStorage.removeItem(tokenName)
  isLoggedInVar(false)
}

export const logout = <T>(client: ApolloClient<T>, force = false): void => {
  removeCostDetailsFilters()
  removeToken()
  force ? client.cache.reset() : undefined
}
