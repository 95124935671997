import { Box, Typography, Button } from '@mui/material'
import React, { ReactNode } from 'react'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

interface ErrorBoundaryProps {
  children: ReactNode
  fallbackUI?: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const DefaultFallbackUI: React.FC = (): JSX.Element => {
  const t = useAppTranslations()
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      <Typography variant="h1" gutterBottom fontSize={'3rem'}>
        {t('ErrorBoundaryUIFallback.somethingWentWrong')}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t('ErrorBoundaryUIFallback.apologizeInconvenience')}
      </Typography>
      <Button variant="contained" color="primary" onClick={() => window.location.reload()} sx={{ mt: 2 }}>
        {t('ErrorBoundaryUIFallback.refreshPage')}
      </Button>
    </Box>
  )
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // TODO connect to a report error service
    console.error('Caught by error boundary:', error, errorInfo)
  }

  render(): ReactNode {
    if (this.state.hasError) return this.props.fallbackUI || <DefaultFallbackUI />

    return this.props.children
  }
}
