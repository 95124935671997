import React from 'react'
import { AdCopyStatus, Status, UserStatus } from '../../../__generated__/graphql'
import { PossiblyBooleanContentStyle } from '../../form/DisplayedItem'

interface StatusCellProps {
  status?: Status | AdCopyStatus | UserStatus
  isActive?: boolean
  isArchived?: boolean
}

const StatusCell: React.FC<StatusCellProps> = ({ status, isActive, isArchived }) => {
  if (status) return <PossiblyBooleanContentStyle content={status} />
  else if (isArchived) return <PossiblyBooleanContentStyle content={Status.Archived} />
  else if (isActive !== undefined)
    return <PossiblyBooleanContentStyle content={isActive ? Status.Active : Status.Inactive} />
  else return <PossiblyBooleanContentStyle content={Status.Active} />
}

export default StatusCell
