import { theme } from './theme'
import { TableCellClasses, TableRowClasses, TableHeadClasses } from '@mui/material'

import { StyleRules } from '@mui/styles'

export const tableCellTheme: Partial<StyleRules<TableCellClasses>> = {
  styleOverrides: {
    root: {
      borderColor: `${theme.palette.divider} !important`,
      fontSize: '1.2rem',
      '&:not(:last-child)': {
        borderRight: `1px solid`,
      },
      '& svg': {
        fontSize: '2rem',
        color: theme.palette.primary.main,
      },
    },
    head: {
      fontWeight: 'bold',
    },
  },
}

export const tableRowTheme: Partial<StyleRules<TableRowClasses>> = {
  styleOverrides: {
    hover: {
      '&:hover': {
        backgroundColor: theme.palette.header,
      },
    },
  },
}

export const tableHeadTheme: Partial<StyleRules<TableHeadClasses>> = {
  styleOverrides: {
    root: {
      backgroundColor: theme.palette.header,
    },
  },
}
