import React, { useCallback, useMemo } from 'react'
import { Autocomplete, TextField, MenuItem, IconButton, Tooltip, Box, SxProps, Theme, Popper } from '@mui/material'
import { useField } from 'formik'
import { getReadableIAB, useIABSelectOptions } from '../../utils/hooks/useIABSelectOptions'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { isNil } from 'lodash-es'
import { AutoCompleteFieldProps } from './AutoCompleteField'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

export type IABAutoCompleteFieldProps = Pick<AutoCompleteFieldProps, 'name' | 'label'> & {
  withAllOption?: boolean
  required?: boolean
  onMouseLeave?: () => void
  variant?: 'filled' | 'outlined' | 'standard'
  sx?: SxProps<Theme>
  onFocus?: () => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const IabAutoCompleteField: React.FC<IABAutoCompleteFieldProps> = ({
  label,
  name,
  withAllOption,
  required = false,
  onMouseLeave,
  variant = 'outlined',
  sx,
  onFocus,
  onBlur,
}) => {
  const t = useAppTranslations()
  const [{ value }, , { setValue }] = useField(name)
  const options = useIABSelectOptions()
  const [expandedOptions, setExpandedOptions] = React.useState<Set<string>>(new Set())

  const optionsMemo = useMemo(() => {
    if (withAllOption && options?.length) return [{ value: 'all', label: t('search.allIAB') }, ...options]
    else return options
  }, [options, withAllOption])

  const handleExpand = (event: React.MouseEvent, optionValue: string): void => {
    event.stopPropagation()
    setExpandedOptions((prevExpanded) => {
      const updatedSet = new Set(prevExpanded)
      if (updatedSet.has(optionValue)) updatedSet.delete(optionValue)
      else updatedSet.add(optionValue)

      return updatedSet
    })
  }

  const onChange = useCallback(
    (event, nextSelectedOption) => {
      setValue(nextSelectedOption?.value)
    },
    [setValue]
  )

  const getOptionLabel = useCallback(
    (option) => option?.label || optionsMemo.find((o) => option === o.value)?.label || '',
    [optionsMemo]
  )

  const resetExpandedOptions = useCallback(() => {
    if (value.includes('_')) setExpandedOptions(new Set([value.split('_')[0]]))
    else setExpandedOptions(new Set())
  }, [value])

  return (
    <Box onMouseLeave={onMouseLeave}>
      <Autocomplete
        value={value}
        onChange={onChange}
        options={optionsMemo}
        disableClearable
        onClose={resetExpandedOptions}
        onOpen={resetExpandedOptions}
        getOptionLabel={getOptionLabel}
        PopperComponent={(props) => <Popper {...props} style={{ minWidth: 300 }} />}
        filterOptions={(options, { inputValue }) => {
          if (!inputValue) return options
          const matchedOptions = options.filter((option) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          )

          matchedOptions.forEach((option) => {
            const parentValue = option.value.split('_')[0]
            if (option.value.includes('_') && !expandedOptions.has(parentValue))
              setExpandedOptions((prev) => new Set(prev).add(parentValue))
          })

          return matchedOptions
        }}
        isOptionEqualToValue={(option, value) => !isNil(option) && option.value === (value as any)}
        renderOption={(props, option) => {
          if (option.value === 'all')
            return (
              <MenuItem {...props} value={option.value}>
                {option.label}
              </MenuItem>
            )
          const isParent = !option.value.includes('_')
          const isExpanded = expandedOptions.has(option.value.split('_')[0])
          if (!isExpanded && !isParent) return null

          return (
            <Tooltip title={getReadableIAB(option.value)}>
              <MenuItem
                value={option.value}
                {...props}
                key={option.value}
                sx={{
                  ...(option.value.includes('_') ? { marginLeft: 4 } : {}),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {isParent && (
                  <IconButton onClick={(event) => handleExpand(event, option.value)} size="small" sx={{ my: 0, py: 0 }}>
                    {isExpanded ? <ExpandLess sx={{ fontSize: '20px' }} /> : <ExpandMore sx={{ fontSize: '20px' }} />}
                  </IconButton>
                )}
                {option.label}
              </MenuItem>
            </Tooltip>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            onFocus={onFocus}
            onBlur={onBlur}
            InputLabelProps={{ shrink: true }}
            label={t(label)}
            variant={variant}
            size="small"
            margin="normal"
            fullWidth
            required={required}
            sx={sx}
          />
        )}
      />
    </Box>
  )
}

export default IabAutoCompleteField
