import React from 'react'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import SelectField from '../../components/form/SelectField'
import { EntityType, PartialCompany, PartialUser } from '../../__generated__/graphql'
import mapEnumForSelect from '../../utils/mapEnumForSelect'

interface FilterProps {
  onReset: () => void
  userList: Array<PartialUser>
  companyList: Array<PartialCompany>
  canFilterCompany: boolean
}

const Filter: React.FC<FilterProps> = ({ onReset, userList, companyList, canFilterCompany }): JSX.Element => {
  const t = useAppTranslations()
  const formik = useFormikContext()

  const entityTypeOptions = mapEnumForSelect(EntityType).map((entityType, i) => (
    <MenuItem value={entityType.value} key={i}>
      {entityType.label}
    </MenuItem>
  ))

  const userOptions = userList.map((user) => (
    <MenuItem key={user.id} value={user.id}>
      {user.firstName} {user.lastName}
    </MenuItem>
  ))

  const companyOptions = companyList.map((company) => (
    <MenuItem key={company.id} value={company.id}>
      {company.name}
    </MenuItem>
  ))

  return (
    <Form>
      <Box mb={3}>
        <Grid container spacing={3} justifyContent="flex-start" alignItems="center">
          <Grid xs={2} item>
            <SelectField withNone={false} name={'entityType'} label={t('commons.entityType')} variant="outlined">
              <MenuItem value={'all'}>{t('search.defaultAllFilter')}</MenuItem>
              {entityTypeOptions}
            </SelectField>
          </Grid>

          <Grid xs={2} item>
            <SelectField withNone={false} name={'userId'} label={t('commons.users')} variant="outlined">
              <MenuItem value={'all'}>{t('search.defaultAllFilter')}</MenuItem>
              {userOptions}
            </SelectField>
          </Grid>

          {canFilterCompany && (
            <Grid xs={2} item>
              <SelectField withNone={false} name={'companyId'} label={t('commons.companies')} variant="outlined">
                <MenuItem value={'all'}>{t('search.defaultAllFilter')}</MenuItem>
                {companyOptions}
              </SelectField>
            </Grid>
          )}

          <Grid item>
            <Button variant="text" onClick={onReset} disabled={!formik.dirty}>
              {t('search.resetFilters')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Form>
  )
}

export default Filter
