import React, { ReactNode, useContext } from 'react'
import { AuthQuery, useAuthQuery } from '../../__generated__/graphql'
import { isNil } from 'lodash-es'
import Login from '../../pages/Login'
import { useSetLanguage } from './useSetLanguage'
import PwsSpinner from '../../components/loader/PwsSpinner'

const UseUserContext = React.createContext<AuthQuery | undefined>(undefined)
UseUserContext.displayName = 'UserContext'

export const PWS_COMPANY_ID = '1'

export type UserContextProviderProps = {
  children: ReactNode
}

export const UserContextProvider = (props: UserContextProviderProps): JSX.Element => {
  const { data, error, loading } = useAuthQuery({ fetchPolicy: 'cache-first' })
  useSetLanguage(data?.me.language)

  if (!isNil(error)) return <Login />
  return <UseUserContext.Provider value={data}>{loading ? <PwsSpinner /> : props.children}</UseUserContext.Provider>
}

export function useCurrentUser(): AuthQuery {
  const value = useContext(UseUserContext)
  if (!value) throw new Error('user provider was not used')
  return value
}

export const useIsPwsUser = (): boolean => {
  const { me } = useCurrentUser()
  return me.company.id === PWS_COMPANY_ID
}
