import { connect, FormikProps } from 'formik'
import { noop } from 'lodash-es'
import { useEffect } from 'react'

const FormikOnChangeEffect = ({ onChange = noop, formik }: { onChange: any; formik: FormikProps<any> }): null => {
  const { values } = formik

  useEffect(() => {
    // Effect will run even when form initialized
    onChange({ values })
  }, [values, onChange])

  return null
}

export default connect(FormikOnChangeEffect)
