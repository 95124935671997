import React from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { Column } from './ReactTable'
import dayjs from 'dayjs'
import DownloadIcon from '@mui/icons-material/Download'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { useCostDetailsFiltersStorage } from '../stats/filters/useStatsFilterStorage'
import { AllRoute } from '../../pages/routeParams'
import { useParams } from 'react-router-dom'
import { determineStatsTypeFromParams } from '../../utils/determineStatsTypeFromParams'

export type Data = {
  [key: string]: unknown
}

type ExportCSVProps = {
  columns: Column[]
  data: Data[]
}

export const buildCSVContent = (columns: Column[], data: Data[]): string => {
  const header = columns.map((col) => col.accessorKey).join(',')

  const rowsData = data.map((row) => {
    return columns
      .map((col) => {
        const cellValue = row[col.accessorKey]
        if (cellValue && dayjs.isDayjs(cellValue) && cellValue.isValid()) return cellValue.format()

        return cellValue
      })
      .join(',')
  })

  return [header, ...rowsData].join('\n')
}

export const createCSVBlob = (columns: Column[], data: Data[]): Blob => {
  const csvContent = buildCSVContent(columns, data)
  return new Blob([csvContent], { type: 'text/csv' })
}

const buildFileName = (type: string, startDate: string, endDate: string, slug?: string): string => {
  if (slug) return `${type}_by_${slug}_from_${startDate}_to_${endDate}.csv`

  return `${type}_from_${startDate}_to_${endDate}.csv`
}

const ExportCSV: React.FC<ExportCSVProps> = ({ columns, data }) => {
  const [costDetailsDatesFilters] = useCostDetailsFiltersStorage()
  const params = useParams<AllRoute>()
  const t = useAppTranslations()

  const downloadCSV = (): void => {
    const blob = createCSVBlob(columns, data)
    const url = window.URL.createObjectURL(blob)

    const startDate = dayjs(costDetailsDatesFilters.startDate).format('DD-MM-YYYY')
    const endDate = dayjs(costDetailsDatesFilters.endDate).format('DD-MM-YYYY')
    const type = determineStatsTypeFromParams(params)
    const fileName = buildFileName(type, startDate, endDate, params.slug)

    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  return (
    <Tooltip title={t('commons.actions.downloadAsCsv')}>
      <IconButton color="primary" onClick={downloadCSV} size="small">
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  )
}

export default ExportCSV
