import { isNil } from 'lodash-es'

export const displayAsCurrency = (number: number | string | null | undefined): string | null => {
  if (isNil(number)) return null

  const toDisplay = typeof number === 'number' ? number : parseFloat(number)

  return toDisplay.toLocaleString('fr-fr', {
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 2,
  })
}
