import React, { useContext } from 'react'
import {
  AdGroupInput,
  AdgroupSettingsQuery,
  useAdgroupSettingsQuery,
  useUpdateAdgroupMutation,
} from '../../../__generated__/graphql'
import { useSnackbarManager } from '../../providers/snackbarContext'
import { useAppTranslations } from '../useAppTranslations'
import prepareFormValues from '../../prepareFormValues'
import { filterMutationInput } from '../../generateMutationInput'
import PwsSpinner from '../../../components/loader/PwsSpinner'

type AdgroupContextInterface = [
  AdgroupSettingsQuery['adGroup'],
  (values: Partial<AdGroupInput>) => Promise<void>,
  boolean
]
const AdgroupContext = React.createContext<AdgroupContextInterface | null>(null)
AdgroupContext.displayName = 'AdgroupContext'

export const updateAdGroupToDelete = ['costAdjustments', 'targeting', 'campaign', 'budget']

export const AdgroupContextProvider: React.FC<{ adgroupId: string }> = ({ adgroupId, children }) => {
  const [, setSnackbar] = useSnackbarManager()
  const t = useAppTranslations()

  const {
    data: adgroupData,
    loading: adgroupLoading,
    refetch: refetchAdGroup,
  } = useAdgroupSettingsQuery({
    variables: { id: adgroupId },
  })
  const [updateAdgroup, { loading: updateAdgroupLoading }] = useUpdateAdgroupMutation()

  const onUpdateAdgroup = async (changes: Partial<AdGroupInput>): Promise<void> => {
    const cleanData = prepareFormValues(changes) as Partial<AdGroupInput>
    if (!adgroupData?.adGroup) return
    // const updatedAdgroup: AdGroupInput = {
    //   ...adgroupData.adGroup,
    //   campaign: undefined,
    //   targeting: undefined,
    //   costAdjustments: undefined,
    //   ...cleanData,
    // } as AdGroupInput

    const adgroupInput = filterMutationInput<AdgroupSettingsQuery['adGroup'], AdGroupInput>(
      adgroupData.adGroup,
      cleanData,
      updateAdGroupToDelete
    )

    await updateAdgroup({ variables: { adgroup: adgroupInput } })
      .then(() => setSnackbar({ severity: 'success', message: t('adgroup.updated') }))
      .catch((err) => setSnackbar({ severity: 'error', message: err.message }))
      .then(() => refetchAdGroup())
  }
  if (adgroupLoading) return <PwsSpinner />
  if (!adgroupData) return null
  return (
    <AdgroupContext.Provider value={[adgroupData.adGroup, onUpdateAdgroup, updateAdgroupLoading]}>
      {children}
    </AdgroupContext.Provider>
  )
}

export const useAdgroupContext = (): AdgroupContextInterface => {
  const value = useContext(AdgroupContext)
  if (!value) throw new Error('Value not found in adgroup context')
  return value
}
