import React, { Dispatch, useEffect } from 'react'
import { useWebsiteLazyQuery } from '../../../__generated__/graphql'
import CreatePositionStepper from './CreatePositionStepper'
import { useWebsiteIdFinder } from '../../../utils/hooks/website/useWebsiteIdFinder'
import Loader from '../../../components/loader/Loader'

export const CreatePositionWizzardFromSidebar: React.FC<{
  updateSidebarItems: () => void
  setOpen: Dispatch<React.SetStateAction<boolean>>
}> = ({ updateSidebarItems, setOpen }) => {
  const [getWebsiteData, { data: websiteData }] = useWebsiteLazyQuery()
  const websiteId = useWebsiteIdFinder()

  useEffect(() => {
    if (websiteId) getWebsiteData({ variables: { id: websiteId } })
  }, [websiteId])

  if (!websiteData?.website) return <Loader />
  return <CreatePositionStepper website={websiteData?.website} onSuccess={updateSidebarItems} setOpen={setOpen} />
}

const CreatePositionWizzard: React.FC<{
  updateSidebarItems: () => void
  setOpen: Dispatch<React.SetStateAction<boolean>>
}> = ({ updateSidebarItems, setOpen }) => {
  const [getWebsiteData, { data: websiteData }] = useWebsiteLazyQuery()

  const websiteId = useWebsiteIdFinder()

  useEffect(() => {
    if (websiteId) getWebsiteData({ variables: { id: websiteId } })
  }, [websiteId])

  return <CreatePositionStepper website={websiteData?.website} onSuccess={updateSidebarItems} setOpen={setOpen} />
}

export default CreatePositionWizzard
