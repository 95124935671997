import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Home from '@mui/icons-material/Home'
import Avatar from '@mui/material/Avatar'
import MenuWithIcon, { MenuActionItem } from './MenuWithIcon'
import { useCurrentUser } from '../../utils/hooks/useUserContext'
import { useLogo } from '../../utils/providers/themeContext'
import { logout } from '../../utils/tokenManagement'
import { apolloClient } from '../../apollo'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { CompanyRouteBuilder } from '../../pages/routeParams'

const Layout: React.FC<JSX.ElementChildrenAttribute> = ({ children }) => {
  const history = useHistory()
  const t = useAppTranslations()
  const data = useCurrentUser()
  const logo = useLogo()

  const notificationsItems = [
    {
      link: '/notifications',
      text: 'layout.notifications',
    },
  ]

  const userItems = [
    {
      link: `${CompanyRouteBuilder(data.me.company.slug, data.me.company.type)}/users/${data.me.id}`,
      text: 'layout.profile',
    },
  ]

  const logoutAction: MenuActionItem[] = [
    {
      text: t('layout.logout'),
      action: () => {
        logout(apolloClient)
        setTimeout(() => history.push('/'), 100)
      },
      autoClose: true,
    },
  ]

  return (
    <>
      <Grid
        container
        sx={{
          height: '100%',
          flexGrow: 1,
          flexDirection: 'column',
          display: 'flex',
          overflow: 'hidden',
        }}
      >
        <Grid sx={{ flex: 0, maxWidth: '100%' }} item>
          <AppBar color="secondary" position="static">
            <Toolbar disableGutters>
              <Box
                sx={{
                  backgroundColor: 'secondary.dark',
                  height: '6.4rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '24.5rem',
                  '& img': {
                    height: '3rem',
                  },
                }}
              >
                <Link to="/home">
                  <img src={logo} alt="Powerspace" />
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  flex: 1,
                  pr: 3,
                }}
              >
                <Divider orientation="vertical" flexItem />
                <IconButton color="inherit" component={Link} to="/" size="large">
                  <Home data-testid="homeIcon" />
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <MenuWithIcon linkItems={notificationsItems} dataId="notificationMenuButton">
                  <Avatar alt={`${data?.me.firstName} ${data?.me.lastName}`} />
                </MenuWithIcon>
                <Typography sx={{ fontSize: '1.2rem' }}>{`${data?.me.firstName} ${data?.me.lastName}`}</Typography>
                <MenuWithIcon linkItems={userItems} actionItems={logoutAction} dataId="userMenuIcon">
                  <KeyboardArrowDownIcon />
                </MenuWithIcon>
              </Box>
            </Toolbar>
          </AppBar>
        </Grid>
        <Grid
          sx={{
            flex: 1,
            overflow: 'auto',
            maxWidth: '100%',
          }}
          item
        >
          <Box component="main" sx={{ height: '100%' }}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Layout
