import {
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
} from '@mui/material'
import {
  Cell,
  ColumnDef,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Header,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import React, { useMemo } from 'react'
import DatatablePagination from '../layout/DatatablePagination'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

export type Column = {
  header: string
  accessorKey: string
  footer: string
  cell: () => void
}

interface ReactTableProps<T> {
  data: T[]
  columns: ColumnDef<T>[]
  hiddenColumns?: Record<string, boolean>
  footerTop?: boolean
  defaultSort?: Array<{ desc: boolean; id: string }>
  defaultExpanded?: ExpandedState
  useGroupStyle?: boolean
  darkHeader?: boolean
  enablePagination?: boolean
  rowSelection?: RowSelectionState
  setRowSelection?: OnChangeFn<RowSelectionState>
  onClick?: (props: T) => void
  stickyHeader?: boolean
  stickyTopPosition?: string
}

export type MetaType = {
  group?: number
}

export const getGroupStyle = <T,>(cells: Header<T, unknown>[] | Cell<T, unknown>[], index: number): SxProps<Theme> => {
  const currentGroup = (cells[index]?.column?.columnDef?.meta as MetaType)?.group
  const nextGroup = (cells[index + 1]?.column?.columnDef?.meta as MetaType)?.group
  if (!nextGroup || nextGroup !== currentGroup) return {}
  else return { borderRight: '0 !important', borderLeft: '0 !important' }
}

const darkHeaderStyle = {
  label: {
    backgroundColor: 'darkHeader',
    '& .MuiTableSortLabel-root': { color: 'white !important' },
    '& .MuiTableCell-head': { color: 'white !important' },
  },
  sortLabel: {
    '& .MuiTableSortLabel-icon': {
      color: 'white !important',
    },
  },
}

const ReactTable = <T,>({
  data,
  columns,
  hiddenColumns = {},
  footerTop = false,
  defaultSort = [],
  defaultExpanded = {},
  useGroupStyle = false,
  darkHeader = false,
  enablePagination = false,
  rowSelection = {},
  setRowSelection,
  onClick,
  stickyHeader = false,
  stickyTopPosition = '0px',
}: ReactTableProps<T>): JSX.Element => {
  const t = useAppTranslations()
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort)
  const [expanded, setExpanded] = React.useState<ExpandedState>(defaultExpanded)

  const tableInstance = useReactTable({
    columns,
    data,
    state: {
      sorting,
      columnVisibility: hiddenColumns,
      expanded,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    getSubRows: (originalRow: any) => originalRow.subRows,
    getRowId: (originalRow: any) => originalRow?.id,
  })

  const hasFooter = useMemo(
    () => Boolean(tableInstance.getFooterGroups()[0].headers[0].column.columnDef.footer),
    [columns]
  )

  const footer = tableInstance.getFooterGroups().map((footerGroup) => (
    <TableRow key={footerGroup.id}>
      {footerGroup.headers.map((header, index) => {
        return (
          <TableCell
            key={header.id}
            colSpan={header.colSpan}
            sx={useGroupStyle ? getGroupStyle(footerGroup.headers, index) : {}}
          >
            {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
          </TableCell>
        )
      })}
    </TableRow>
  ))

  return (
    <Table size="small">
      <TableHead sx={stickyHeader ? { position: 'sticky', top: stickyTopPosition, zIndex: 1 } : {}}>
        {tableInstance.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id} sx={darkHeader ? darkHeaderStyle.label : undefined}>
            {headerGroup.headers.map((header, index) => {
              return (
                <TableCell
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  colSpan={header.colSpan}
                  sx={useGroupStyle ? getGroupStyle(headerGroup.headers, index) : {}}
                >
                  {header.isPlaceholder ? null : header.column.getCanSort() ? (
                    <TableSortLabel
                      active={!!header.column.getIsSorted()}
                      sx={darkHeader ? darkHeaderStyle.sortLabel : {}}
                      direction={
                        header.column.getIsSorted() ? (header.column.getIsSorted() as 'asc' | 'desc') : undefined
                      }
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableSortLabel>
                  ) : (
                    flexRender(header.column.columnDef.header, header.getContext())
                  )}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
        {hasFooter && footerTop && tableInstance.getFooterGroups() && <>{footer}</>}
      </TableHead>
      <TableBody>
        {tableInstance.getRowModel().rows.length > 0 ? (
          tableInstance.getRowModel().rows.map((row) => (
            <TableRow key={row.id} hover sx={onClick ? { cursor: 'pointer ' } : {}}>
              {row.getVisibleCells().map((cell, index) => (
                <TableCell
                  key={cell.id}
                  sx={useGroupStyle ? getGroupStyle(row.getVisibleCells(), index) : undefined}
                  onClick={onClick && cell.column.id !== 'actions' ? () => onClick(row.original) : undefined}
                >
                  {cell.getValue() === null ? 'n/a' : flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={columns.length} sx={{ textAlign: 'center' }}>
              {t('commons.noDataToDisplay')}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
      {hasFooter && !footerTop && tableInstance.getFooterGroups() && <TableFooter>{footer}</TableFooter>}
      {enablePagination && (
        <DatatablePagination rowsDisplayed={data.length} colSpan={tableInstance.getFlatHeaders().length} />
      )}
    </Table>
  )
}

export default ReactTable
