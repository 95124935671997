import { Location } from 'history'
import React, { useEffect, useState } from 'react'
import { Prompt, useHistory } from 'react-router-dom'
import { ConfirmDialog } from './ConfirmDialog'
import { useFormikContext } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import _ from 'lodash'
import { formatFieldKey } from '../../utils/formatFieldKey'

const RouteLeavingGuard = <T extends object>(): JSX.Element => {
  const t = useAppTranslations()
  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState<Location | null>(null)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const { dirty, values, initialValues } = useFormikContext<T>()
  const [changedFields, setChangedFields] = useState<string[]>([])

  const findChangedFields = (): void => {
    const initial = initialValues as Record<string, any>
    const current = values as Record<string, any>

    const changedFields: string[] = []

    const deepCompare = (initialObj: any, currentObj: any, parentKey = ''): void => {
      Object.keys(initialObj).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key

        if (_.isArray(initialObj[key]) && initialObj[key].every(_.isString)) {
          if (!_.isEqual(initialObj[key], currentObj[key])) changedFields.push(parentKey || key)
          return
        }

        if (_.isObject(initialObj[key]) && _.isObject(currentObj[key]))
          deepCompare(initialObj[key], currentObj[key], fullKey)
        else if (!_.isEqual(initialObj[key], currentObj[key])) changedFields.push(fullKey)
      })
    }
    deepCompare(initial, current)
    setChangedFields(_.uniq(changedFields))
  }

  useEffect(() => {
    if (dirty) findChangedFields()
  }, [dirty, initialValues, values])

  const closeModal = (): void => {
    setModalVisible(false)
  }

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false)
    setConfirmedNavigation(true)
  }

  useEffect(() => {
    if (confirmedNavigation && lastLocation) history.push(lastLocation.pathname)
  }, [confirmedNavigation, lastLocation])

  const listOfChangedFields = dirty ? (
    <ul>
      {changedFields.map(formatFieldKey).map((field, index) => (
        <li key={index}>{field}</li>
      ))}
    </ul>
  ) : null

  return (
    <>
      <Prompt when={dirty} message={handleBlockedNavigation} />
      <ConfirmDialog
        message={
          <>
            <p>{t('commons.unsavedChangesWarning')}</p>
            <p>{t('commons.unsavedModifications')}</p>
            {listOfChangedFields}
          </>
        }
        actionButton={{
          label: t('commons.actions.quitWithoutSaving'),
          action: handleConfirmNavigationClick,
        }}
        setOpen={closeModal}
        open={modalVisible}
      />
    </>
  )
}
export default RouteLeavingGuard
