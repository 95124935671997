import * as React from 'react'
import { useCurrentCompanyDisplayedContext } from '../../pages/company/currentCompanyDisplayedContext'
import { Alert, Typography } from '@mui/material'
import { Trans } from 'react-i18next'

const InactiveCompanyWarning = (): JSX.Element | null => {
  const {
    company: { active, name },
  } = useCurrentCompanyDisplayedContext()
  if (active) return null
  return (
    <Alert sx={{ mt: 4, mx: 2, p: 2 }} severity="warning">
      <Typography variant="body1">
        <Trans ns="translations" i18nKey="company.statusWarning" values={{ name }} />
      </Typography>
    </Alert>
  )
}

export default InactiveCompanyWarning
