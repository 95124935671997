import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import { Link } from 'react-router-dom'
import ListItemText from '@mui/material/ListItemText'
import React from 'react'
import { sidebarPalette } from './Sidebar'
import { styled } from '@mui/material'
import { ActivityRouteBuilder } from '../../../pages/routeParams'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'

const DivSidebarFooter = styled('div')(() => ({
  marginTop: 'auto',

  '& span': {
    color: sidebarPalette.light,
    textTransform: 'uppercase',
    fontSize: '1.2rem',
  },
}))

export const SidebarFooter = (): JSX.Element => {
  const { company } = useCompanyDetailsContext()
  const t = useAppTranslations()
  return (
    <DivSidebarFooter>
      <Divider />
      <ListItem button component={Link} to={ActivityRouteBuilder(company.type.toLocaleLowerCase(), company.slug)}>
        <ListItemText>{t('layout.sidebar.feed')}</ListItemText>
      </ListItem>
      <Divider />
      <ListItem button component="a" href="https://docs.powerspace.com/docs" target="_blank">
        <ListItemText>{t('layout.sidebar.documentation')}</ListItemText>
      </ListItem>
    </DivSidebarFooter>
  )
}
