import { Description } from '@mui/icons-material'
import { Button, Box } from '@mui/material'
import { useFormikContext } from 'formik'
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

const FileInputField: React.FC<{
  name: string
  accept: string
  onFileChange?: (event: ChangeEvent<HTMLInputElement>) => void
}> = ({ name, accept, onFileChange }): JSX.Element => {
  const t = useAppTranslations()
  const formik = useFormikContext()
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    setFileName('')
  }, [formik.initialValues])

  const onFileUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      let filename = ''
      if (event?.target?.files?.length) {
        filename = event.target.files[0].name
        !onFileChange && formik.setFieldValue(name, event.target.files[0])
      }
      setFileName(filename)
      onFileChange && onFileChange(event)

      event.target.value = ''
    },
    [formik, setFileName, onFileChange]
  )

  return (
    <Button variant="outlined" component="label" color={fileName ? 'primary' : 'secondary'} sx={{ my: '0.5rem' }}>
      {fileName ? (
        <>
          <Description sx={{ mr: 2 }} /> {fileName}
        </>
      ) : (
        t('commons.actions.selectFile')
      )}
      <Box sx={{ display: 'none' }}>
        <input name={name} accept={accept} id="contained-button-image" multiple type="file" onChange={onFileUpload} />
      </Box>
    </Button>
  )
}

export default FileInputField
