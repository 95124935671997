import { Box, Chip, Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { UnStyledLink } from '../../components/layout/styled'
import theme from '../../styles/theme'
import { displayDateAsText } from '../../utils/displayDateAsText'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import {
  ActivityFeedPartsFragment,
  ActivityType,
  EntityType,
  Maybe,
  PartialUser,
  Scalars,
} from '../../__generated__/graphql'
import { AdGroupRouteBuilder, PositionRouteBuilder, UserRouteBuilder, WebsiteRouteBuilder } from '../routeParams'
import { activityFeedConf } from './activityFeedConf'

export const DATE_FORMAT = 'dddd DD MMMM YYYY - HH:ss'

const ActivityFeedRow: React.FC<{ feed: ActivityFeedPartsFragment }> = ({ feed }) => {
  const { user, entityType, company, createdAt } = feed

  return (
    <Grid
      container
      spacing={1}
      sx={{
        borderLeft: 10,
        borderColor: activityFeedConf[entityType]?.backgroundColor,
        borderBottom: 2,
        borderBottomColor: theme.palette.grey[200],
        my: 1,
        py: 1,
        fontSize: theme.typography.body2,
      }}
    >
      <UserSection user={user} companyId={company.id} />
      <EntitySection entityType={entityType} />
      <TypeSection feed={feed} slug={company.name} />
      <CompanySection companyName={company.name} />
      <DateSection date={createdAt} />
    </Grid>
  )
}

export default ActivityFeedRow

const UserSection: React.FC<{ user: Maybe<PartialUser> | undefined; companyId: Scalars['PwsID'] }> = ({
  user,
  companyId,
}) => {
  const t = useAppTranslations()
  const { firstName, lastName } = user || {}
  const displayCompany = useMemo(() => companyId !== user?.company.id, [user, companyId])
  const renderCompanyName = displayCompany ? `(${user?.company.name})` : ''

  return (
    <Grid item sm={2} sx={{ fontWeight: theme.typography.fontWeightBold }}>
      {user ? (
        <>
          {firstName} {lastName} {renderCompanyName}
        </>
      ) : (
        t('activity.feed.userNotFound')
      )}
    </Grid>
  )
}

const EntitySection: React.FC<{ entityType: EntityType }> = ({ entityType }) => {
  return (
    <Grid item sm={2} sx={{ overflow: 'hidden' }}>
      <Chip
        label={entityType}
        sx={{
          backgroundColor: activityFeedConf[entityType]?.backgroundColor,
          color: activityFeedConf[entityType]?.color,
          borderRadius: 1,
        }}
        size="small"
      />
    </Grid>
  )
}

const CompanySection: React.FC<{ companyName: string }> = ({ companyName }) => (
  <Grid item sm={2} sx={{ textAlign: 'end' }}>
    {companyName}
  </Grid>
)

const TypeSection: React.FC<{ feed: ActivityFeedPartsFragment; slug: Maybe<string> }> = ({ feed, slug }) => {
  const t = useAppTranslations()
  const { activityType, property, oldValue, newValue, entityId, entityType } = feed

  const activityTypeLabel = {
    [ActivityType.Create]: t('activity.feed.created'),
    [ActivityType.Update]: t('activity.feed.updated'),
    [ActivityType.Invite]: t('activity.feed.invited'),
  }

  let content = (
    <>
      <Box component="span" sx={{ fontWeight: 'bold' }}>
        {activityTypeLabel[activityType]}
      </Box>{' '}
      {t('activity.feed.propertyOfEntityWithId', { property, entityType })}{' '}
      <Box component="span" sx={{ fontWeight: 'bold' }}>
        {entityId}{' '}
      </Box>
      {t('activity.feed.fromOldValueToNewValue', { oldValue, newValue })}
    </>
  )

  if (['is_active', 'status'].includes(property || ''))
    content = (
      <>
        {t('activity.feed.changed')}{' '}
        <Box component="span" sx={{ fontStyle: 'italic' }}>
          {t('activity.feed.status')}
        </Box>{' '}
        {t('activity.feed.of')} {entityType}{' '}
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {entityId}
        </Box>{' '}
        {t('activity.feed.from')} <StatusValue status={oldValue} /> {t('activity.feed.to')}{' '}
        <StatusValue status={newValue} />
      </>
    )

  if (activityType === ActivityType.Create)
    content = (
      <>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {t('activity.feed.created')}
        </Box>{' '}
        {entityType} {t('activity.feed.withId')}{' '}
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {entityId}
        </Box>{' '}
      </>
    )

  if (activityType === ActivityType.Invite)
    content = (
      <>
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {t('activity.feed.invited')}
        </Box>{' '}
        {t('commons.user')} {t('activity.feed.withId')}{' '}
        <Box component="span" sx={{ fontWeight: 'bold' }}>
          {entityId}
        </Box>{' '}
        {t('activity.feed.toJoinCompany', { companyName: feed.company.name })}
      </>
    )

  return (
    <RedirectLinkWrapper feed={feed} slug={slug}>
      {content}
    </RedirectLinkWrapper>
  )
}

const RedirectLinkWrapper: React.FC<{ feed: ActivityFeedPartsFragment; slug: Maybe<string> }> = ({
  feed,
  children,
  slug,
}) => {
  if (!slug)
    <Grid item sm={4}>
      {children}
    </Grid>

  // TODO add adCopy, adTemplate redirection url
  const url = (): string => {
    switch (feed.entityType) {
      case EntityType.User:
        return feed.user?.id ? UserRouteBuilder(feed.user?.id) : ''
      case EntityType.AdGroup:
        return AdGroupRouteBuilder(slug as string, feed.entityId)
      case EntityType.Website:
        return WebsiteRouteBuilder(slug as string, feed.entityId)
      case EntityType.Position:
        return PositionRouteBuilder(slug as string, feed.entityId)
      default:
        return ''
    }
  }

  if (!url())
    return (
      <Grid item sm={4}>
        {children}
      </Grid>
    )

  return (
    <Grid item sm={4}>
      <UnStyledLink to={url()}>{children}</UnStyledLink>
    </Grid>
  )
}

const StatusValue: React.FC<{ status: string | null | undefined }> = ({ status }) => {
  const statusLabel = (): string => {
    switch (status) {
      case 'true':
        return 'active'
      case 'false':
        return 'inactive'
      default:
        return status || ''
    }
  }

  const statusColor = (): string => {
    switch (status) {
      case 'true':
      case 'active':
        return 'success.main'
      case 'false':
      case 'inactive':
        return 'warning.main'
      case 'pending':
        return 'info.main'
      case 'rejected':
        return 'error.main'
      default:
        return ''
    }
  }
  return (
    <Box
      component="span"
      sx={{
        color: statusColor(),
        textTransform: 'uppercase',
        fontWeight: 'bold',
      }}
    >
      {statusLabel()}
    </Box>
  )
}

const DateSection: React.FC<{ date: string }> = ({ date }) => (
  <Grid item sm={2} sx={{ textAlign: 'end' }}>
    {displayDateAsText(date, DATE_FORMAT)}
  </Grid>
)
