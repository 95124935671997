import React from 'react'
import { Box, Paper, styled } from '@mui/material'
import logo from '../../img/logo_powerspace.svg'

export const chevronSize = '1rem'

export const loginPalette = {
  chevron: '#E52B7A',
  background: '#1d2d3d',
}

export const StyledMain = styled('main')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
  backgroundColor: loginPalette.background,
})

export const Chevron = styled(Box)({
  position: 'absolute',
  height: '4rem',
  width: '4rem',
})

export const PaperPaperStyle = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '46rem',
  minHeight: '43rem',
  padding: theme.spacing(5),

  '& > h1': {
    marginTop: 0,
  },
}))

export const DivContainer = styled('div')({
  maxWidth: '56rem',
  width: '100%',
  padding: '2rem 5rem',
  position: 'relative',
  margin: 'auto',

  '@media (max-width: 600px)': {
    padding: '2rem 1rem',
  },
})

export const DivLogo = styled('div')({
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2rem',

  '& img': {
    width: '22rem',
  },
})

export const PCopyright = styled('p')(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.text.disabled,
  fontSize: '1.2rem',
  textAlign: 'center',
  marginTop: '2rem',
  left: 0,
  right: 0,
}))

export const DivBottomLink = styled('div')({
  marginTop: 'auto',
  fontSize: '1.3rem',
})

const LoggedOutLayoutWrapper: React.FC = ({ children }) => {
  return (
    <StyledMain>
      <DivContainer>
        <DivLogo>
          <img src={logo} alt="Powerspace" />
        </DivLogo>
        <PaperPaperStyle>{children}</PaperPaperStyle>
        <PCopyright>Copyright &copy; {new Date().getFullYear()} Powerspace</PCopyright>
      </DivContainer>
    </StyledMain>
  )
}

export default LoggedOutLayoutWrapper
