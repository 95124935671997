const {
  REACT_APP_ANALYTICS,
  REACT_APP_ADSERVER_DOMAIN,
  REACT_APP_AD_PREVIEW,
  REACT_APP_API_URL,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_ADCOPY_IMAGE_FORMATS,
  REACT_APP_GRAPH_PERIOD_LENGTH,
  REACT_APP_NATIVE_TEMPLATE_PREVIEW_DEBOUNCE_TIME,
  REACT_APP_SELECTED_SEGMENTS_DEFAULT_VISIBLE,
  REACT_APP_COST_ADJUSTMENT_VARIATION_STEP,
  REACT_APP_CONF_UPDATE_DELAY_MINUTES,
  REACT_APP_CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY,
  REACT_APP_ADGROUP_END_DATE_THRESHOLD_DAYS,
  REACT_APP_ADGROUP_BUDGET_LEFT_THRESHOLD_PERCENTAGE,
  REACT_APP_ADGROUP_DAILY_BUDGET_THRESHOLD,
  REACT_APP_MAX_ADCOPY_GENERATION,
  REACT_APP_CGU_VERSION,
  REACT_APP_DOMAIN,
} = process.env

if (!REACT_APP_API_URL) throw 'Application configuration fails: REACT_APP_API_URL is missing'
if (!REACT_APP_GOOGLE_API_KEY) throw 'Application configuration fails: REACT_APP_GOOGLE_API_KEY is missing'
if (!REACT_APP_ANALYTICS) throw 'Application configuration fails: REACT_APP_ANALYTICS is missing'
if (!REACT_APP_ADSERVER_DOMAIN) throw 'Application configuration fails: REACT_APP_ADSERVER_DOMAIN is missing'
if (!REACT_APP_AD_PREVIEW) throw 'Application configuration fails: REACT_APP_AD_PREVIEW is missing'
if (!REACT_APP_ADCOPY_IMAGE_FORMATS) throw 'Application configuration fails: REACT_APP_ADCOPY_IMAGE_FORMATS is missing'
if (!REACT_APP_GRAPH_PERIOD_LENGTH) throw 'Application configuration fails: REACT_APP_GRAPH_PERIOD_LENGTH is missing'
if (!REACT_APP_NATIVE_TEMPLATE_PREVIEW_DEBOUNCE_TIME)
  throw 'Application configuration fails: REACT_APP_NATIVE_TEMPLATE_PREVIEW_DEBOUNCE_TIME is missing'
if (!REACT_APP_SELECTED_SEGMENTS_DEFAULT_VISIBLE)
  throw 'Application configuration fails: REACT_APP_SELECTED_SEGMENTS_DEFAULT_VISIBLE is missing'
if (!REACT_APP_COST_ADJUSTMENT_VARIATION_STEP)
  throw 'Application configuration fails: REACT_APP_COST_ADJUSTMENT_VARIATION_STEP is missing'
if (!REACT_APP_CONF_UPDATE_DELAY_MINUTES)
  throw 'Application configuration fails: REACT_APP_CONF_UPDATE_DELAY_MINUTES is missing'
if (!REACT_APP_CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY)
  throw 'Application configuration fails: REACT_APP_CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY is missing'
if (!REACT_APP_ADGROUP_END_DATE_THRESHOLD_DAYS)
  throw 'Application configuration fails: REACT_APP_ADGROUP_END_DATE_THRESHOLD_DAYS is missing'
if (!REACT_APP_ADGROUP_BUDGET_LEFT_THRESHOLD_PERCENTAGE)
  throw 'Application configuration fails: REACT_APP_ADGROUP_BUDGET_LEFT_THRESHOLD_PERCENTAGE is missing'
if (!REACT_APP_ADGROUP_DAILY_BUDGET_THRESHOLD)
  throw 'Application configuration fails: REACT_APP_ADGROUP_DAILY_BUDGET_THRESHOLD is missing'
if (!REACT_APP_MAX_ADCOPY_GENERATION)
  throw 'Application configuration fails: REACT_APP_MAX_ADCOPY_GENERATION is missing'
if (!REACT_APP_CGU_VERSION) throw 'Application configuration fails: REACT_APP_CGU_VERSION is missing'
if (!REACT_APP_DOMAIN) throw 'Application configuration fails: REACT_APP_DOMAIN is missing'
export const API_URL: string = REACT_APP_API_URL
export const GOOGLE_API_KEY: string = REACT_APP_GOOGLE_API_KEY
export const APP_ANALYTICS: string = REACT_APP_ANALYTICS
export const ADSERVER_DOMAIN: string = REACT_APP_ADSERVER_DOMAIN
export const AD_PREVIEW: string = REACT_APP_AD_PREVIEW
export const ADCOPY_IMAGE_FORMATS: string = REACT_APP_ADCOPY_IMAGE_FORMATS
export const GRAPH_PERIOD_LENGTH: number = parseInt(REACT_APP_GRAPH_PERIOD_LENGTH)
export const NATIVE_TEMPLATE_PREVIEW_DEBOUNCE_TIME: number = parseInt(REACT_APP_NATIVE_TEMPLATE_PREVIEW_DEBOUNCE_TIME)
export const SELECTED_SEGMENTS_DEFAULT_VISIBLE: number = parseInt(REACT_APP_SELECTED_SEGMENTS_DEFAULT_VISIBLE)
export const COST_ADJUSTMENT_VARIATION_STEP: number = parseInt(REACT_APP_COST_ADJUSTMENT_VARIATION_STEP)
export const CONF_UPDATE_DELAY_MINUTES: number = parseInt(REACT_APP_CONF_UPDATE_DELAY_MINUTES)
export const CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY: number = parseInt(REACT_APP_CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY)
export const ADGROUP_END_DATE_THRESHOLD_DAYS: number = parseInt(REACT_APP_ADGROUP_END_DATE_THRESHOLD_DAYS)
export const ADGROUP_BUDGET_LEFT_THRESHOLD_PERCENTAGE: number = parseInt(
  REACT_APP_ADGROUP_BUDGET_LEFT_THRESHOLD_PERCENTAGE
)
export const ADGROUP_DAILY_BUDGET_THRESHOLD: number = parseInt(REACT_APP_ADGROUP_DAILY_BUDGET_THRESHOLD)
export const MAX_ADCOPY_GENERATION: number = parseInt(REACT_APP_MAX_ADCOPY_GENERATION)
export const CGU_VERSION: string = REACT_APP_CGU_VERSION
export const DOMAIN: string = REACT_APP_DOMAIN
