import React from 'react'
import { FormikStepProps } from '../../../../components/form/wizzard/FormikStepper'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { PositionInput } from '../../../../__generated__/graphql'
import { Grid } from '@mui/material'
import InputField from '../../../../components/form/InputField'
import InputAdornment from '@mui/material/InputAdornment'
import * as Yup from 'yup'
import { MaybeFormik } from '../../../../interfaces/maybeFormik'

export const settingsSchema = Yup.object().shape({
  nbZone: Yup.number().min(1).required('commons.required'),
  revenueShare: Yup.number().min(0).max(100).integer('commons.integer').required('commons.required'),
  cpmFloor: Yup.number(),
})

export interface CreatePositionSettingsInterface extends Partial<Record<keyof PositionInput, unknown>> {
  nbZone: number
  revenueShare: number
  cpmFloor: MaybeFormik<number>
}

const CreatePositionSettingsStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <InputField
          type={'number'}
          name={'nbZone'}
          label={t('position.settings.summary.nbZone')}
          required
          variant="outlined"
        />
      </Grid>
      <Grid item xs>
        <InputField
          type={'number'}
          name={'revenueShare'}
          label={t('position.settings.summary.revenueShare')}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          required
          variant="outlined"
        />
      </Grid>
      <Grid item xs>
        <InputField
          type={'number'}
          name={'cpmFloor'}
          label={t('position.settings.summary.cpmFloor')}
          InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
          variant="outlined"
        />
      </Grid>
    </Grid>
  )
}

export default CreatePositionSettingsStep
