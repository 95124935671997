import React, { useState } from 'react'
import { Box, SxProps, TextField, TextFieldProps, Theme } from '@mui/material'
import { useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import dayjs, { Dayjs } from 'dayjs'
import { MobileDatePicker } from '@mui/lab'
import { EditionModes } from '../../utils/providers/editionModeContext'
import HelperTooltip from './HelperTooltip'

export interface DateFieldProps {
  name: string
  label: string
  inputFormat?: string
  valueFormat?: string
  minDate?: Dayjs
  maxDate?: Dayjs
  margin?: TextFieldProps['margin']
  required?: boolean
  InputProps?: TextFieldProps['InputProps']
  InputLabelProps?: TextFieldProps['InputLabelProps']
  onMouseLeave?: () => void
  sx?: SxProps<Theme>
  disableHoverEdition?: boolean
  setEditionMode?: React.Dispatch<React.SetStateAction<EditionModes>>
  tooltipText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  placeholder?: string
  defaultHelperText?: string
  clearable?: boolean
  views?: Array<'day' | 'month' | 'year'>
  disableFuture?: boolean
}

const DateField: React.FC<DateFieldProps> = ({
  name,
  label,
  margin = 'normal',
  inputFormat = 'DD/MM/YYYY',
  valueFormat,
  onMouseLeave,
  sx,
  disableHoverEdition = false,
  setEditionMode,
  tooltipText,
  variant = 'standard',
  defaultHelperText = '',
  placeholder,
  clearable = false,
  views,
  ...props
}) => {
  const t = useAppTranslations()
  const [field, meta, helpers] = useField({ name })
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const hasError = Boolean(meta.error)
  const helperText = (meta.error && hasError && t(meta.error)) || defaultHelperText

  const isSame = (date?: string): boolean => dayjs(date).diff(dayjs(meta.initialValue)) === 0

  const canChangeEditionMode = !disableHoverEdition && setEditionMode

  const handleDateChange = (date?: string): void => {
    if (dayjs(date).isValid()) {
      helpers.setValue(dayjs(date).format(valueFormat))
      isSame(date) && canChangeEditionMode && setEditionMode(EditionModes.Display)
    } else helpers.setValue(null)
  }

  return (
    <Box onMouseLeave={() => !isDialogOpen && onMouseLeave && onMouseLeave()}>
      <MobileDatePicker
        inputFormat={inputFormat}
        clearable={clearable}
        views={views}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            variant={variant}
            error={hasError}
            margin={margin}
            helperText={helperText}
            data-testid="DateFieldTestId"
            size="small"
            fullWidth
            sx={sx}
            placeholder={placeholder}
          />
        )}
        label={
          <>
            {t(label)}
            {tooltipText && <HelperTooltip helperText={tooltipText} sx={{ fontSize: 15, marginLeft: '6.5px' }} />}
          </>
        }
        InputAdornmentProps={{ position: 'end' }}
        value={field.value}
        onChange={() => null}
        onAccept={(date) => handleDateChange(date)}
        disableCloseOnSelect={false}
        onOpen={() => setIsDialogOpen(true)}
        onClose={() => setIsDialogOpen(false)}
        {...props}
      />
    </Box>
  )
}

export default DateField
