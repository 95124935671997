import { Form, Formik } from 'formik'
import InputField from './InputField'
import CheckboxButton from './CheckboxButton'
import { Button } from '@mui/material'
import React from 'react'
import * as Yup from 'yup'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { useLoginMutation } from '../../__generated__/graphql'
import { saveToken } from '../../utils/tokenManagement'
import { apolloClient } from '../../apollo'
import ErrorAlert from '../error/ErrorAlert'
import { StyledLink } from '../layout/styled'
import BackdropLoader from '../loader/BackdropLoader'
import { DivBottomLink } from '../layout/LoggedOutLayoutWrapper'

const loginSchema = Yup.object().shape({
  password: Yup.string().required('commons.required'),
  username: Yup.string().email('login.errors.invalidEmail').required('commons.required'),
})

interface LoginState {
  username: string
  password: string
  rememberMe: boolean
}

const LoginForm: React.FC = () => {
  const t = useAppTranslations()
  const [login, { loading: loginLoading, error: loginError, data: loginResult }] = useLoginMutation()

  const handleSubmit = async (values: LoginState): Promise<void> => {
    await login({
      variables: {
        email: values.username,
        password: values.password,
        rememberMe: values.rememberMe,
      },
    })
  }

  const initialValues: LoginState = {
    username: '',
    password: '',
    rememberMe: true,
  }

  if (loginResult?.login) {
    saveToken(loginResult.login.token)
    apolloClient.resetStore()
  }

  return (
    <>
      <h1>{t('login.title')}</h1>
      <BackdropLoader open={loginLoading} />
      {loginError && <ErrorAlert message={t(`serverError.${loginError.message}`)} />}
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={loginSchema}>
        {(formik) => (
          <Form>
            <InputField
              data-testid="emailField"
              variant="outlined"
              name="username"
              label="commons.email"
              type="email"
              autoFocus
              required
              fullWidth
              defaultHelperText=" "
              margin="dense"
            />
            <InputField
              variant="outlined"
              data-testid="passwordField"
              name="password"
              label="commons.password"
              type="password"
              required
              fullWidth
              defaultHelperText=" "
              margin="dense"
            />
            <CheckboxButton name="rememberMe" className="rememberMe" label="login.form.rememberMe" />
            <Button
              className="submit"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!(formik.isValid && formik.dirty)}
            >
              {t('commons.login')}
            </Button>
          </Form>
        )}
      </Formik>
      <DivBottomLink>
        <StyledLink to="/request-reset-password">{t('login.form.passwordRecovery')}</StyledLink>
      </DivBottomLink>
    </>
  )
}

export default LoginForm
