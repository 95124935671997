import { EntityType } from '../../__generated__/graphql'

type EntityColors = Record<EntityType, { backgroundColor: string; color: string }>
export const activityFeedConf: Partial<EntityColors> = {
  [EntityType.AdCopy]: {
    color: '#6b6b6b',
    backgroundColor: '#ffda6c',
  },
  [EntityType.AdGroup]: {
    color: '#6b6b6b',
    backgroundColor: '#ffb867',
  },
  [EntityType.AdTemplate]: {
    color: 'white',
    backgroundColor: '#c5c3e5',
  },
  [EntityType.BillingContact]: {
    color: '#',
    backgroundColor: '#',
  },
  [EntityType.Campaign]: {
    color: '#white',
    backgroundColor: '#ff8e76',
  },
  [EntityType.Company]: {
    color: '#fff',
    backgroundColor: '#',
  },
  [EntityType.Position]: {
    color: '#white',
    backgroundColor: '#ada9da',
  },
  [EntityType.Segment]: {
    color: '#white',
    backgroundColor: '#6fc88a',
  },
  [EntityType.User]: {
    color: '#white',
    backgroundColor: '#',
  },
  [EntityType.Website]: {
    color: '#white',
    backgroundColor: '#cdaada',
  },
  [EntityType.WebsiteDomainExclusion]: {
    color: '#white',
    backgroundColor: '',
  },
  [EntityType.WebsiteIabExclusion]: {
    color: '#white',
    backgroundColor: '',
  },
  [EntityType.AdgroupDirectory]: {
    color: '#c5c1c1',
    backgroundColor: '#f7f7f7',
  },
  [EntityType.Advertiser]: {
    color: 'white',
    backgroundColor: '#ffc9bd',
  },
  [EntityType.User]: {
    color: 'white',
    backgroundColor: '#89abc5',
  },
  [EntityType.Publisher]: {
    color: 'white',
    backgroundColor: '#9465a9',
  },
  [EntityType.Reseller]: {
    color: 'white',
    backgroundColor: '#c5c3e5',
  },
  [EntityType.ProgrammaticCampaign]: {
    color: '#6b6b6b',
    backgroundColor: '#adef79',
  },
  [EntityType.Agency]: {
    color: 'white',
    backgroundColor: '#c06095',
  },
  [EntityType.AdGroupTargeting]: {
    color: '#c5c1c1',
    backgroundColor: '#f7f7f7',
  },
}
