interface MarketRequiredFields {
  country: { code: string }
  language: { code: string }
}

const getMarketCode = (market: MarketRequiredFields): string => {
  if (market?.country?.code && market?.language?.code)
    if (market.country.code == market.language.code) return market.language.code.toUpperCase()
    else return `${market.language.code}-${market.country.code}`.toUpperCase()
  else throw new Error('Missing information on market ' + market)
}

export default getMarketCode
