import React from 'react'
import * as Yup from 'yup'
import { FormikStepProps } from '../../../components/form/wizzard/FormikStepper'
import { AdGroupCreateInput } from '../../../__generated__/graphql'
import { EditionModeContextProvider, EditionModes } from '../../../utils/providers/editionModeContext'
import Cost from '../settings/budgetAndDates/Cost'
import DailyBudget from '../settings/budgetAndDates/DailyBudget'
import Dates from '../settings/budgetAndDates/Dates'
import BudgetCapping from '../settings/budgetAndDates/BudgetCapping'
import { Grid } from '@mui/material'

export const budgetAndDateSchema = Yup.object().shape({
  costValue: Yup.number().min(0.05).required('commons.required'),
  costType: Yup.string().required('commons.required'),
  dailyBudget: Yup.number().min(15).required('commons.required'),
  budgetCapping: Yup.number().min(0).required('commons.required'),
  budgetCappingType: Yup.string().required('commons.required'),
  startDate: Yup.date().nullable(),
  endDate: Yup.date().when('startDate', {
    is: undefined,
    then: Yup.date().nullable(),
    otherwise: Yup.date().nullable().min(Yup.ref('startDate'), 'commons.endDateValidation'),
  }),
})

const AdgroupBudgetStep: React.FC<FormikStepProps & { adgroup: AdGroupCreateInput }> = ({ adgroup }) => {
  return (
    <EditionModeContextProvider defaultMode={EditionModes.Edition}>
      <Grid container>
        <Cost adgroup={adgroup} isWizard />
        <DailyBudget adgroup={adgroup} isWizard />
        <BudgetCapping adgroup={adgroup} isWizard />
        <Grid item sm={3} />
        <Dates adgroup={adgroup} isWizard />
      </Grid>
    </EditionModeContextProvider>
  )
}

export default AdgroupBudgetStep
