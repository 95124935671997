import React, { Dispatch, useState } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { FormikStepper } from '../../../components/form/wizzard/FormikStepper'
import {
  WebsiteCreateInput,
  useCreateWebsiteMutation,
  WebsiteBasicPartsFragment,
  GetCompanyDetailsQuery,
} from '../../../__generated__/graphql'
import { FormikValues } from 'formik'
import * as Yup from 'yup'
import WebsiteDetailStep from './WebsiteDetailStep'
import { urlRegexWithTrackingParams } from '../../../utils/UrlRegex'
import prepareFormValues from '../../../utils/prepareFormValues'
import getIabTranslation from '../../../utils/getIabTranslation'
import { useNavigation } from '../../../utils/hooks/useNavigation'
import { Edit } from '@mui/icons-material'
import WebsitePublisherStep from './WebsitePublisherStep'

const CreateWebsite: React.FC<{
  onWebsiteCreation?: () => void
  setOpen: Dispatch<React.SetStateAction<boolean>>
  company?: GetCompanyDetailsQuery['company']
}> = ({ onWebsiteCreation, setOpen, company }) => {
  const t = useAppTranslations()
  const [error, setError] = React.useState(undefined)
  const [createdWebsite, setCreatedWebsite] = useState<WebsiteBasicPartsFragment | null>()
  const [createWebsite] = useCreateWebsiteMutation()
  const navigation = useNavigation()

  const initialValues: Partial<Record<keyof WebsiteCreateInput, unknown>> = {
    publisherId: company ? parseInt(company?.id, 10) : 0,
    marketId: 1,
    name: '',
    url: '',
    iabCategory: 'IAB24',
    areCampaignsExcluded: false,
  }

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const cleanData = prepareFormValues(values)

    if (cleanData.iabCategory) cleanData.iabCategory = getIabTranslation(cleanData.iabCategory as string, false)

    await createWebsite({
      variables: {
        website: cleanData as WebsiteCreateInput,
      },
    })
      .then((res) => {
        const createdWebsite = res?.data?.createWebsite
        if (createdWebsite) {
          onWebsiteCreation && onWebsiteCreation()
          setCreatedWebsite(createdWebsite)
        }
      })
      .catch((err: React.SetStateAction<undefined>) => setError(err))
  }

  const detailsSchema = Yup.object().shape({
    name: Yup.string().min(3).required('commons.required'),
    url: Yup.string().matches(urlRegexWithTrackingParams, t('commons.invalidUrl')).min(3).required('commons.required'),
    marketId: Yup.number().required('commons.required'),
  })

  const publisherStepSchema = Yup.object().shape({
    publisherId: Yup.string().required('commons.required'),
  })

  const successButton = {
    label: t('website.goToWebsite', { websiteName: createdWebsite?.name }),
    startIcon: <Edit />,
    onClick: () => {
      if (createdWebsite) {
        navigation.goToWebsite(createdWebsite.publisher.slug, createdWebsite.id)
        setOpen(false)
      }
    },
  }

  return (
    <FormikStepper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      enableReinitialize={true}
      successMessage={t('website.created')}
      successActionButton={successButton}
      setOpen={setOpen}
      mergeSteps
    >
      {!company && (
        <WebsitePublisherStep validationSchema={publisherStepSchema} label={t('website.details.publisherStepTitle')} />
      )}
      <WebsiteDetailStep validationSchema={detailsSchema} label={t('website.details.stepperTitle')} />
    </FormikStepper>
  )
}

export default CreateWebsite
