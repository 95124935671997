import { Alert, Box, FormHelperText } from '@mui/material'
import { FormikValues, useField } from 'formik'
import React, { ChangeEvent, useState } from 'react'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import FileInputField from '../../../../components/form/FileInputField'
import InputField from '../../../../components/form/InputField'
import SwitchInput from '../../../../components/form/SwitchInput'
import { StyledDescription } from '../../../../components/form/styled'
import * as Yup from 'yup'
import {
  AddAdGroupTargetingAdressesMutationVariables,
  AdgroupSettingsDocument,
  useAddAdGroupTargetingAdressesMutation,
} from '../../../../__generated__/graphql'
import { useAdgroupContext } from '../../../../utils/hooks/adGroup/useAdgroupContext'
import { FormikStepProps, FormikStepper } from '../../../../components/form/wizzard/FormikStepper'
import { ApolloError } from '@apollo/client'

const parseCSVAddresses = (csvText: string): string[] =>
  csvText
    .split(/[\r\n]+/)
    .map((line) => line.trim())
    .filter((line) => line.length > 0)

const CSVFIleInput: React.FC = () => {
  const t = useAppTranslations()
  const [, meta, helper] = useField({ name: 'addresses' })

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files && event.target.files[0]

    if (!file) return

    const reader = new FileReader()
    reader.onload = (e) => {
      const csvText = e.target?.result as string
      const addressesArray = parseCSVAddresses(csvText)
      helper.setValue(addressesArray)
    }
    reader.readAsText(file)
  }

  const hasError = Boolean(meta.error)
  const helperText = meta.error && hasError && t(meta.error)

  return (
    <>
      <FileInputField name="addresses" accept=".csv" onFileChange={handleFileUpload} />
      {meta.value.length > 0 && (
        <StyledDescription>
          {t('adgroup.geoTargeting.targetingAddresses.numberAddressesFound', {
            addressesFound: meta.value?.length,
          })}
        </StyledDescription>
      )}
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </>
  )
}

const CSVAddressesUploadFields: React.FC<FormikStepProps> = (): JSX.Element => {
  const t = useAppTranslations()
  return (
    <Box m={1}>
      <CSVFIleInput />
      <InputField
        name={'radius'}
        label={'adgroup.geoTargeting.targetingAddresses.radius'}
        type={'number'}
        variant="outlined"
      />
      <SwitchInput
        name={'clearPrevious'}
        label={t('adgroup.geoTargeting.targetingAddresses.clearPrevious')}
        displaysText={false}
      />
      <Alert severity="info">{t('adgroup.geoTargeting.targetingAddresses.csvUploadInfoMessage')}</Alert>
    </Box>
  )
}

const CSVAddressesUploadForm: React.FC<{
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setOpen }) => {
  const [adGroup] = useAdgroupContext()
  const [error, setError] = useState<ApolloError>()
  const t = useAppTranslations()
  const [uploadAddresses] = useAddAdGroupTargetingAdressesMutation({
    refetchQueries: () => [
      {
        query: AdgroupSettingsDocument,
        variables: {
          id: adGroup.id,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  const initialValues: Omit<AddAdGroupTargetingAdressesMutationVariables, 'adGroupId'> = {
    addresses: [],
    radius: 5,
    clearPrevious: true,
  }

  const targetingAddressesSchema = Yup.object().shape({
    addresses: Yup.array().of(Yup.string()).min(1),
    radius: Yup.number(),
    clearPrevious: Yup.boolean(),
  })

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const { addresses, radius, clearPrevious } = values
    const adGroupTargetingAddresses: AddAdGroupTargetingAdressesMutationVariables = {
      addresses,
      radius,
      clearPrevious,
      adGroupId: adGroup.id,
    }
    await uploadAddresses({ variables: adGroupTargetingAddresses })
      .then((res) => {
        if (res.data?.addAdGroupTargetingAdresses) {
          const citiesNotFound = res.data?.addAdGroupTargetingAdresses.map((element) => element.address)
          citiesNotFound.length > 0 &&
            setError({
              message: [
                t('adgroup.geoTargeting.targetingAddresses.unrecognizedCitiesMessage'),
                citiesNotFound.join(','),
              ].join(' '),
            } as ApolloError)
        }
      })
      .catch((err: ApolloError) => setError(err))
  }

  return (
    <FormikStepper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      enableReinitialize={true}
      successMessage={t('adgroup.geoTargeting.targetingAddresses.success')}
      setOpen={setOpen}
    >
      <CSVAddressesUploadFields
        label={t('adgroup.geoTargeting.importCSV')}
        validationSchema={targetingAddressesSchema}
      />
    </FormikStepper>
  )
}

export default CSVAddressesUploadForm
