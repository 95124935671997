import React from 'react'
import { FormikStepProps } from '../../../../components/form/wizzard/FormikStepper'
import { Grid } from '@mui/material'
import InputField from '../../../../components/form/InputField'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import MarketSelectField from '../../../../components/form/MarketSelectField'
import IabAutoCompleteField from '../../../../components/form/IABAutoCompleteField'

const CampaignDetailStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()

  return (
    <Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="flex-start">
      <Grid container direction="column" item xs={6}>
        <Grid item>
          <InputField
            name={'name'}
            label={'campaign.details.name'}
            type={'text'}
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <InputField
            name={'advertiserDomainName'}
            label={'campaign.details.advertiserDomainName'}
            type={'text'}
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={6}>
        <Grid item>
          <IabAutoCompleteField name={'iabCategory'} label={t('commons.iabCategory')} />
        </Grid>
        <Grid item>
          <MarketSelectField name={'marketId'} label={t('commons.market')} required variant="outlined" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default CampaignDetailStep
