import React, { useMemo } from 'react'
import { FormikStepProps } from '../../../components/form/wizzard/FormikStepper'
import { Grid } from '@mui/material'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { useActivePublisherNamesQuery } from '../../../__generated__/graphql'
import AutoCompleteField, { AutoCompleteFieldProps } from '../../../components/form/AutoCompleteField'
import Loader from '../../../components/loader/Loader'

const WebsitePublisherStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()
  const { data: publisher, loading: loadingPublishers } = useActivePublisherNamesQuery()

  const publisherList: AutoCompleteFieldProps['options'] = useMemo(() => {
    if (publisher)
      return publisher.searchCompanies.map((company) => ({
        value: company.id,
        label: company.genericName,
      }))

    return []
  }, [publisher])

  if (loadingPublishers) return <Loader />
  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <AutoCompleteField
          label={t('commons.publishers')}
          name="publisherId"
          value={0}
          options={publisherList}
          disableClearable={true}
        />
      </Grid>
    </Grid>
  )
}

export default WebsitePublisherStep
