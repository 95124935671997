import React from 'react'
import { Helmet } from 'react-helmet'
import { useDocumentTitle } from '../../utils/providers/documentTitleContext'

const ReactHelmet: React.FC = () => {
  const { title } = useDocumentTitle()
  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" type="image/png" href="/favicon.png" />
    </Helmet>
  )
}

export default ReactHelmet
