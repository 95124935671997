import React, { ReactNode } from 'react'
import { Theme } from '@emotion/react'
import DoneIcon from '@mui/icons-material/Done'
import { SxProps, FormLabel, styled, Box, FormHelperText } from '@mui/material'
import { useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { useHoverEditionHandlers } from '../../utils/withHoverEditionMode'
import { Status, UserStatus } from '../../__generated__/graphql'
import HelperTooltip from './HelperTooltip'
import { StyledEditIcon } from './styled'
import { Clear } from '@mui/icons-material'

export const StyledItemWrapper = styled('div')({
  display: 'inline-flex',
  flexDirection: 'column',
  position: 'relative',
  minWidth: '0',
  padding: '0',
  margin: '0',
  border: '0',
  verticalAlign: 'top',
  marginTop: '16px',
  marginBottom: '8px',
  width: '100%',
  height: '64.26px', // Total heigh of MUI input with label, value and one line below for helperText
})

export const StyledLabel = styled(FormLabel)({
  fontSize: '1.4rem',
  color: 'rgba(0, 0, 0, 0.6)',
  textTransform: 'uppercase',
  fontFamily: '"Open Sans",Helvetica,Arial,sans-serif',
  fontWeight: '400',
  lineHeight: '1.4375em',
  padding: '0',
  position: 'absolute',
  display: 'block',
  transformOrigin: 'top left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '133%',
  left: '0',
  top: '0',
  transform: 'translate(0, -1.5px) scale(0.75)',
  transition:
    'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
})

export const StyledValueWrapper = styled('div')({
  marginTop: '16px',
  fontSize: '1.4rem',
  fontWeight: '400',
  lineHeight: '1.4375em',
  boxSizing: 'border-box',
  position: 'relative',
  cursor: 'text',
  width: '100%',
})

export const StyledValue = styled('div')({
  font: 'inherit',
  letterSpacing: 'inherit',
  color: 'currentColor',
  padding: '4px 0 5px',
  border: '0',
  boxSizing: 'content-box',
  background: 'none',
  minHeight: '1.4375em',
  margin: '0',
  WebkitTapHighlightColor: 'transparent',
  minWidth: '0',
  width: '100%',
  WebkitAnimationName: 'mui-auto-fill-cancel',
  animationName: 'mui-auto-fill-cancel',
  WebkitAnimationDuration: '10ms',
  animationDuration: '10ms',
  paddingTop: '1px',
  overflow: 'auto',
  display: 'inline-flex',
  alignItems: 'center',
})

export const StyledFormHelperText = styled(FormHelperText)({
  fontSize: '1.1rem',
  fontWeight: '400',
  lineHeight: '1.66',
  textAlign: 'left',
  marginTop: '4px',
  marginRight: '0',
  marginBottom: '0',
  marginLeft: '0',
})

export const StaticItem: React.FC<{
  label?: string
  valueSx?: SxProps<Theme>
  children: ReactNode
}> = ({ label, children, valueSx }) => {
  return (
    <StyledItemWrapper>
      <StyledLabel>{label}</StyledLabel>
      <StyledValueWrapper>
        <StyledValue sx={valueSx}>{children}</StyledValue>
      </StyledValueWrapper>
    </StyledItemWrapper>
  )
}

const DisplayedItem: React.FC<{
  label: string
  isLink?: boolean
  booleanDisplayType?: Status.Active | 'positive'
  sx?: SxProps<Theme>
  tooltipText?: string
  name: string
  disableHoverEdition?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  editable?: boolean
}> = ({ label, children, name = '', tooltipText, isLink, editable, disableHoverEdition }) => {
  const t = useAppTranslations()
  const [, meta] = useField({ name })

  const hasError = Boolean(meta.touched && meta.error)
  const helperText = meta.error && hasError ? t(meta.error) : ' '
  const { setEditionOnEnter } = useHoverEditionHandlers(name, false)
  const content = children === null || children === '' ? 'n/a' : children

  return (
    <StyledItemWrapper onMouseEnter={setEditionOnEnter}>
      <StyledLabel error={hasError}>
        {t(label)}
        {tooltipText && <HelperTooltip helperText={tooltipText} />}
      </StyledLabel>
      <StyledValueWrapper>
        <StyledValue>
          <PossiblyBooleanContentStyle content={content} isLink={isLink} />
          {!disableHoverEdition && editable && <StyledEditIcon />}
        </StyledValue>
      </StyledValueWrapper>
      <StyledFormHelperText error={hasError}>{helperText}</StyledFormHelperText>
    </StyledItemWrapper>
  )
}

export default DisplayedItem

enum specialFormatting {
  positive = 'positive',
  negative = 'negative',
  include = 'include',
  exclude = 'exclude',
}
const textStyle = {
  whiteSpace: 'pre-wrap',
}

const ContentRender: React.FC<{ content: string | ReactNode; isLink: boolean }> = ({ content, isLink }) => {
  const t = useAppTranslations()

  switch (content) {
    case Status.Active:
    case Status.Inactive:
    case Status.Archived:
      return t('commons.' + content)

    case specialFormatting.positive:
      return <DoneIcon />

    case specialFormatting.negative:
      return <Clear />

    default:
      return React.createElement(
        isLink && content !== 'n/a' ? 'a' : 'span',
        isLink ? { href: content } : { style: textStyle },
        content
      )
  }
}

export const PossiblyBooleanContentStyle: React.FC<{ content: string | ReactNode; isLink?: boolean }> = ({
  content,
  isLink = false,
}) => {
  const getBooleanStyle = (): { color?: string; fontWeight?: string } => {
    switch (content) {
      case Status.Active:
      case UserStatus.Enabled:
      case specialFormatting.include:
        return { color: 'success.main', fontWeight: 'bold' }
      case Status.Inactive:
      case UserStatus.Disabled:
        return { color: 'warning.main', fontWeight: 'bold' }
      case specialFormatting.exclude:
        return { color: 'error.main', fontWeight: 'bold' }
      case specialFormatting.positive:
        return { color: 'success.main' }
      case specialFormatting.negative:
      case UserStatus.Deleted:
        return { color: 'error.main' }
      case Status.Archived:
        return { color: 'text.secondary', fontWeight: 'bold' }
      default:
        return {}
    }
  }

  return (
    <Box sx={getBooleanStyle()}>
      <ContentRender content={content} isLink={isLink} />
    </Box>
  )
}
