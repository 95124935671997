import React from 'react'
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { Field, FieldArray, useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { labelStyle } from './TransformDisplayToEdition'
import { StyledButtonAsInput } from '../layout/styled'

export interface InputFieldArrayProps {
  name: string
  label: string
  type: string
  autoFocus?: boolean
  fullWidth?: boolean
  margin?: TextFieldProps['margin']
  defaultHelperText?: (name: string) => void | string
  parentDefaultHelperText?: string
  required?: boolean
  InputLabelProps?: TextFieldProps['InputLabelProps']
  onMouseLeave?: () => void
  variant?: 'filled' | 'outlined' | 'standard'
  addButtonLabel?: string
  canAdd?: boolean
}

export interface SubInputFieldArrayProps {
  name: string
  label: string
  type: string
  autoFocus?: boolean
  fullWidth?: boolean
  margin?: TextFieldProps['margin']
  defaultHelperText?: (name: string) => void | string
  required?: boolean
  InputLabelProps?: TextFieldProps['InputLabelProps']
  variant?: 'filled' | 'outlined' | 'standard'
  onRemove?: () => void
}

const SubInputField: React.FC<SubInputFieldArrayProps> = ({
  name,
  label,
  type,
  defaultHelperText,
  margin = 'normal',
  variant = 'standard',
  onRemove,
  ...props
}) => {
  const t = useAppTranslations()
  const [, meta] = useField({ name })

  const hasError = Boolean(meta.touched && meta.error)
  const helperTextToDisplay = typeof defaultHelperText === 'function' ? defaultHelperText(name) : defaultHelperText
  const helperText = (meta.error && hasError && t(meta.error)) || helperTextToDisplay

  return (
    <Field
      as={TextField}
      id={name}
      type={type}
      label={t(label)}
      margin={margin}
      helperText={helperText}
      error={hasError}
      variant={variant}
      size="small"
      fullWidth
      name={name}
      InputProps={
        onRemove && {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="delete item" onClick={onRemove} edge="end" size="large">
                <Delete />
              </IconButton>
            </InputAdornment>
          ),
        }
      }
      {...props}
    />
  )
}

const InputFieldArray: React.FC<InputFieldArrayProps> = ({
  name,
  label,
  type,
  defaultHelperText,
  parentDefaultHelperText,
  margin = 'normal',
  onMouseLeave,
  variant = 'standard',
  addButtonLabel = 'commons.actions.add',
  canAdd = true,
  ...props
}) => {
  const t = useAppTranslations()
  const [, meta] = useField({ name })

  const hasError = Boolean(meta.touched && meta.error)
  parentDefaultHelperText

  return (
    <Box onMouseLeave={onMouseLeave}>
      <FieldArray
        name={name}
        render={(arrayHelpers: any) => (
          <Box>
            <Grid container>
              <Grid item xs={12} mt="9px">
                <FormLabel sx={{ ...labelStyle }}>{t(label)}</FormLabel>
              </Grid>
              <Grid item xs={12}>
                {meta.value &&
                  meta.value.length > 0 &&
                  meta.value.map((_item: unknown, index: number) => (
                    <SubInputField
                      key={index}
                      type={type}
                      label={`${t(label)} ${index + 1}`}
                      margin={margin}
                      defaultHelperText={defaultHelperText}
                      variant={variant}
                      fullWidth
                      name={`${name}.${index}`}
                      onRemove={
                        index === 0 && props.required && meta.value.length < 2
                          ? undefined
                          : () => arrayHelpers.remove(index)
                      }
                      {...props}
                    />
                  ))}
                <StyledButtonAsInput
                  type="button"
                  size="small"
                  onClick={() => arrayHelpers.push('')}
                  startIcon={<Add />}
                  disabled={!canAdd}
                >
                  {t(addButtonLabel)}
                </StyledButtonAsInput>
              </Grid>
            </Grid>
            <FormHelperText error={hasError}>{parentDefaultHelperText}</FormHelperText>
          </Box>
        )}
      />
    </Box>
  )
}

export default InputFieldArray
