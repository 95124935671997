import { ButtonClasses } from '@mui/material'
import { StyleRules } from '@mui/styles'

export const buttonTheme: Partial<StyleRules<ButtonClasses>> = {
  styleOverrides: {
    root: {
      textTransform: 'none',
    },
    containedSizeLarge: {
      fontSize: '1.4rem',
    },
    iconSizeLarge: {
      '&> *:first-of-type': {
        fontSize: '1.6rem',
      },
    },
  },
  defaultProps: {
    disableElevation: true,
    size: 'large',
    variant: 'contained',
  },
}
