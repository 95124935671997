import React, { useEffect, useState } from 'react'
import { EditionModeContextProvider, EditionModes, useEditionMode } from './providers/editionModeContext'
import { useField, useFormikContext } from 'formik'
import { isEqual } from 'lodash-es'

export interface HoverProp {
  disableHoverEdition?: boolean
}

interface HoverEditionHandlerInterface {
  handleFocus: () => void
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void
  setDisplayOnLeave: (() => void) | undefined
  setEditionOnEnter: (() => void) | undefined
}

export const withHoverEditionMode =
  <P extends HoverProp>(Component: React.FC<P>): React.FC<P> =>
  (props: HoverProp) => {
    return (
      <>
        {!props.disableHoverEdition ? (
          <EditionModeContextProvider>
            <Component {...(props as P)} />
          </EditionModeContextProvider>
        ) : (
          <Component {...(props as P)} />
        )}
      </>
    )
  }

export const useHoverEditionHandlers = (name: string, disableHoverEdition: boolean): HoverEditionHandlerInterface => {
  const [isFocused, setIsFocused] = useState(false)
  const [, setEditionMode] = useEditionMode()
  const [, meta] = useField({ name })
  const formik = useFormikContext()

  useEffect(() => {
    if (disableHoverEdition) return
    formik.isSubmitting && setDisplay()
  }, [formik.isSubmitting])

  useEffect(() => {
    if (disableHoverEdition) return
    setDisplay()
  }, [meta.value, meta.initialValue])

  const isDifferent = !isEqual(meta.value, meta.initialValue)

  const setDisplay = (): void => {
    if (isFocused) return
    if (isDifferent) return
    else setEditionMode(EditionModes.Display)
  }

  const setEdition = (): void => setEditionMode(EditionModes.Edition)

  const handleFocus = (): void => {
    setIsFocused(true)
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (e.relatedTarget !== e.currentTarget) {
      setIsFocused(false)
      !isDifferent && setEditionMode(EditionModes.Display)
    }
  }

  return {
    handleFocus,
    handleBlur,
    setDisplayOnLeave: disableHoverEdition ? undefined : setDisplay,
    setEditionOnEnter: disableHoverEdition ? undefined : setEdition,
  }
}
