import React, { useContext } from 'react'
import { AdvertiserConfigQuery, useAdvertiserConfigQuery } from '../../../__generated__/graphql'
import { useCurrentCompanyDisplayedContext } from '../../../pages/company/currentCompanyDisplayedContext'
import PwsSpinner from '../../../components/loader/PwsSpinner'

const AdvertiserConfigContext = React.createContext<undefined | AdvertiserConfigQuery['advertiser']>(undefined)
AdvertiserConfigContext.displayName = 'AdvertiserConfigContext'

export const AdvertiserConfigContextProvider: React.FC = ({ children }) => {
  const {
    company: { id },
  } = useCurrentCompanyDisplayedContext()
  const { data: advertiserConfig, loading } = useAdvertiserConfigQuery({ variables: { id } })

  return (
    <AdvertiserConfigContext.Provider value={advertiserConfig?.advertiser}>
      {loading ? <PwsSpinner /> : children}
    </AdvertiserConfigContext.Provider>
  )
}

export const useAdvertiserConfigContext = (): AdvertiserConfigQuery['advertiser'] => {
  const value = useContext(AdvertiserConfigContext)
  if (!value) throw new Error('Value not found in advertiser config context')
  return value
}
