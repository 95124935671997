interface Countries {
  [key: string]: {
    center: {
      lat: number
      lng: number
    }
    zoom: number
  }
}

export const countriesCenterAndZoom: Countries = {
  fr: {
    center: {
      lat: 46.52863469527167,
      lng: 2.43896484375,
    },
    zoom: 5,
  },
  es: {
    center: {
      lat: 40.5,
      lng: -4,
    },
    zoom: 5,
  },
  gb: {
    center: {
      lat: 52.5897,
      lng: -1.3184,
    },
    zoom: 5,
  },
  be: {
    center: {
      lat: 50.8337,
      lng: 4.4165,
    },
    zoom: 7,
  },
  us: {
    center: {
      lat: 39.7748,
      lng: -100.1953,
    },
    zoom: 4,
  },
  it: {
    center: {
      lat: 41.9023,
      lng: 12.5684,
    },
    zoom: 5,
  },
  pt: {
    center: {
      lat: 38.8226,
      lng: -9.1406,
    },
    zoom: 6,
  },
  ch: {
    center: {
      lat: 46.9803,
      lng: 8.2617,
    },
    zoom: 6,
  },
  nl: {
    center: {
      lat: 52.2143,
      lng: 5.625,
    },
    zoom: 6,
  },
  de: {
    center: {
      lat: 51.0138,
      lng: 10.459,
    },
    zoom: 5,
  },
  gi: {
    center: {
      lat: 36.3151,
      lng: -5.625,
    },
    zoom: 8,
  },
  hk: {
    center: {
      lat: 22.106,
      lng: 114.1699,
    },
    zoom: 8,
  },
  lu: {
    center: {
      lat: 49.7245,
      lng: 6.1523,
    },
    zoom: 7,
  },
  se: {
    center: {
      lat: 63.86,
      lng: 17.4023,
    },
    zoom: 4,
  },
  br: {
    center: {
      lat: -9.7957,
      lng: -50.1855,
    },
    zoom: 3,
  },
}
