import React, { ReactNode, useContext } from 'react'
import { useCurrentUser } from '../hooks/useUserContext'
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material/styles'
import { colors, theme } from '../../styles/theme/theme'
import { createTheme } from '@mui/material/styles'
import pwsLogo from '../../img/logo_powerspace.svg'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const LogoContext = React.createContext<string>(pwsLogo)
LogoContext.displayName = 'LogoContext'

type LogoContextProviderProps = {
  logo: string
}

const LogoContextProvider: React.FC<LogoContextProviderProps> = ({ children, logo }): JSX.Element => {
  return <LogoContext.Provider value={logo}>{children}</LogoContext.Provider>
}

export function useLogo(): string {
  const value = useContext(LogoContext)
  if (!value) throw new Error('provider was not used')
  return value as string
}

export const ThemeContext: React.FC<ReactNode> = ({ children }) => {
  const {
    me: { company: currentUserCompany },
  } = useCurrentUser()

  const userTheme = React.useMemo(() => {
    if (currentUserCompany.style)
      return createTheme(theme, {
        palette: {
          ...colors,
          secondary: {
            main: currentUserCompany.style.primaryColor,
            dark: currentUserCompany.style.secondaryColor,
          },
        },
      })
    else return theme
  }, [currentUserCompany])

  const logoToDisplay = currentUserCompany.style?.logoUrl || pwsLogo

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={userTheme}>
        <LogoContextProvider logo={logoToDisplay}>{children}</LogoContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
