import { CompanyType } from '../__generated__/graphql'
import { getCompanyUrl } from '../utils/getcompanyUrl'

export interface UserRoute extends Record<string, string> {
  id: string
}
export const UserRouteUrl = '/user/:id'
export const UserRouteBuilder = (id: string): string => `/user/${id}`

export interface ActivityRoute extends Record<string, string> {
  slug: string
}

export const ActivityRouteUrl = '/:companyType/:slug/activity'
export const ActivityRouteBuilder = (companyType: string, slug: string): string => `/${companyType}/${slug}/activity`
export interface AgencyRoute extends Record<string, string> {
  slug: string
}
export const AgencyRouteUrl = '/agency/:slug'
export const AgencyRouteBuilder = (slug: string): string => `/agency/${slug}`

export interface ResellerRoute extends Record<string, string> {
  slug: string
}
export const ResellerRouteUrl = '/reseller/:slug'
export const ResellerRouteBuilder = (slug: string): string => `/reseller/${slug}`

export interface TradingDeskRoute extends Record<string, string> {
  slug: string
}
export const TradingDeskRouteUrl = '/tradingdesk/:slug'
export const TradingDeskRouteBuilder = (slug: string): string => `/tradingdesk/${slug}`
export interface AdvertiserRoute extends Record<string, string> {
  slug: string
}
export const AdvertiserRouteUrl = '/advertiser/:slug'
export const AdvertiserRouteBuilder = (slug: string): string => `/advertiser/${slug}`

export interface CampaignRoute extends AdvertiserRoute {
  campaignId: string
}
export const CampaignRouteUrl = AdvertiserRouteUrl + '/campaign/:campaignId'
export const CampaignRouteBuilder = (slug: string, campaignId: string): string =>
  AdvertiserRouteBuilder(slug) + `/campaign/${campaignId}`

export interface CampaignProgrammaticRoute extends TradingDeskRoute {
  campaignProgrammaticId: string
}
export const CampaignProgrammaticRouteUrl = TradingDeskRouteUrl + '/campaign/:campaignProgrammaticId'
export const CampaignProgrammaticRouteBuilder = (slug: string, campaignProgrammaticId: string): string =>
  TradingDeskRouteBuilder(slug) + `/campaign/${campaignProgrammaticId}`

export interface ExternalAdGroupRoute {
  adgroupId: string
}
export const ExternalAdGroupLinkUrl = '/adgroup/:adgroupId'

export interface AdGroupRoute extends AdvertiserRoute {
  adgroupId: string
}
export const AdGroupRouteUrl = AdvertiserRouteUrl + '/adgroup/:adgroupId'

export const AdGroupRouteBuilder = (slug: string, adGroupId: string): string =>
  AdvertiserRouteBuilder(slug) + `/adgroup/${adGroupId}`
export interface InventoryRoute extends TradingDeskRoute {
  inventoryId: string
}
export const InventoryRouteUrl = TradingDeskRouteUrl + '/adgroup/:inventoryId'
export interface AdCopyRoute extends AdGroupRoute {
  adcopyId: string
}

export const InventoryRouteBuilder = (slug: string, inventoryId: string): string =>
  TradingDeskRouteBuilder(slug) + `/adgroup/${inventoryId}`

export const AdCopyRouteUrl = AdGroupRouteUrl + '/creatives/:adcopyId'
export const AdCopyRouteBuilder = (slug: string, adGroupId: string, adCopyId: string): string =>
  AdGroupRouteBuilder(slug, adGroupId) + `/creatives/${adCopyId}`

export interface PublisherRoute extends Record<string, string> {
  slug: string
}

export const PublisherRouteUrl = '/publisher/:slug'
export const PublisherRouteBuilder = (slug: string): string => `/publisher/${slug}`

export interface InvoiceRoute extends PublisherRoute {
  reference: string
}
export const InvoiceRouteUrl = PublisherRouteUrl + '/invoices/:reference'
export const InvoiceRouteBuilder = (slug: string, reference: string): string =>
  PublisherRouteBuilder(slug) + `/invoices/${reference}`

export interface WebsiteRoute extends PublisherRoute {
  siteId: string
}
export const WebsiteRouteUrl = PublisherRouteUrl + '/site/:siteId'
export const WebsiteRouteBuilder = (slug: string, websiteId: string): string =>
  PublisherRouteBuilder(slug) + `/site/${websiteId}`

export interface PositionRoute extends PublisherRoute {
  positionId: string
}
export const PositionRouteUrl = PublisherRouteUrl + '/position/:positionId'
export const PositionRouteBuilder = (slug: string, positionId: string): string =>
  PublisherRouteBuilder(slug) + `/position/${positionId}`

export interface AdTemplateRoute extends AdvertiserRoute {
  templateId: string
}
export const AdTemplateRouteUrl = PositionRouteUrl + '/templates/:templateId'
export const AdTemplateRouteBuilder = (slug: string, positionId: string, adTemplateId: string): string =>
  PositionRouteBuilder(slug, positionId) + `/templates/${adTemplateId}`

export const CompanyRouteBuilder = (slug: string, type: CompanyType): string => getCompanyUrl({ slug, type })
export type CompanyRoute = AdvertiserRoute | PublisherRoute | TradingDeskRoute | AgencyRoute | ResellerRoute
export type AllRoute =
  | UserRoute
  | AgencyRoute
  | AdvertiserRoute
  | CampaignRoute
  | AdGroupRoute
  | AdCopyRoute
  | WebsiteRoute
  | PositionRoute
  | PublisherRoute
  | PositionRoute
  | TradingDeskRoute
  | InventoryRoute
  | CampaignProgrammaticRoute
  | InvoiceRoute
