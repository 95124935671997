import { CompanyType } from '../__generated__/graphql'
import {
  AdvertiserRouteBuilder,
  PublisherRouteBuilder,
  TradingDeskRouteBuilder,
  ResellerRouteBuilder,
  AgencyRouteBuilder,
} from '../pages/routeParams'

type RouteBuilders = {
  [key in CompanyType]: (slug: string) => string
}

export const getCompanyUrl = (company: { type: CompanyType; slug: string }): string => {
  const mapCompanyRouteBuilders: RouteBuilders = {
    [CompanyType.Advertiser]: AdvertiserRouteBuilder,
    [CompanyType.Publisher]: PublisherRouteBuilder,
    [CompanyType.TradingDesk]: TradingDeskRouteBuilder,
    [CompanyType.Reseller]: ResellerRouteBuilder,
    [CompanyType.Agency]: AgencyRouteBuilder,
  }

  const routeBuilder = mapCompanyRouteBuilders[company.type]
  return routeBuilder(company.slug)
}
