import React from 'react'
import { Box, SxProps, TextField, Theme } from '@mui/material'
import { useField } from 'formik'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import MenuItem from '@mui/material/MenuItem'
import HelperTooltip from './HelperTooltip'

export interface SelectFieldProps {
  name: string
  label: string
  withNone?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  required?: boolean
  multiple?: boolean
  disabled?: boolean
  placeholder?: string
  sx?: SxProps<Theme>
  onMouseLeave?: () => void
  tooltipText?: string
  variant?: 'filled' | 'outlined' | 'standard'
  defaultHelperText?: string
  renderValue?: (value: unknown) => React.ReactNode
}

const SelectField: React.FC<SelectFieldProps> = ({
  name,
  label,
  withNone = true,
  children,
  multiple = false,
  sx,
  onMouseLeave,
  tooltipText,
  variant = 'standard',
  defaultHelperText = '',
  renderValue,
  ...props
}) => {
  const t = useAppTranslations()
  const [field, meta] = useField({ name })

  const hasError = Boolean(meta.touched && meta.error)
  const helperText = (meta.error && hasError && t(meta.error)) || defaultHelperText

  return (
    <Box onMouseLeave={onMouseLeave}>
      <TextField
        InputLabelProps={{ shrink: true }}
        id={name}
        {...field}
        {...props}
        label={
          <>
            {t(label)}
            {tooltipText && <HelperTooltip helperText={tooltipText} sx={{ fontSize: 15, marginLeft: '6.5px' }} />}
          </>
        }
        variant={variant}
        size="small"
        margin="normal"
        select
        fullWidth
        error={hasError}
        helperText={helperText}
        SelectProps={{
          multiple: multiple,
          displayEmpty: withNone || !!renderValue,
          renderValue,
        }}
        sx={sx}
      >
        {withNone && (
          <MenuItem value="">
            <em>{t('commons.emptyField')}</em>
          </MenuItem>
        )}
        {children}
      </TextField>
    </Box>
  )
}

export default SelectField
