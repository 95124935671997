import React, { useEffect, useMemo, useState } from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { CompanyType, GetCompanyDetailsQuery } from '../../../__generated__/graphql'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import MenuWithIcon, { MenuActionItem } from '../MenuWithIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import { Item, sidebarPalette } from './Sidebar'
import CreateWebsite from '../../../pages/website/create/CreateWebsite'
import { FormDialog } from '../FormDialog'
import CreateCampaign from '../../../pages/campaign/CreateCampaign'
import { AdvertiserRouteBuilder, TradingDeskRouteBuilder, PublisherRouteBuilder } from '../../../pages/routeParams'
import { NoUnderlineLink } from '../styled'
import styled from '@emotion/styled'
import theme from '../../../styles/theme'

const StyledListItemText = styled(ListItemText)(() => ({
  fontSize: '1.4rem',
  textTransform: 'uppercase',
  fontWeight: 600,
  color: theme.palette.secondary.contrastText,
}))

export const SidebarHeader: React.FC<{
  updateWithArchived: (showArchived: boolean) => void
  updateSidebarItems: () => void
  items: Item[]
}> = ({ updateWithArchived, updateSidebarItems, items }): JSX.Element => {
  const t = useAppTranslations()
  const { company } = useCompanyDetailsContext()
  const companyTypesWithoutMenu: Array<CompanyType> = [CompanyType.Reseller]
  const [showArchived, setShowArchived] = useState<boolean>(false)

  useEffect(() => {
    updateWithArchived(showArchived)
  }, [showArchived])

  if (companyTypesWithoutMenu.includes(company.type)) return <></>

  return (
    <SidebarHeaderContent
      currentCompany={company}
      items={items}
      archiveText={t(`layout.sidebar.${showArchived ? 'hideArchived' : 'showArchived'}`)}
      toggleArchived={() => setShowArchived(!showArchived)}
      updateSidebarItems={updateSidebarItems}
    />
  )
}

const SidebarHeaderContent: React.FC<{
  currentCompany: GetCompanyDetailsQuery['company']
  items: Item[]
  toggleArchived: () => void
  archiveText: string
  updateSidebarItems: () => void
}> = ({ currentCompany, toggleArchived, archiveText, updateSidebarItems, items }) => {
  if (currentCompany.type === CompanyType.Agency) return <StyledHeader>{currentCompany.name}</StyledHeader>
  if (currentCompany.type === CompanyType.Publisher)
    return (
      <>
        <PublisherSidebarHeaderContent
          currentCompany={currentCompany}
          items={items}
          toggleArchived={toggleArchived}
          archiveText={archiveText}
          updateSidebarItems={updateSidebarItems}
        />
      </>
    )

  if (currentCompany.type === CompanyType.Advertiser || currentCompany.type === CompanyType.TradingDesk)
    return (
      <>
        <AdvertiserSidebarHeaderContent
          currentCompany={currentCompany}
          items={items}
          toggleArchived={toggleArchived}
          archiveText={archiveText}
          updateSidebarItems={updateSidebarItems}
        />
      </>
    )

  return <></>
}

const StyledHeader: React.FC<{ link?: string; actions?: MenuActionItem[] }> = ({ link, actions, children }) => {
  return (
    <ListItem
      data-testid="sidebarHeaderContent"
      secondaryAction={
        actions && actions.length > 0 ? (
          <MenuWithIcon edge="end" actionItems={actions} dataId="sidebarMenuIcon">
            <MoreVertIcon fontSize="small" />
          </MenuWithIcon>
        ) : null
      }
      sx={{
        backgroundColor: sidebarPalette.dark,
        minHeight: '6.6rem',
      }}
    >
      {link ? (
        <NoUnderlineLink to={link}>
          <StyledListItemText>{children}</StyledListItemText>
        </NoUnderlineLink>
      ) : (
        <StyledListItemText>{children}</StyledListItemText>
      )}
    </ListItem>
  )
}

const AdvertiserSidebarHeaderContent: React.FC<{
  currentCompany: GetCompanyDetailsQuery['company']
  items: Item[]
  toggleArchived: () => void
  archiveText: string
  updateSidebarItems: () => void
}> = ({ currentCompany, toggleArchived, archiveText, updateSidebarItems, items }) => {
  const t = useAppTranslations()
  const [createCampaignDialogOpened, setCreateCampaignDialogOpened] = useState(false)

  const advertiserActionItems = useMemo(() => {
    const defaultMenu: MenuActionItem[] = [
      {
        text: t('layout.sidebar.advertiser.createCampaign'),
        action: () => setCreateCampaignDialogOpened(true),
        autoClose: true,
      },
    ]

    return defaultMenu.concat([
      {
        text: archiveText,
        action: toggleArchived,
      },
    ])
  }, [items])

  const link =
    currentCompany.type === CompanyType.TradingDesk
      ? TradingDeskRouteBuilder(currentCompany.slug)
      : AdvertiserRouteBuilder(currentCompany.slug)

  return (
    <>
      <StyledHeader link={link} actions={advertiserActionItems}>
        {currentCompany.name}
      </StyledHeader>

      <FormDialog
        title={t('campaign.create')}
        open={createCampaignDialogOpened}
        setOpen={setCreateCampaignDialogOpened}
        form={<CreateCampaign onCampaignCreation={updateSidebarItems} setOpen={setCreateCampaignDialogOpened} />}
      />
    </>
  )
}

const PublisherSidebarHeaderContent: React.FC<{
  currentCompany: GetCompanyDetailsQuery['company']
  items: Item[]
  toggleArchived: () => void
  archiveText: string
  updateSidebarItems: () => void
}> = ({ currentCompany, toggleArchived, archiveText, updateSidebarItems, items }) => {
  const t = useAppTranslations()
  const [createWebsiteDialogOpened, setCreateWebsiteDialogOpened] = useState(false)

  const publisherActionItems = useMemo(() => {
    const defaultMenu: MenuActionItem[] = [
      {
        text: t('layout.sidebar.publisher.createWebsite'),
        action: () => setCreateWebsiteDialogOpened(true),
        autoClose: true,
      },
    ]

    return defaultMenu.concat([
      {
        text: archiveText,
        action: toggleArchived,
      },
    ])
  }, [items])

  return (
    <>
      <StyledHeader link={PublisherRouteBuilder(currentCompany.slug)} actions={publisherActionItems}>
        {currentCompany.name}
      </StyledHeader>

      <FormDialog
        title={t('website.create')}
        open={createWebsiteDialogOpened}
        setOpen={setCreateWebsiteDialogOpened}
        form={
          <CreateWebsite
            onWebsiteCreation={updateSidebarItems}
            setOpen={setCreateWebsiteDialogOpened}
            company={currentCompany}
          />
        }
      />
    </>
  )
}
