import { Delete } from '@mui/icons-material'
import { Button, Typography, styled } from '@mui/material'
import { Link } from 'react-router-dom'

export const DisabledDeleteIcon = styled(Delete)(({ theme }) => ({
  color: `${theme.palette.grey[400]} !important`,
}))

export const UnStyledLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const NoUnderlineLink = styled(Link)(() => ({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const StyledDownloadLink = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const StyledMessage = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  padding: '10px',
  backgroundColor: theme.palette.grey[200],
  borderRadius: '4px',
  color: theme.palette.text.secondary,
  fontWeight: 'bold',
}))

export const StyledButtonAsInput = styled(Button)({
  borderColor: 'rgba(0, 0, 0, 0.23)',
  '&:hover': {
    borderColor: 'inherit',
  },
  color: 'inherit',
})

StyledButtonAsInput.defaultProps = {
  variant: 'outlined',
}
