import React from 'react'
import { useAgencyAdvertisersSidebarItemsQuery } from '../../../__generated__/graphql'
import { SidebarHeader } from './SidebarHeader'
import { SidebarFooter } from './SidebarFooter'
import { DivMenuContent } from './SidebarBody'
import { NetworkStatus } from '@apollo/client'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import SidebarSkeleton from '../../loader/SidebarSkeleton'
import { ListSidebarContent } from './Sidebar'
import { ListItemButton, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'
import { AdvertiserRouteBuilder } from '../../../pages/routeParams'
import { useSidebarStyles } from './SidebarItem'

const AgencySidebarContent = (): JSX.Element => {
  const classes = useSidebarStyles()
  const companyDetails = useCompanyDetailsContext()
  const {
    data: advertisers,
    refetch: refetchAdvertisers,
    networkStatus: advertisersNetworkStatus,
  } = useAgencyAdvertisersSidebarItemsQuery({
    variables: {
      agencyId: companyDetails.company.id,
      isActive: true,
    },
    notifyOnNetworkStatusChange: true,
  })

  return (
    <ListSidebarContent>
      <SidebarHeader
        updateWithArchived={(showArchived: boolean) => refetchAdvertisers({ isActive: showArchived ? false : true })}
        updateSidebarItems={() => refetchAdvertisers()}
        items={[]}
      />
      {advertisersNetworkStatus === NetworkStatus.loading ? (
        <SidebarSkeleton />
      ) : (
        <DivMenuContent data-testid="sidebarBody">
          {advertisers?.searchCompanies?.map((advertiser) => (
            <ListItemButton
              key={advertiser.id}
              disableRipple
              component={NavLink}
              activeClassName={classes.activeItem}
              to={AdvertiserRouteBuilder(advertiser.slug)}
            >
              <ListItemText>{advertiser.name}</ListItemText>
            </ListItemButton>
          ))}
        </DivMenuContent>
      )}
      <SidebarFooter />
    </ListSidebarContent>
  )
}

export default AgencySidebarContent
