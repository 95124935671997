import React, { createContext, useContext, useEffect, useState } from 'react'
import { ConfirmDialog } from '../../components/layout/ConfirmDialog'

export interface ConfirmDialogState {
  message: string | string[]
  buttonLabel: string
  onSubmit: undefined | (() => void)
}

type ContextValue = React.Dispatch<React.SetStateAction<ConfirmDialogState>>

const ConfirmDialogContext = createContext<ContextValue | null>(null)
ConfirmDialogContext.displayName = 'ConfirmDialogContext'

export const ConfirmDialogContextProvider: React.FC = ({ children }): JSX.Element => {
  const initialState = {
    message: '',
    buttonLabel: '',
    onSubmit: undefined,
  }

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogState>(initialState)

  useEffect(() => {
    if (confirmDialogState.onSubmit !== undefined) setOpenConfirmDialog(true)
  }, [confirmDialogState])

  const onConfirm = (): void => {
    if (confirmDialogState.onSubmit !== undefined) confirmDialogState.onSubmit()
    setOpenConfirmDialog(false)
  }

  return (
    <ConfirmDialogContext.Provider value={setConfirmDialogState}>
      <ConfirmDialog
        message={confirmDialogState.message}
        actionButton={{
          label: confirmDialogState.buttonLabel,
          action: onConfirm,
        }}
        setOpen={setOpenConfirmDialog}
        open={openConfirmDialog}
      />
      {children}
    </ConfirmDialogContext.Provider>
  )
}

export function useConfirmDialogContext(): ContextValue {
  const value = useContext(ConfirmDialogContext)
  if (!value) throw new Error('confirmDialog context must be initialized before using it')
  return value
}
