import { theme } from './theme'
import { TabClasses, TabsClasses } from '@mui/material'

import { StyleRules } from '@mui/styles'

export const tabsTheme: Partial<StyleRules<TabsClasses>> = {
  styleOverrides: {
    indicator: {
      position: 'absolute',
      height: '.5rem',
      backgroundColor: theme.palette.primary.main,
    },
    root: {
      backgroundColor: theme.palette.header,
    },
  },
}

export const tabTheme: Partial<StyleRules<TabClasses>> = {
  styleOverrides: {
    root: {
      height: '6.6rem',
      fontWeight: 'bold',
      marginLeft: '4rem',
      padding: 0,
      [theme.breakpoints.up('md')]: {
        minWidth: 0,
      },
      '&.Mui-selected': {
        color: theme.palette.text.primary,
      },
    },
    textColorInherit: {
      color: theme.palette.text.secondary,
    },
    wrapper: {
      minWidth: 0,
    },
  },
  defaultProps: {
    disableRipple: true,
  },
}
