import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import { getUserToken, logout } from './utils/tokenManagement'
import { omitDeep } from './utils/omitDeep'
import { API_URL } from './env'

const uploadLink = createUploadLink({
  uri: `${API_URL}/graphql`,
})

const authLink = setContext((_, { headers }) => {
  const token = getUserToken()

  return {
    headers: {
      ...headers,
      Authorization: token ? token : '',
    },
  }
})

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        isLoggedIn() {
          return isLoggedInVar()
        },
      },
    },
  },
})

export const isLoggedInVar = cache.makeVar<boolean>(!!localStorage.getItem('token'))

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const errorCode = graphQLErrors[0]?.extensions?.code
    if (errorCode === 'UNAUTHENTICATED') logout(apolloClient, true)
  }

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const cleanMutationTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) operation.variables = omitDeep(operation.variables, ['__typename'])

  return forward(operation)
})

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, cleanMutationTypeName, uploadLink as unknown as ApolloLink]),
  cache,
  resolvers: {},
})
