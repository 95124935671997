import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { USER_LANGUAGES } from '../userLanguages'
import { locales } from '../dayjsLocales'
import dayjs from 'dayjs'

const getDefaultLanguage = (): string => {
  const browserLanguage = navigator.language.slice(0, 2)
  return USER_LANGUAGES.includes(browserLanguage) ? browserLanguage : 'en'
}

export const useSetLanguage = (language?: string): void => {
  const { i18n } = useTranslation(['translations'])

  useEffect(() => {
    const selectedLanguage = language || getDefaultLanguage()
    i18n.changeLanguage(selectedLanguage)
    locales[selectedLanguage as keyof typeof locales].then(() => dayjs.locale(selectedLanguage))
  }, [language])
}
