import { Iab } from '../../__generated__/graphql'
import { useAppTranslations } from './useAppTranslations'

export function replaceUnderscoreWithDash(value: string): string {
  return value.replace('_', '-')
}

export function removeIAB(value: string): string {
  return value.replace('IAB', '')
}

export function getReadableIAB(value: string): string {
  const valueWithoutUnderscore = value.replace('_', '-')
  return valueWithoutUnderscore.replace('IAB', '')
}

export function useIABSelectOptions(): { value: Iab; label: string }[] {
  const t = useAppTranslations()

  return Object.values(Iab).map((value) => {
    const valueWithoutUnderscore = replaceUnderscoreWithDash(value)

    return {
      value,
      label: t('iabCategories.' + valueWithoutUnderscore),
    }
  })
}
