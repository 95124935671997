import React from 'react'
import { Link } from 'react-router-dom'
import { styled, Tooltip } from '@mui/material'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'

interface LinkCellProps {
  value: string | JSX.Element
  target: string
  label?: string
}

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.primary.main,
}))

const LinkCell: React.FC<LinkCellProps> = ({ value, target, label }): JSX.Element => {
  const t = useAppTranslations()
  if (!target) return <>{value}</>
  return (
    <Tooltip disableFocusListener disableTouchListener title={t('commons.goTo', { item: label ?? value }) as string}>
      <StyledLink to={target}>{value}</StyledLink>
    </Tooltip>
  )
}
export default LinkCell
