import React from 'react'
import TimeseriesWidgets from './TimeseriesWidgets'
import TimeseriesChart from './TimeseriesChart'
import {
  AdvertiserCostDetails,
  AdvertiserTimeseriesCost,
  PublisherCostDetails,
  PublisherTimeseriesCost,
} from '../../../__generated__/graphql'
import { StatsConfKeys } from '../../../utils/statsConf'
import { ChartOptionsType } from '../../../utils/hooks/costDetails/useTimeseries'
import { ApolloError } from '@apollo/client'
import ErrorAlert from '../../error/ErrorAlert'
import { Skeleton } from '@mui/material'

type TimeseriesProps = {
  timeseriesData?: {
    total: AdvertiserCostDetails | PublisherCostDetails
    timeseries: AdvertiserTimeseriesCost[] | PublisherTimeseriesCost[]
  }
  chartItems: StatsConfKeys[]
  chartItemsVisibility: Record<StatsConfKeys, boolean>
  toggleVisibility: (name: StatsConfKeys) => void
  dateFormat: string
  chartOptions: ChartOptionsType
  isPublisherStats: boolean
  loading: boolean
  error?: ApolloError
}

const Timeseries: React.FC<TimeseriesProps> = ({
  timeseriesData,
  chartItems,
  chartItemsVisibility,
  toggleVisibility,
  dateFormat,
  chartOptions,
  isPublisherStats,
  error,
  loading,
}) => {
  if (error) return <ErrorAlert message={error.message} />
  return (
    <>
      <TimeseriesWidgets
        costDetails={timeseriesData}
        chartItems={chartItems}
        chartItemsVisibility={chartItemsVisibility}
        handleToggleVisibility={toggleVisibility}
        isPublisherStats={isPublisherStats}
        loading={loading}
      />
      {loading ? (
        <Skeleton variant="rectangular" height="350px" />
      ) : (
        timeseriesData && (
          <TimeseriesChart
            costDetails={timeseriesData}
            chartItems={chartItems}
            chartItemsVisibility={chartItemsVisibility}
            chartDateFormat={dateFormat}
            isPublisherStats={isPublisherStats}
            chartOptions={chartOptions}
          />
        )
      )}
    </>
  )
}

export default Timeseries
