import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { AdGroupRouteBuilder, ExternalAdGroupRoute } from '../routeParams'
import { useAdGroupAdvertiserSlugQuery } from '../../__generated__/graphql'
import PwsSpinner from '../../components/loader/PwsSpinner'
import { useSnackbarManager } from '../../utils/providers/snackbarContext'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

const AdGroupToFullLinkRedirect: React.FC = () => {
  const t = useAppTranslations()
  const [, setSnackbar] = useSnackbarManager()
  const { adgroupId } = useParams<ExternalAdGroupRoute>()
  const { data: adGroupData, loading, error } = useAdGroupAdvertiserSlugQuery({ variables: { id: adgroupId } })

  if (error) {
    setSnackbar({ message: t('adgroup.notFound'), severity: 'error' })
    return <Redirect to="/" />
  }
  if (loading || !adGroupData) return <PwsSpinner />
  return <Redirect to={AdGroupRouteBuilder(adGroupData?.adGroup?.campaign?.advertiser?.slug, adgroupId)} />
}

export default AdGroupToFullLinkRedirect
