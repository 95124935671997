import dayjs from 'dayjs'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState, useEffect, useMemo } from 'react'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { GraphGranularity, AdvertiserCostDetails, PublisherCostDetails } from '../../../__generated__/graphql'
import { CostDetailsTimeFilters } from '../../../components/stats/filters/useStatsFilterStorage'
import { CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY } from '../../../env'
import { StatsEntity } from '../../../interfaces/stats'
import { useLocalStorage } from '../useLocalStorage'
import { StatsConfKeys } from '../../statsConf'

dayjs.extend(utc)
dayjs.extend(timezone)

export const CHART_DATE_FORMAT = {
  [GraphGranularity.All]: 'all',
  [GraphGranularity.Hour]: 'D MMMM YYYY HH:mm',
  [GraphGranularity.Day]: 'D MMMM YYYY - dddd',
  [GraphGranularity.Week]: 'D MMMM YYYY - dddd',
  [GraphGranularity.Month]: 'MMMM',
  [GraphGranularity.Year]: 'YYYY',
}

export type ChartOptionsType = {
  displayValues: boolean
}

type TimeseriesType = {
  timeseries: {
    date: Date
    total: AdvertiserCostDetails | PublisherCostDetails
  }[]
}

export type CostDetailsDataType<T> = T & TimeseriesType

type UseCostDetailsReturnType<T extends TimeseriesType> = {
  costDetailsData: CostDetailsDataType<T> | undefined
  chartItemsVisibility: Record<StatsConfKeys, boolean>
  toggleVisibility: (name: StatsConfKeys) => void
  chartOptions: ChartOptionsType
  setChartOptions: React.Dispatch<React.SetStateAction<ChartOptionsType>>
  chartItems: StatsConfKeys[]
  dateFormat: string
  setDateFormat: React.Dispatch<React.SetStateAction<string>>
}

export const useCostDetails = <T extends TimeseriesType>(
  costDetails: Maybe<CostDetailsDataType<T>>,
  options: {
    filters: CostDetailsTimeFilters
    entity: StatsEntity
  }
): UseCostDetailsReturnType<T> => {
  const { filters, entity } = options
  const [dateFormat, setDateFormat] = useState<string>(CHART_DATE_FORMAT[filters.granularity])
  const [chartOptions, setChartOptions] = useState<ChartOptionsType>({ displayValues: false })
  const [chartItemsVisibility, setChartItemsVisibility] = useLocalStorage<Record<StatsConfKeys, boolean>>(
    'costDetails.widgets',
    {} as Record<StatsConfKeys, boolean>
  )

  const isPublisherStats = useMemo(
    () =>
      [StatsEntity.Websites, StatsEntity.Positions, StatsEntity.ResellerPublisher, StatsEntity.Publishers].includes(
        entity
      ),
    [entity]
  )

  const chartItems: StatsConfKeys[] = useMemo(() => {
    if (!costDetails) return []

    return isPublisherStats
      ? ['displays', 'impressions', 'clicks', 'ctr', 'cpc', 'rpm', 'cpm', 'commissions']
      : ['impressions', 'clicks', 'ctr', 'cpc', 'cpm', 'costs'] // 'leads', 'orders',
  }, [costDetails])

  const updateValueVisibility = (chartItemsVisibility: Record<StatsConfKeys, boolean>): void => {
    const visibleItemCount = chartItems.filter((item) => chartItemsVisibility[item]).length
    setChartOptions({ ...chartOptions, displayValues: visibleItemCount <= CONF_CHART_MAX_LINES_FOR_VALUE_DISPLAY })
  }

  useEffect(() => {
    const newValues = chartItems.reduce((acc, name) => ({ ...acc, [name]: true }), {})
    setChartItemsVisibility({ ...newValues, ...chartItemsVisibility })
    updateValueVisibility({ ...newValues, ...chartItemsVisibility })
  }, [chartItems])

  const toggleVisibility = (name: StatsConfKeys): void => {
    const newValues = { ...chartItemsVisibility, [name]: !chartItemsVisibility[name] }
    updateValueVisibility(newValues)
    setChartItemsVisibility(newValues)
  }

  const updatedCostDetails = useMemo(() => {
    if (!costDetails) return undefined

    const timeseries =
      costDetails.timeseries === undefined
        ? []
        : costDetails.timeseries
            .map((singleTimeSeries) => {
              return {
                date: dayjs(singleTimeSeries.date).tz(filters.timeZone),
                total: singleTimeSeries.total,
              }
            })
            .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1))

    return Object.assign(costDetails, { timeseries })
  }, [costDetails])

  return {
    costDetailsData: updatedCostDetails,
    chartItemsVisibility,
    toggleVisibility,
    chartOptions,
    setChartOptions,
    chartItems,
    dateFormat,
    setDateFormat,
  }
}
