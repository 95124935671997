import React, { useMemo, useState, ChangeEvent } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useField } from 'formik'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { TargetingCircle } from '../../../__generated__/graphql'
import { Chip } from '@mui/material'
import { UpdateRadiusDialog } from '../../../pages/adgroup/settings/geoTargeting/UpdateRadiusDialog'
import { LocationTypesEnum } from '../../../pages/adgroup/settings/geoTargeting/GeoTargetingFields'
import { AutoCompleteGoogleMapFieldProps, PlaceType } from './intefaces'
import { useAutoComplete } from './useAutoComplete'

const AutoCompleteGoogleMapField: React.FC<AutoCompleteGoogleMapFieldProps> = ({
  name,
  label,
  required = false,
  locationType = LocationTypesEnum.Cities,
  countryCode = 'fr',
  ...props
}) => {
  const t = useAppTranslations()
  const [field] = useField({ name })
  const { value } = field
  const [radiusDialog, setRadiusDialog] = useState<{ open: boolean; cityFieldName: string }>({
    open: false,
    cityFieldName: '',
  })
  const { options, onChange, setInputValue } = useAutoComplete(locationType, name, countryCode)

  const isLocationTypeCities = useMemo((): boolean => locationType === LocationTypesEnum.Cities, [locationType])

  return (
    <>
      <Autocomplete
        {...props}
        id="autoCompleteGoogleMap"
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        value={value}
        multiple
        onChange={(event: ChangeEvent<unknown>, newValue: Array<PlaceType | TargetingCircle>) => onChange(newValue)}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => (
            // key is in getTagProps(...)
            // eslint-disable-next-line react/jsx-key
            <Chip
              {...getTagProps({ index })}
              label={isLocationTypeCities ? `${option.name} - ${option.radius}km` : option?.name || option.label}
              onClick={() => {
                isLocationTypeCities && setRadiusDialog({ open: true, cityFieldName: `targeting.cities.[${index}]` })
              }}
            />
          ))
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required={required}
            label={t(label)}
            variant="outlined"
            fullWidth
            margin="normal"
            size="small"
          />
        )}
        renderOption={(props, option: any) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {option?.structured_formatting?.main_text}
                  <Typography variant="body2" color="textSecondary">
                    {option?.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          )
        }}
      />
      {radiusDialog.open && (
        <UpdateRadiusDialog
          open={radiusDialog.open}
          cityFieldName={radiusDialog.cityFieldName}
          closeDialog={() => setRadiusDialog({ open: false, cityFieldName: '' })}
        />
      )}
    </>
  )
}

export default AutoCompleteGoogleMapField
