import { useCurrentCompanyDisplayedContext } from '../../../pages/company/currentCompanyDisplayedContext'

export const PWS_RESELLER_ID = '1'

export function useIsPowerspaceReseller(): boolean {
  const {
    company: { resellerId },
  } = useCurrentCompanyDisplayedContext()
  return resellerId === PWS_RESELLER_ID
}
