import React, { ReactNode, useContext } from 'react'
import { GetCompanyDetailsQuery, useGetCompanyDetailsQuery } from '../../__generated__/graphql'
import { useParams } from 'react-router-dom'
import { CompanyRoute } from '../routeParams'
import { useDocumentTitle } from '../../utils/providers/documentTitleContext'
import PwsSpinner from '../../components/loader/PwsSpinner'

const CurrentCompanyDisplayedContext = React.createContext<GetCompanyDetailsQuery | undefined>(undefined)
CurrentCompanyDisplayedContext.displayName = 'CurrentCompanyDisplayedContext'

export const CurrentCompanyDisplayedProvider = (props: { children: ReactNode }): JSX.Element => {
  const { slug } = useParams<CompanyRoute>()

  const { data, loading } = useGetCompanyDetailsQuery({
    variables: { slug },
  })

  useDocumentTitle(`${data?.company.name ?? ''} | Powerspace Ads`)
  return (
    <CurrentCompanyDisplayedContext.Provider value={data}>
      {loading ? <PwsSpinner /> : props.children}
    </CurrentCompanyDisplayedContext.Provider>
  )
}

export function useCurrentCompanyDisplayedContext(): GetCompanyDetailsQuery {
  const value = useContext(CurrentCompanyDisplayedContext)
  if (!value) throw new Error('provider was not used')
  return value
}

export function useUserPermissions(): GetCompanyDetailsQuery['company']['userPermissions'] {
  const value = useContext(CurrentCompanyDisplayedContext)?.company?.userPermissions
  if (!value) throw new Error('current company displayed provider was not used')
  return value
}
