import React from 'react'
import { Item, sidebarPalette } from './Sidebar'
import { Items } from './SidebarItem'
import { styled } from '@mui/material'
import FadeWrapper from '../../transitions/FadeWrapper'

export const DivMenuContent = styled('div')(() => ({
  flex: 1,
  overflow: 'auto',

  '& span': {
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    fontWeight: 600,
  },

  '& a:hover': {
    backgroundColor: sidebarPalette.dark,
  },
}))

export const SidebarBody: React.FC<{
  items: Item[]
  updateSidebarItems: () => void
}> = ({ items, updateSidebarItems }) => {
  const sortedItems = items.slice().sort((a, b) => a.name.localeCompare(b.name))

  const displayedItems = sortedItems.map((item) => (
    <FadeWrapper key={item.id}>
      <Items item={item} deployAll={false} updateSidebarItems={updateSidebarItems} />
    </FadeWrapper>
  ))

  return <DivMenuContent data-testid="sidebarBody">{displayedItems}</DivMenuContent>
}
