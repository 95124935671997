import React from 'react'
import { Input, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material'
import { SelectItemInterface } from '../../../../utils/mapEnumForSelect'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'

interface SelectFieldProps<T> {
  defaultValue: T
  onChange: (changes: T) => void
  options: SelectItemInterface[]
}

const StyledInput = styled(Input)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&:before': {
    borderColor: theme.palette.divider,
  },
}))

const CostDetailsTableSelectField = <T extends string>({
  defaultValue,
  onChange,
  options,
}: SelectFieldProps<T>): JSX.Element => {
  const t = useAppTranslations()
  const dataTypesOptions = options.map((type, i) => (
    <MenuItem value={type.value} key={i}>
      {t(`stats.dataTypes.${type.label}`)}
    </MenuItem>
  ))

  const handleChange = (event: SelectChangeEvent<T>): void => {
    const {
      target: { value },
    } = event

    onChange(value as T)
  }

  return (
    <Select
      value={defaultValue}
      onChange={handleChange}
      input={<StyledInput sx={{ px: 1 }} />}
      renderValue={(selected) => t(`stats.dataTypes.${selected}`)}
      autoWidth={true}
    >
      {dataTypesOptions}
    </Select>
  )
}

export default CostDetailsTableSelectField
