import React, { Dispatch, useState } from 'react'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { FormikStepper } from '../../components/form/wizzard/FormikStepper'
import { CampaignCreateInput, CampaignPartsFragment, Iab, useCreateCampaignMutation } from '../../__generated__/graphql'
import { FormikValues } from 'formik'
import * as Yup from 'yup'
import CampaignDetailStep from './dashboard/create/CampaignDetailStep'
import { useNavigation } from '../../utils/hooks/useNavigation'
import { useCompanyDetailsContext } from '../../utils/hooks/useCompanyDetailsContext'
import prepareFormValues from '../../utils/prepareFormValues'
import getIabForBackend from '../../utils/getIabForBackend'
import { Edit } from '@mui/icons-material'

const CreateCampaign: React.FC<{
  onCampaignCreation: () => void
  setOpen: Dispatch<React.SetStateAction<boolean>>
}> = ({ onCampaignCreation, setOpen }) => {
  const t = useAppTranslations()
  const [error, setError] = useState(undefined)
  const [createdCampaign, setCreatedCampaign] = useState<CampaignPartsFragment | null>()
  const [createCampaign] = useCreateCampaignMutation()
  const companyDetails = useCompanyDetailsContext()
  const navigation = useNavigation()

  const initialValues: Partial<Record<keyof CampaignCreateInput, unknown>> = {
    companyId: companyDetails?.company.id,
    marketId: 1,
    name: '',
    advertiserDomainName: '',
    iabCategory: Iab.Iab24,
  }

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    const cleanData = prepareFormValues(values)

    if (cleanData.iabCategory) cleanData.iabCategory = getIabForBackend(cleanData.iabCategory as string)

    await createCampaign({
      variables: {
        campaign: cleanData as CampaignCreateInput,
      },
    })
      .then((res) => {
        const createdCampaign = res?.data?.createCampaign
        if (createdCampaign) {
          onCampaignCreation()
          setCreatedCampaign(createdCampaign)
        }
      })
      .catch((err: React.SetStateAction<undefined>) => setError(err))
  }

  const detailsSchema = Yup.object().shape({
    name: Yup.string().min(3).required('commons.required'),
    advertiserDomainName: Yup.string().min(3).required('commons.required'),
    marketId: Yup.number().required('commons.required'),
  })

  const successButton = {
    label: t('campaign.goToCampaign', { campaignName: createdCampaign?.name }),
    startIcon: <Edit />,
    onClick: () => {
      if (createdCampaign) {
        navigation.goToCampaign(companyDetails?.company.slug, createdCampaign.id)
        setOpen(false)
      }
    },
  }

  return (
    <FormikStepper
      initialValues={initialValues}
      onSubmit={handleSubmit}
      error={error}
      setError={setError}
      enableReinitialize={true}
      successMessage={t('campaign.created')}
      setOpen={setOpen}
      successActionButton={successButton}
    >
      <CampaignDetailStep validationSchema={detailsSchema} label={t('campaign.details.stepperTitle')} />
    </FormikStepper>
  )
}

export default CreateCampaign
