import IconButton, { IconButtonTypeMap } from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import { Link } from 'react-router-dom'
import React, { useCallback } from 'react'
import { Maybe } from '../../__generated__/graphql'
import { ListItemIcon, MenuItem, SxProps, Tooltip } from '@mui/material'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'

type Item = {
  text: string
}

type MenuLinkItem = {
  link: string
} & Item

export type MenuActionItem = {
  action: () => void
  autoClose?: boolean
  icon?: React.ReactNode
} & Item

interface MenuWithIconProps {
  linkItems?: MenuLinkItem[]
  actionItems?: MenuActionItem[]
  dataId?: string
  size?: 'small' | 'medium' | 'large'
  iconButtonSx?: SxProps
  edge?: IconButtonTypeMap['props']['edge']
}

const MenuWithIcon: React.FC<MenuWithIconProps> = ({
  linkItems,
  actionItems,
  dataId,
  children,
  size = 'large',
  iconButtonSx,
  edge,
}) => {
  const t = useAppTranslations()
  const [menu, setMenu] = React.useState<Maybe<HTMLElement>>(null)

  const closeHandler = useCallback((event) => {
    event.stopPropagation()
    setMenu(null)
  }, [])

  if (!linkItems?.length && !actionItems?.length) return null

  return (
    <>
      <Tooltip title={t('commons.actions.moreOptions')} placement="right">
        <IconButton
          data-testid={dataId}
          edge={edge}
          color="inherit"
          onClick={(event) => {
            event.stopPropagation()
            event.preventDefault()
            setMenu(event.currentTarget)
          }}
          size={size}
          sx={iconButtonSx}
        >
          {children}
        </IconButton>
      </Tooltip>
      <Menu anchorEl={menu} keepMounted open={Boolean(menu)} onClose={closeHandler}>
        <span>
          <LinkItems linkItems={linkItems} />
          <ActionItems actionItems={actionItems} closeOnAction={closeHandler} />
        </span>
      </Menu>
    </>
  )
}

const LinkItems: React.FC<{ linkItems: MenuLinkItem[] | undefined }> = ({ linkItems }) => {
  const t = useAppTranslations()

  if (!linkItems) return null
  return (
    <>
      {linkItems?.map((linkItem) => (
        <MenuItem component={Link} to={linkItem.link} key={linkItem.text}>
          {t(linkItem.text)}
        </MenuItem>
      ))}
    </>
  )
}

const ActionItems: React.FC<{
  actionItems: MenuActionItem[] | undefined
  closeOnAction?: (event: React.SyntheticEvent) => void
}> = ({ actionItems, closeOnAction }) => {
  if (!actionItems) return null
  return (
    <>
      {actionItems?.map((actionItem, index) => (
        <ActionItem actionItem={actionItem} closeOnAction={closeOnAction} key={index} />
      ))}
    </>
  )
}

const ActionItem: React.FC<{ actionItem: MenuActionItem; closeOnAction?: (event: React.SyntheticEvent) => void }> = ({
  actionItem,
  closeOnAction,
}) => {
  const t = useAppTranslations()

  const actionWithClose = useCallback(
    (event) => {
      actionItem?.action()
      if (actionItem?.autoClose && closeOnAction) closeOnAction(event)
    },
    [actionItem, closeOnAction]
  )

  return (
    <MenuItem onClick={(event) => actionWithClose(event)} key={actionItem?.text}>
      {actionItem?.icon && <ListItemIcon>{actionItem?.icon}</ListItemIcon>}
      {t(actionItem?.text)}
    </MenuItem>
  )
}

export default MenuWithIcon
