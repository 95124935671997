import React, { useMemo, useState } from 'react'
import { useConfirmDialogContext } from '../../../utils/providers/confirmDialogContext'
import { Item } from './Sidebar'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import MenuWithIcon from '../MenuWithIcon'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useCompanyDetailsContext } from '../../../utils/hooks/useCompanyDetailsContext'
import { CompanyType, SidebarItemsDocument, Status, useArchiveWebsiteMutation } from '../../../__generated__/graphql'
import Lock from '@mui/icons-material/Lock'
import BackdropLoader from '../../loader/BackdropLoader'
import { FormDialog } from '../FormDialog'
import { Add } from '@mui/icons-material'
import { CreatePositionWizzardFromSidebar } from '../../../pages/position/createPosition/CreatePositionWizzard'

const SidebarWebsiteActions: React.FC<{
  item: Item
  updateSidebarItems: () => void
}> = ({ item, updateSidebarItems }) => {
  const t = useAppTranslations()
  const {
    company: { type: companyType, id: companyId },
  } = useCompanyDetailsContext()
  const setConfirmDialogState = useConfirmDialogContext()
  const [createPositionDialogOpened, setCreatePositionDialogOpened] = useState(false)

  const [archiveWebsite, { loading: archiveWebsiteLoading }] = useArchiveWebsiteMutation({
    refetchQueries: () => [
      {
        query: SidebarItemsDocument,
        variables: {
          companyId,
          archived: false,
          isAdvertiser: companyType === CompanyType.Advertiser,
          isTradingDesk: companyType === CompanyType.TradingDesk,
          isPublisher: companyType === CompanyType.Publisher,
        },
      },
    ],
    awaitRefetchQueries: true,
  })

  const actionsItems = useMemo(() => {
    if (item.status === Status.Archived) return []

    return [
      {
        text: t('layout.sidebar.publisher.createPosition'),
        icon: <Add fontSize="small" />,
        action: () => setCreatePositionDialogOpened(true),
        autoClose: true,
      },
      {
        text: t('commons.actions.archive'),
        icon: <Lock fontSize="small" />,
        action: () => {
          setConfirmDialogState({
            message: [t('commons.actions.confirmArchive', { name: item.name })],
            buttonLabel: t('commons.actions.archive'),
            onSubmit: () => archiveWebsite({ variables: { websiteId: item.id } }),
          })
        },
        autoClose: true,
      },
    ]
  }, [item, setConfirmDialogState, archiveWebsite, companyType])

  return (
    <>
      <BackdropLoader open={archiveWebsiteLoading} />
      <MenuWithIcon
        actionItems={actionsItems}
        dataId="sidebarMenuItemIcon"
        size="small"
        iconButtonSx={{ py: 0, color: 'white' }}
      >
        <MoreVertIcon fontSize="small" />
      </MenuWithIcon>
      {/* stop propagation to avoid triggering parent ListItemButton link redirection */}
      <div onClick={(e) => e.stopPropagation()}>
        <FormDialog
          title={t('position.create.title')}
          open={createPositionDialogOpened}
          setOpen={setCreatePositionDialogOpened}
          form={
            <CreatePositionWizzardFromSidebar
              setOpen={setCreatePositionDialogOpened}
              updateSidebarItems={updateSidebarItems}
            />
          }
        />
      </div>
    </>
  )
}

export default SidebarWebsiteActions
