import { useEffect, useMemo, useState, useRef } from 'react'
import { throttle, last } from 'lodash-es'
import { useField } from 'formik'
import { TargetingCircle } from '../../../__generated__/graphql'
import { LocationTypesEnum } from '../../../pages/adgroup/settings/geoTargeting/GeoTargetingFields'
import { Request, PlaceType, PlaceDetails, useAutoCompleteInterface } from './intefaces'
import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_API_KEY } from '../../../env'

const autocompleteService = { current: null }
const placesService = { current: null }

export const useAutoComplete = (
  locationType: LocationTypesEnum,
  name: string,
  countryCode: string
): useAutoCompleteInterface => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<PlaceType[]>([])
  const loaded = useRef(false)
  const [field, , helpers] = useField({ name })
  const { setValue } = helpers
  const { value } = field

  const isLocationTypeCountries = useMemo((): boolean => locationType === LocationTypesEnum.Countries, [locationType])

  useEffect(() => {
    if (typeof window !== 'undefined' && !loaded.current) {
      const loader = new Loader({
        apiKey: GOOGLE_API_KEY,
        libraries: ['places'],
        id: '__googleMapsScriptId',
      })
      loader.load().then(() => (loaded.current = true))
    }
  }, [])

  const fetchAutoCompleteOptions = useMemo(
    () =>
      throttle((request: Request, callback: (results?: PlaceType[]) => void) => {
        ;(autocompleteService.current as any).getPlacePredictions(request, callback)
      }, 200),
    []
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService?.current && (window as any)?.google?.maps?.places?.AutocompleteService)
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService()

    if (!autocompleteService.current) return undefined

    if (!placesService?.current && (window as any)?.google?.maps?.places?.PlacesService)
      placesService.current = new (window as any).google.maps.places.PlacesService(
        document.getElementById('autoCompleteGoogleMap')
      )

    if (!placesService.current) return undefined

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    const filterMap: Record<LocationTypesEnum, string[]> = {
      [LocationTypesEnum.Cities]: ['locality', 'postal_code'],
      [LocationTypesEnum.Departements]: ['administrative_area_level_2'],
      [LocationTypesEnum.Regions]: ['administrative_area_level_1'],
      [LocationTypesEnum.Countries]: ['country'],
    }

    const typesFilter = filterMap[locationType] ?? []

    fetchAutoCompleteOptions(
      {
        input: inputValue,
        componentRestrictions: { country: isLocationTypeCountries ? '' : countryCode },
        types: typesFilter,
      },
      (results?: PlaceType[]) => {
        if (active && results) {
          const filteredOptions = results.filter((result) =>
            result.types.some((type: string) => typesFilter.includes(type))
          )
          setOptions(filteredOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetchAutoCompleteOptions])

  const fetchLocationDetails = useMemo(
    () =>
      (value: PlaceType, callback: (placeDetails: PlaceDetails) => void): void => {
        ;(placesService.current as any).getDetails(
          {
            placeId: value.place_id,
            fields: ['geometry', 'name', 'address_components'],
          },
          callback
        )
      },
    []
  )

  const addNewLocationToSelection = (placeDetails: PlaceDetails): void => {
    let formattedLocation = {}
    switch (locationType) {
      case LocationTypesEnum.Cities:
        formattedLocation = {
          name: placeDetails.name,
          location: {
            latitude: placeDetails.geometry.location.lat(),
            longitude: placeDetails.geometry.location.lng(),
          },
          radius: '5',
        }
        break
      case LocationTypesEnum.Regions:
      case LocationTypesEnum.Departements:
        const coordinates = placeDetails.geometry.viewport.toJSON()
        formattedLocation = {
          name: placeDetails.name,
          geometry: {
            southwest: {
              latitude: coordinates.south,
              longitude: coordinates.west,
            },
            northeast: {
              latitude: coordinates.north,
              longitude: coordinates.east,
            },
          },
        }
        break
      case LocationTypesEnum.Countries:
        formattedLocation = {
          code: placeDetails.address_components[0].short_name.toLowerCase(),
          label: placeDetails.address_components[0].long_name,
        }
        break
    }
    setValue([...value, formattedLocation])
  }

  const onChange = (newValue: Array<PlaceType | TargetingCircle>): void => {
    if (newValue.length < value.length) setValue(newValue)
    else fetchLocationDetails(last(newValue) as PlaceType, addNewLocationToSelection)
  }

  return { setInputValue, options, onChange }
}
