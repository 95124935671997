import { Box, Grid, FormHelperText, Tooltip } from '@mui/material'
import { useField } from 'formik'
import React, { useCallback } from 'react'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { useHoverEditionHandlers, withHoverEditionMode } from '../../utils/withHoverEditionMode'
import HelperTooltip from './HelperTooltip'
import { ChipFormInput, StyledEditIcon, StyledFormLabel } from './styled'
import { EditionModes, useEditionMode } from '../../utils/providers/editionModeContext'
import { useUserPermissions } from '../../pages/company/currentCompanyDisplayedContext'

type Option = Record<'label' | 'value', string>
interface ButtonsFieldProps {
  name: string
  label: string
  options: Option[]
  defaultValues: Option['value'][]
  tooltipText?: string
  disableHoverEdition?: boolean
  editable?: boolean
}

const ButtonsField: React.FC<ButtonsFieldProps> = ({
  name,
  label,
  options,
  defaultValues,
  tooltipText,
  disableHoverEdition = false,
  editable = true,
}): JSX.Element => {
  const t = useAppTranslations()
  const [field, meta, helpers] = useField({ name })
  const [editionModeContext] = useEditionMode()
  const { setValue } = helpers
  const { setDisplayOnLeave, setEditionOnEnter } = useHoverEditionHandlers(name, disableHoverEdition)
  const { edit } = useUserPermissions()

  const isActive = useCallback(
    (option: Option): boolean => {
      const source = editionModeContext === EditionModes.Display ? defaultValues : field.value
      return (source || []).includes(option.value)
    },
    [field, editionModeContext]
  )

  const switchButtonStatus = useCallback(
    (option: Option): void => {
      if (editionModeContext === EditionModes.Display) return
      else if (isActive(option)) setValue([...field.value.filter((v: string) => v !== option.value)])
      else setValue([...field.value, option.value])
    },
    [disableHoverEdition, isActive, field, setValue]
  )

  const hasError = Boolean(meta.error && editionModeContext === EditionModes.Edition)
  const helperText = meta.error && hasError && t(meta.error)

  return (
    <Box mb={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <StyledFormLabel>
            {t(label)} {tooltipText && <HelperTooltip helperText={tooltipText} sx={{ fontSize: '11px' }} />}
            {!disableHoverEdition && edit && editable && (
              <Tooltip title={t('commons.actions.hoverEditHelper') as string}>
                <StyledEditIcon />
              </Tooltip>
            )}
          </StyledFormLabel>
        </Grid>
        {options.map((option, index) => (
          <Grid item key={index}>
            <ChipFormInput
              label={option.label}
              onClick={() => edit && editable && switchButtonStatus(option)}
              color={isActive(option) ? 'secondary' : 'default'}
              clickable={editionModeContext === EditionModes.Edition && edit && editable}
              data-testid={option.label}
              onMouseLeave={setDisplayOnLeave}
              onMouseEnter={setEditionOnEnter}
            />
          </Grid>
        ))}
      </Grid>
      <FormHelperText error={hasError}>{helperText}</FormHelperText>
    </Box>
  )
}

export default withHoverEditionMode(ButtonsField)
