import {
  FormattedCampaignCostDetails,
  AdGroupsCostDetailsTableItem,
  AdCopyCostDetailsTableItem,
  PositionInfos,
} from '../interfaces/stats'
import { AdGroupCostDetailsQuery, AdvertiserCostDetails, PublisherCostDetails } from '../__generated__/graphql'

export const hasEngagement = (res: AdvertiserCostDetails | PublisherCostDetails): boolean => {
  const commonCondition = res.impressions > 0 || res.clicks > 0
  if ('leads' in res && 'orders' in res) return commonCondition || res.leads > 0 || res.orders > 0
  return commonCondition
}

export const mapData = <T extends { total: object; name: string; id?: string }>(
  dataArray: T[] | undefined
): Array<T['total'] & { name: string }> =>
  dataArray?.map(({ name, id, total }) => ({
    name: name,
    ...(id ? { id } : {}),
    ...total,
  })) ?? []

export const mapTotalData = <T,>(costDetailsTotal: T): T & { name: string } => ({
  name: 'Total',
  ...costDetailsTotal,
})

export const mapTimeSeriesData = <T extends { total: object; date: string }>(
  timeSeriesCost: T[] | undefined
): Array<T['total'] & { name: string }> =>
  timeSeriesCost?.map(({ date, total }) => ({
    name: date,
    ...total,
  })) ?? []

export const mapPositionData = <T,>(positionCost: (PositionInfos & { total: T })[]): Array<T & PositionInfos> =>
  positionCost?.map(({ name, status, total, channelType, id }) => ({
    name,
    status,
    channelType,
    ...(id ? { id } : {}),
    ...total,
  })) || []

export const mapPositionsAndWebsitesData = <T,>(
  websitesCost:
    | {
        name: string
        id?: string
        additionalInfo?: string
        total: T
        positions?: (PositionInfos & { total: T })[]
      }[]
    | undefined
): Array<T & { name: string; subRows?: Array<T & PositionInfos> }> =>
  websitesCost?.map(({ name, id, total, positions, additionalInfo }) => ({
    name: name,
    ...(additionalInfo ? { additionalInfo } : {}),
    ...(id ? { id: id } : {}),
    ...total,
    subRows: mapPositionData(positions ?? []),
  })) || []

export const mapAdGroupsData = (
  adGroupsCost: FormattedCampaignCostDetails['adGroups'] | undefined
): Array<AdGroupsCostDetailsTableItem> =>
  adGroupsCost?.map(({ name, isActive, dailyBudget, total, id }) => ({
    name,
    isActive,
    dailyBudget,
    id,
    ...total,
  })) || []

export const mapAdCopiesData = (
  adCopiesCost: AdGroupCostDetailsQuery['adGroup']['adCopies'] | undefined
): Array<AdCopyCostDetailsTableItem> =>
  adCopiesCost?.map((adCopy) => ({
    id: adCopy.id,
    name: adCopy.name,
    image: adCopy.image,
    status: adCopy.status,
    title: adCopy.title,
    description: adCopy.description,
    callToAction: adCopy.callToAction,
    advertiserName: adCopy.advertiserName,
    ...adCopy.costDetails.total,
  })) || []
