import { Delete, Mode } from '@mui/icons-material'
import { Button, Chip, FormLabel, styled } from '@mui/material'

export const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textTransform: 'uppercase',
  fontSize: theme.typography.caption.fontSize,
  pb: 1,
  mt: 3,
  display: 'inline-block',
}))

export const StyledDescription = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
  display: 'inline-block',
  width: '100%',
}))

export const H2SectionTitle = styled('h2')(({ theme }) => ({
  fontSize: '1.6rem',
  flexGrow: 1,
  margin: 0,
  lineHeight: '100%',
  marginTop: theme.spacing(2),
}))

export const SectionHeader = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderBottom: `0.1rem solid ${theme.palette.header}`,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-end',

  '& button': {
    marginLeft: theme.spacing(2),
  },
}))

export const RedDeleteIcon = styled(Delete)(({ theme }) => ({
  color: `${theme.palette.error.main} !important`,
}))
export const DisabledDeleteIcon = styled(RedDeleteIcon)(({ theme }) => ({
  color: `${theme.palette.grey[400]} !important`,
}))

export const ChipFormInput = styled(Chip)(({ theme }) => ({
  padding: theme.spacing(1),
  margin: theme.spacing(0, 1),
  textTransform: 'uppercase',
  fontSize: theme.typography.caption.fontSize,
}))

export const StyledEditIcon = styled(Mode)(({ theme }) => ({
  fontSize: '1.2rem !important',
  color: `${theme.palette.text.secondary} !important`,
  marginLeft: '0.5rem !important',
}))

export const ButtonInputStyle = styled(Button)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[400]}`,
  '&:hover': {
    border: '1px solid black',
  },
}))
