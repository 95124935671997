import React from 'react'
import CampaignProgrammaticSummary from '../summary/ProgrammaticCampaignSummary'
import FadeWrapper from '../../../components/transitions/FadeWrapper'
import { PaperWithPadding } from '../../../components/layout/PaperWithPadding'
import Stats from './stats/Stats'
import { Box } from '@mui/material'
import InactiveCompanyWarning from '../../../components/warning/InactiveCompanyWarning'

const CampaignProgrammatic: React.FC = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <FadeWrapper>
        <InactiveCompanyWarning />
        <PaperWithPadding>
          <CampaignProgrammaticSummary />
          <Stats />
        </PaperWithPadding>
      </FadeWrapper>
    </Box>
  )
}

export default CampaignProgrammatic
