import { mapValues } from 'lodash-es'

import { omit } from './omit'

const isObject = (obj: unknown): boolean => typeof obj === 'object' && obj !== null

export const omitDeep = (toClean: Record<string, unknown>, toOmit: string[]): Record<string, unknown> => {
  if (isObject(toClean) && !(toClean instanceof Blob || toClean instanceof File)) {
    const cleaned = omit(toClean, toOmit)

    return mapValues(cleaned, (value: unknown | unknown[]) => {
      if (isObject(value))
        if (Array.isArray(value)) return value.map((element) => omitDeep(element, toOmit))
        else return omitDeep(value as Record<string, unknown>, toOmit)
      else return value
    })
  } else return toClean
}
