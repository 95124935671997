import { Chip, Grid, Skeleton, Typography } from '@mui/material'
import React from 'react'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import { AdvertiserCostDetails, PublisherCostDetails } from '../../../__generated__/graphql'
import { StyledFormLabel } from '../../form/styled'
import { StatsConf, StatsConfKeys } from '../../../utils/statsConf'

const StatsWidget: React.FC<{
  name: StatsConfKeys
  value: number
  onClick: () => void
  isDisplayed: boolean
  isPublisherStats: boolean
  loading: boolean
}> = ({ name, value, onClick, isDisplayed, isPublisherStats, loading }): JSX.Element => {
  const t = useAppTranslations()
  const valueToDisplay = StatsConf[name]?.style === 'percent' ? value / 100 : value

  const displayedName = t(
    `stats.customDisplay.${isPublisherStats ? 'publisher' : 'advertiser'}.${StatsConf[name]?.name}`
  )

  return (
    <Grid item xs>
      <Chip
        sx={{
          borderBottom: 5,
          borderRight: 1,
          borderRightColor: 'divider',
          borderRadius: 0,
          py: 5,
          borderBottomColor: StatsConf[name]?.color || 'FFF',
          backgroundColor: 'grey.100',
          textAlign: 'center',
          width: '100%',
          opacity: isDisplayed ? undefined : 0.3,
        }}
        onClick={onClick}
        clickable
        label={
          <>
            <StyledFormLabel sx={{ fontSize: 15, mb: 1 }}>{displayedName}</StyledFormLabel>
            {loading ? (
              <Skeleton variant="text" width={100} height={30} />
            ) : (
              <Typography variant="h1">
                {Intl.NumberFormat('fr-FR', {
                  style: StatsConf[name]?.style,
                  maximumFractionDigits: 2,
                  currency: 'EUR',
                }).format(valueToDisplay)}
              </Typography>
            )}
          </>
        }
      />
    </Grid>
  )
}

const timeseriesWidgets: React.FC<{
  costDetails: { total: AdvertiserCostDetails | PublisherCostDetails } | undefined
  chartItems: StatsConfKeys[]
  chartItemsVisibility: Record<StatsConfKeys, boolean>
  handleToggleVisibility: (name: StatsConfKeys) => void
  isPublisherStats: boolean
  loading?: boolean
}> = ({
  costDetails,
  chartItems,
  chartItemsVisibility,
  handleToggleVisibility,
  isPublisherStats,
  loading = false,
}): JSX.Element => {
  return (
    <Grid container my={1} mb={3}>
      {chartItems.map((name) => (
        <StatsWidget
          key={name}
          name={name}
          value={costDetails?.total[name as keyof (AdvertiserCostDetails | PublisherCostDetails)] || 0}
          onClick={() => handleToggleVisibility(name)}
          isDisplayed={chartItemsVisibility[name]}
          isPublisherStats={isPublisherStats}
          loading={loading}
        />
      ))}
    </Grid>
  )
}

export default timeseriesWidgets
