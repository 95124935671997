import React from 'react'
import { Backdrop as MuiBackdrop } from '@mui/material'
import PwsSpinner from './PwsSpinner'

const BackdropLoader: React.FC<{ open: boolean }> = ({ open }) => (
  <MuiBackdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
    <PwsSpinner />
  </MuiBackdrop>
)

export default BackdropLoader
