import { AppBarClasses } from '@mui/material'
import { StyleRules } from '@mui/styles'

export const appBarTheme: Partial<StyleRules<AppBarClasses>> = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
      '& hr': {
        borderColor: 'rgba(0, 0, 0, 0.12)',
      },
    },
  },
}
