import React from 'react'
import { FormikStepProps } from '../../../components/form/wizzard/FormikStepper'
import { Grid } from '@mui/material'
import InputField from '../../../components/form/InputField'
import { useAppTranslations } from '../../../utils/hooks/useAppTranslations'
import MarketSelectField from '../../../components/form/MarketSelectField'
import IabAutoCompleteField from '../../../components/form/IABAutoCompleteField'

const WebsiteDetailStep: React.FC<FormikStepProps> = () => {
  const t = useAppTranslations()

  return (
    <Grid container direction="row" spacing={3} justifyContent="flex-start" alignItems="flex-start">
      <Grid container direction="column" item xs={6}>
        <Grid item>
          <InputField
            name={'name'}
            label={'website.details.name'}
            type={'text'}
            fullWidth
            required
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <InputField name={'url'} label={'website.details.url'} type={'text'} fullWidth required variant="outlined" />
        </Grid>
      </Grid>
      <Grid container direction="column" item xs={6}>
        <Grid item>
          <IabAutoCompleteField name={'iabCategory'} label={t('website.details.iabCategory')} />
        </Grid>
        <Grid item>
          <MarketSelectField name={'marketId'} label={t('website.details.market')} required variant="outlined" />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default WebsiteDetailStep
