import React from 'react'
import SelectField, { SelectFieldProps } from './SelectField'
import Loader from '../loader/Loader'
import { useMarketsQuery } from '../../__generated__/graphql'
import { MenuItem } from '@mui/material'
import getMarketCode from '../../utils/getMarketCode'

const MarketSelectField: React.FC<SelectFieldProps> = ({ name, label, required, variant }) => {
  const { data: marketsFromDB, loading: loadingMarkets } = useMarketsQuery()

  if (loadingMarkets) return <Loader />
  return (
    <SelectField name={name} label={label} withNone={false} required={required} variant={variant}>
      {marketsFromDB?.searchMarkets?.map((market, i) => (
        <MenuItem value={parseInt(market?.id ?? 0)} key={i}>
          {getMarketCode(market)}
        </MenuItem>
      ))}
    </SelectField>
  )
}

export default MarketSelectField
