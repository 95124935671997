import React, { useContext, useEffect, useState } from 'react'
import {
  DemandSource,
  PositionQuery,
  PositionInput,
  useUpdateDemandSourcesMutation,
  usePositionQuery,
  useUpdatePositionMutation,
  PositionZoneDocument,
} from '../../../__generated__/graphql'
import { NetworkStatus } from '@apollo/client'
import { useSnackbarManager } from '../../providers/snackbarContext'
import { useAppTranslations } from '../useAppTranslations'
import { filterMutationInput } from '../../generateMutationInput'
import BackdropLoader from '../../../components/loader/BackdropLoader'
import PwsSpinner from '../../../components/loader/PwsSpinner'

type PositionContextInterface = [
  PositionQuery,
  (values: Partial<PositionInput>) => Promise<void>,
  (values: DemandSource[]) => Promise<void>
]
const PositionContext = React.createContext<PositionContextInterface | null>(null)
PositionContext.displayName = 'PositionContext'

export const PositionContextProvider: React.FC<{ positionId: string }> = ({ positionId, children }) => {
  const [, setSnackbar] = useSnackbarManager()
  const t = useAppTranslations()
  const {
    data: positionData,
    loading: positionLoading,
    refetch: refetchPosition,
    networkStatus,
  } = usePositionQuery({
    variables: { id: positionId },
    notifyOnNetworkStatusChange: true,
  })

  const [updatePosition, { loading: updatePositionLoading }] = useUpdatePositionMutation({
    refetchQueries: () => [
      {
        query: PositionZoneDocument,
        variables: { id: positionData?.position.id },
      },
    ],
    awaitRefetchQueries: true,
  })
  const [editDemandSource, { loading: updateDemandSourceLoading }] = useUpdateDemandSourcesMutation()

  const [loadingForDemandSources, setLoadingFormDemandSources] = useState(false)

  const onUpdate = async (values: Partial<PositionInput>): Promise<void> => {
    if (!positionData?.position) return

    const toDelete = ['demandSources', 'availableDemandSources', 'templates', 'website']
    const positionInput = filterMutationInput<PositionQuery['position'], PositionInput>(
      positionData.position,
      values,
      toDelete
    )

    await updatePosition({ variables: { position: positionInput } })
      .then(() => setSnackbar({ severity: 'success', message: t('commons.saved') }))
      .catch((err) => setSnackbar({ severity: 'error', message: err.message }))
  }

  const onDemandSourceUpdate = async (values: DemandSource[]): Promise<void> => {
    if (!positionData?.position) return

    await editDemandSource({
      variables: {
        positionId: positionData.position.id,
        demandSources: values,
      },
    })
      .then(() => setSnackbar({ severity: 'success', message: t('commons.saved') }))
      .catch((err) => setSnackbar({ severity: 'error', message: err.message }))
      .then(() => refetchPosition())
  }

  useEffect(() => {
    if (updateDemandSourceLoading || networkStatus === NetworkStatus.refetch) setLoadingFormDemandSources(true)
    else setLoadingFormDemandSources(false)
  }, [updateDemandSourceLoading, networkStatus])

  if (positionLoading) return <PwsSpinner />
  if (!positionData) return null
  return (
    <PositionContext.Provider value={[positionData, onUpdate, onDemandSourceUpdate]}>
      {positionLoading || loadingForDemandSources ? (
        <PwsSpinner />
      ) : (
        <>
          <BackdropLoader open={updatePositionLoading} />
          {children}
        </>
      )}
    </PositionContext.Provider>
  )
}

export const usePositionContext = (): PositionContextInterface => {
  const value = useContext(PositionContext)
  if (!value) throw new Error('Value not found in position context')
  return value
}
