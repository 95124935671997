import EN from './translations/en_US/translations.json'
import FR from './translations/fr_FR/translations.json'
import i18n from 'i18next'

i18n.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: 'en', // language to use
  resources: {
    en: {
      translations: EN, // 'translations' is our custom namespace
    },
    fr: {
      translations: FR,
    },
  },
})
