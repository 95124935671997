import React from 'react'
import { UserPermission } from '../../__generated__/graphql'
import { useUserPermissions } from '../../pages/company/currentCompanyDisplayedContext'

const check = (
  permissions: Partial<UserPermission>,
  roleRequired: 'read' | 'edit' | 'admin' | 'superAdmin',
  permissionRequired?: keyof UserPermission
): boolean => {
  if (roleRequired && permissions[roleRequired] && !permissionRequired) return true
  else if (roleRequired && permissions[roleRequired] && permissionRequired && permissions[permissionRequired])
    return true
  else return false
}

interface HasPermissionProps {
  roleRequired?: 'read' | 'edit' | 'admin' | 'superAdmin'
  permissionRequired?: keyof UserPermission
}

const HasPermission: React.FC<HasPermissionProps> = ({ children, permissionRequired, roleRequired = 'read' }) => {
  const permissions = useUserPermissions()
  return check(permissions, roleRequired, permissionRequired) ? <>{children}</> : null
}
export default HasPermission
