import { FormikErrors } from 'formik'
import { Tooltip } from '@mui/material'
import React, { useMemo } from 'react'

interface FlattenedError {
  field: string
  error: string
}

const flattenErrors = (errors: FormikErrors<unknown>): Array<{ field: string; error: string }> => {
  return Object.entries(errors).reduce((acc, [field, error]) => {
    if (typeof error === 'string') return [...acc, { field: field, error }]
    if (typeof error === 'object') return [...acc, ...flattenErrors(error as FormikErrors<unknown>)]
    return acc
  }, [] as FlattenedError[])
}

const FormikErrorsTooltip: React.FC<{ formikErrors: FormikErrors<unknown>; children: React.ReactNode }> = ({
  formikErrors,
  children,
}) => {
  const flattenedErrors = useMemo(() => flattenErrors(formikErrors), [formikErrors])

  return (
    <Tooltip
      title={
        flattenedErrors.length > 0
          ? flattenedErrors.map(({ field, error }) => (
              <li key={field}>
                {field}: {error}
              </li>
            ))
          : ''
      }
    >
      <span>{children}</span>
    </Tooltip>
  )
}

export default FormikErrorsTooltip
