import { Alert, Box, Table } from '@mui/material'
import dayjs from 'dayjs'
import { Formik } from 'formik'
import { uniqBy } from 'lodash-es'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import SectionTitle from '../../components/form/SectionTitle'
import DatatablePagination from '../../components/layout/DatatablePagination'
import { PaperWithPadding } from '../../components/layout/PaperWithPadding'
import FadeWrapper from '../../components/transitions/FadeWrapper'
import FormikOnChangeEffect from '../../utils/FormikOnChangeEffect'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { useCompanyDetailsContext } from '../../utils/hooks/useCompanyDetailsContext'
import {
  buildPagination,
  DEFAULT_PAGINATION,
  PaginationContextProvider,
  usePagination,
} from '../../utils/providers/paginationContext'
import {
  ActivityFeed as ActivityFeedType,
  ActivityFeedQueryVariables,
  EntityType,
  PartialCompany,
  PartialUser,
  useActivityFeedQuery,
} from '../../__generated__/graphql'
import { AllRoute } from '../routeParams'
import ActivityFeedRow from './ActivityFeedRow'
import Filter from './Filter'
import TableSkeleton from '../../components/loader/TableSkelton'
import PwsSpinner from '../../components/loader/PwsSpinner'

export type ActivityFeedFilters = {
  userId: string | number
  entityType: string | EntityType
  companyId: string | number
}

const Activity: React.FC = () => {
  const [pagination, setPagination] = useState({ ...DEFAULT_PAGINATION })
  return (
    <Box
      sx={{
        height: '100%',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <FadeWrapper>
        <PaginationContextProvider state={[pagination, setPagination]}>
          <ActivityFeed />
        </PaginationContextProvider>
      </FadeWrapper>
    </Box>
  )
}

export default Activity

const ActivityFeed: React.FC = () => {
  const t = useAppTranslations()
  const [paginationContext, setPagination] = usePagination()

  const { slug } = useParams<AllRoute>()
  const companyId = slug ? useCompanyDetailsContext().company.id : null
  const canFilterCompany = useMemo(() => !slug, [])

  const initialFilterValues = {
    userId: 'all',
    entityType: 'all',
    companyId: companyId || 'all',
  }

  const [filterValues, setFilterValues] = useState<ActivityFeedFilters>(initialFilterValues)
  const [userList, setUserIdList] = useState<Array<PartialUser>>([])
  const [companyList, setCompanyIdList] = useState<PartialCompany[]>([])
  const buildSearchQueryFromFilters = useCallback((): ActivityFeedQueryVariables => {
    return {
      userId: filterValues.userId !== 'all' ? filterValues.userId : null,
      entityType: filterValues.entityType !== 'all' ? (filterValues.entityType as EntityType) : null,
      companyId: canFilterCompany ? (filterValues.companyId !== 'all' ? filterValues.companyId : null) : companyId,
    }
  }, [filterValues])

  const {
    data: activityFeedData,
    loading,
    error,
  } = useActivityFeedQuery({
    variables: {
      pagination: buildPagination(paginationContext),
      ...buildSearchQueryFromFilters(),
    },
  })

  useEffect(() => {
    userList.length < 1 && getUserList()
    companyList.length < 1 && getCompanyList()
  }, [activityFeedData])

  const getUserList = (): void => {
    setUserIdList(
      uniqBy(activityFeedData?.searchActivityFeed, 'user.id')
        .map((feed) => feed.user)
        .filter((user) => user !== null && user !== undefined) as Array<PartialUser>
    )
  }

  const getCompanyList = (): void => {
    setCompanyIdList(uniqBy(activityFeedData?.searchActivityFeed, 'company.id').map((feed) => feed.company))
  }

  const activityFeedSections = useMemo(() => {
    return [
      {
        title: t('activity.todayTitle', { date: dayjs().format('DD MMMM YYYY') }),
        activityFeed:
          activityFeedData?.searchActivityFeed.filter((feed) => dayjs().isSame(feed.createdAt, 'day')) || [],
      },
      {
        title: t('activity.yesterdayTitle', { date: dayjs().subtract(1, 'day').format('DD MMMM YYYY') }),
        activityFeed:
          activityFeedData?.searchActivityFeed.filter((feed) =>
            dayjs().subtract(1, 'day').isSame(feed.createdAt, 'day')
          ) || [],
      },
      {
        title: t('activity.earlierTitle'),
        activityFeed:
          activityFeedData?.searchActivityFeed.filter((feed) =>
            dayjs().subtract(1, 'day').isAfter(feed.createdAt, 'day')
          ) || [],
      },
    ]
  }, [activityFeedData])

  const onFilterChange = useCallback((changes: { values: ActivityFeedFilters }) => {
    if (changes?.values) {
      setFilterValues({
        userId: changes.values.userId,
        entityType: changes.values.entityType,
        companyId: changes.values.companyId,
      })
      setPagination((oldPagination) => ({ ...oldPagination, page: 0 }))
    }
  }, [])

  if (error)
    return (
      <PaperWithPadding>
        <Alert severity="error">{error.message}</Alert>
      </PaperWithPadding>
    )

  return (
    <PaperWithPadding>
      <Formik initialValues={initialFilterValues} onSubmit={() => undefined}>
        {(formik) => (
          <>
            <FormikOnChangeEffect formik={formik} onChange={onFilterChange} />
            <Filter
              onReset={() => formik.resetForm()}
              userList={userList}
              companyList={companyList}
              canFilterCompany={canFilterCompany}
            />
          </>
        )}
      </Formik>
      {loading && <TableSkeleton colNumbers={5} lineNumbers={20} />}
      {activityFeedSections
        .filter((section) => section.activityFeed.length > 0)
        .map((section) => (
          <ActivityFeedSection
            key={section.title}
            title={section.title}
            activityFeed={section.activityFeed}
            loading={loading}
          />
        ))}
      <Table>
        <DatatablePagination rowsDisplayed={activityFeedData?.searchActivityFeed.length || 0} />
      </Table>
    </PaperWithPadding>
  )
}

const ActivityFeedSection: React.FC<{ title: string; activityFeed: Array<ActivityFeedType>; loading: boolean }> = ({
  title,
  activityFeed,
  loading,
}) => {
  return (
    <FadeWrapper>
      <SectionTitle title={title} />
      <Box mb={3}>
        {loading ? <PwsSpinner /> : activityFeed.map((feed, index) => <ActivityFeedRow feed={feed} key={index} />)}
      </Box>
    </FadeWrapper>
  )
}
