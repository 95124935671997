import React from 'react'
import { usePositionContext } from '../../../../utils/hooks/position/usePositionContext'
import { useAppTranslations } from '../../../../utils/hooks/useAppTranslations'
import { Grid } from '@mui/material'
import TransformDisplayToEdition from '../../../../components/form/TransformDisplayToEdition'
import InputAdornment from '@mui/material/InputAdornment'
import { displayAsPercent } from '../../../../utils/displayAsPercent'
import { displayAsCurrency } from '../../../../utils/displayAsCurrency'
import HasPermission from '../../../../components/permissions/HasPermission'
import { useCanEditPosition } from '../../../../utils/hooks/position/useCanEditPosition'
import { ChannelType } from '../../../../__generated__/graphql'

export const fromPercentToFloat = (percent: number): number => {
  return Math.round(percent) / 100
}

const SettingsSummary = (): JSX.Element => {
  const t = useAppTranslations()
  const [position] = usePositionContext()
  const editionInputSize = 3
  const canEditPosition = useCanEditPosition()

  return (
    <Grid container spacing={1}>
      {position.position.channelType === ChannelType.Web && (
        <Grid item xs={editionInputSize}>
          <TransformDisplayToEdition
            type={'number'}
            name={'nbZone'}
            label={t('position.settings.summary.nbZone')}
            text={position.position.nbZone.toString()}
            required
          />
        </Grid>
      )}
      <HasPermission roleRequired="edit" permissionRequired="positionAdvancedsettings">
        <Grid item xs={editionInputSize}>
          <TransformDisplayToEdition
            type={'number'}
            name={'revenueShare'}
            label={t('position.settings.summary.revenueShare')}
            text={displayAsPercent(position.position.revenueShare)}
            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            editable={canEditPosition}
          />
        </Grid>
        <Grid item xs={editionInputSize}>
          <TransformDisplayToEdition
            type={'number'}
            name={'cpmFloor'}
            label={t('position.settings.summary.cpmFloor')}
            text={displayAsCurrency(position.position.cpmFloor)}
            InputProps={{ endAdornment: <InputAdornment position="end">€</InputAdornment> }}
            editable={canEditPosition}
          />
        </Grid>
      </HasPermission>
    </Grid>
  )
}

export default SettingsSummary
