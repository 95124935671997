import React from 'react'
import { useField } from 'formik'
import { FormHelperText, Switch, SxProps, Theme } from '@mui/material'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import {
  PossiblyBooleanContentStyle,
  StyledItemWrapper,
  StyledLabel,
  StyledValue,
  StyledValueWrapper,
} from './DisplayedItem'

export interface SwitchInputProps {
  name: string
  label: string
  testId?: string
  sx?: SxProps<Theme>
  onMouseLeave?: () => void
  textToggle?: { true: string; false: string }
  displaysText?: boolean
}

const SwitchInput: React.FC<SwitchInputProps> = ({
  name,
  label,
  testId,
  onMouseLeave,
  textToggle = { true: 'yes', false: 'no' },
  displaysText = true,
  ...props
}) => {
  const t = useAppTranslations()
  const [, meta, helpers] = useField({ name })
  const stringLabel: string = t(label)

  const hasError = Boolean(meta.touched && meta.error)
  const helperText = meta.error && hasError ? t(meta.error) : ' '

  const { value } = meta
  const { setValue } = helpers
  return (
    <StyledItemWrapper onMouseLeave={onMouseLeave}>
      <StyledLabel error={hasError}>{stringLabel}</StyledLabel>

      <StyledValueWrapper>
        <StyledValue sx={{ overflow: 'visible' }}>
          {displaysText && <PossiblyBooleanContentStyle content={value ? textToggle.true : textToggle.false} />}
          <Switch
            data-testid={testId}
            checked={value}
            color={'secondary'}
            onChange={() => setValue(!value)}
            {...props}
          />
        </StyledValue>
      </StyledValueWrapper>

      <FormHelperText>{helperText}</FormHelperText>
    </StyledItemWrapper>
  )
}
export default SwitchInput
