import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Row } from '@tanstack/react-table'
import React from 'react'
import { TransparentButton } from '../layout/collapsible/styles'

interface ExpandCellWrapperInterface<T> {
  row: Row<T>
  children: React.ReactChild
}

const ExpandCellWrapper = <T,>({ children, row }: ExpandCellWrapperInterface<T>): JSX.Element => {
  return (
    <div style={{ paddingLeft: `${row.depth * 2}rem` }}>
      <div>
        {row.getCanExpand() && (
          <TransparentButton
            sx={{ p: 0 }}
            {...{
              onClick: row.getToggleExpandedHandler(),
            }}
          >
            {row.getIsExpanded() ? <ExpandLess /> : <ExpandMore />}
          </TransparentButton>
        )}
        {children}
      </div>
    </div>
  )
}

export default ExpandCellWrapper
