import React from 'react'
import { Form, FormikFormProps } from 'formik'
import RouteLeavingGuard from '../layout/RouteLeavingGuard'

const FormWithRouteLeavingGuard: React.FC<FormikFormProps> = ({ children, ...props }) => {
  return (
    <Form {...props}>
      <RouteLeavingGuard />
      {children}
    </Form>
  )
}

export default FormWithRouteLeavingGuard
