import {
  PublisherCost,
  ChannelType,
  Status,
  PublisherCostDetails,
  AdvertiserCost,
  AdvertiserCostDetails,
  AdGroupCostDetailsQuery,
  CampaignCostDetailsQuery,
  WebsiteCostDetailsQuery,
  PositionCostDetailsQuery,
  PublisherCostDetailsQuery,
  AdCopyStatus,
  AdvertiserCostDetailsQuery,
  AgencyCostDetailsQuery,
  InventoryCostDetailsQuery,
  ProgrammaticCampaignCostDetailsQuery,
  TradingDeskCostDetailsQuery,
  AdvertiserTimeseriesCost,
  PublisherTimeseriesCost,
  ResellerPublisherCostDetailsQuery,
  ResellerAdvertiserCostDetailsQuery,
} from '../__generated__/graphql'

export enum StatsEntity {
  Websites = 'websites',
  Positions = 'positions',
  AdGroups = 'adGroups',
  Campaigns = 'campaigns',
  Publishers = 'publishers',
  Advertisers = 'advertisers',
  ResellerPublisher = 'resellerPublisher',
  ResellerAdvertiser = 'resellerAdvertiser',
  Agency = 'agency',
  Inventory = 'inventory',
  ProgrammaticCampaign = 'programmaticCampaign',
  TradingDesk = 'tradingDesk',
}

export type AgencyCostDetailsWithTimeseries = AgencyCostDetailsQuery['agency']['costDetails'] & {
  timeseries: AdvertiserTimeseriesCost[]
}

type CompanyAdvertiserCostDetails = AdvertiserCostDetailsQuery['advertiser']['costDetails']
export interface FormattedCompanyAdvertiserCostDetails extends CompanyAdvertiserCostDetails {
  campaigns?: {
    name: string
    total: AdvertiserCostDetails
  }[]
  websites?: {
    additionalInfo: string
    name: string
    total: AdvertiserCostDetails
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}

type InventoryCostDetails = InventoryCostDetailsQuery['inventory']['costDetails']
export interface FormattedInventoryCostDetails extends InventoryCostDetails {
  websites: {
    additionalInfo: string
    name: string
    total: AdvertiserCostDetails
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}

type programmaticCampaignCostDetails = ProgrammaticCampaignCostDetailsQuery['campaignProgrammatic']['costDetails']
export interface FormattedProgrammaticCampaignCostDetails extends programmaticCampaignCostDetails {
  websites: {
    name: string
    additionalInfo: string
    total: AdvertiserCostDetails
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}

type tradingDeskCostDetails = TradingDeskCostDetailsQuery['tradingDesk']['costDetails']
export interface FormattedTradingDeskCostDetails extends tradingDeskCostDetails {
  websites: {
    name: string
    additionalInfo: string
    total: AdvertiserCostDetails
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}

export type AdGroupCostDetails = AdGroupCostDetailsQuery['adGroup']['costDetails']
export interface FormattedAdGroupCostDetails extends AdGroupCostDetails {
  adCopies: {
    id: string
    name: string
    title: string
    description: string
    callToAction: string
    advertiserName: string
    status: AdCopyStatus
    image: string
    costDetails: {
      total: AdvertiserCostDetails
    }
  }[]
  websites: {
    name: string
    additionalInfo: string
    total: AdvertiserCostDetails
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}
export type PositionCostDetails = PositionCostDetailsQuery['position']['costDetails']
export interface FormattedPositionCostDetails extends PositionCostDetails {
  adTemplates?: {
    name: string
    total: PublisherCostDetails
  }[]
  campaigns: {
    name: string
    total: PublisherCostDetails
  }[]
  timeseries: PublisherTimeseriesCost[]
}
export type CampaignCostDetails = CampaignCostDetailsQuery['campaign']['costDetails']
export interface FormattedCampaignCostDetails extends CampaignCostDetails {
  adGroups?: {
    id: string
    name: string
    isActive: boolean
    dailyBudget: string
    total: AdvertiserCostDetails
  }[]
  websites: {
    name: string
    total: AdvertiserCostDetails
    additionalInfo: string
    positions?: {
      name: string
      total: AdvertiserCostDetails
    }[]
  }[]
  timeseries: AdvertiserTimeseriesCost[]
}
export type WebsiteCostDetails = WebsiteCostDetailsQuery['website']['costDetails']
export interface FormattedWebsiteCostDetails extends WebsiteCostDetails {
  positions?: {
    name: string
    id: string
    channelType: ChannelType
    status: Status
    total: PublisherCostDetails
  }[]
  campaigns: {
    name: string
    total: PublisherCostDetails
  }[]
  timeseries: PublisherTimeseriesCost[]
}

export type CompanyPublisherCostDetails = PublisherCostDetailsQuery['publisher']['costDetails']
export interface FormattedCompanyPublisherCostDetails extends CompanyPublisherCostDetails {
  websites?: {
    name: string
    id: string
    total: PublisherCostDetails
    positions?: {
      name: string
      id: string
      channelType: ChannelType
      status: Status
      total: PublisherCostDetails
    }[]
  }[]
  campaigns?: {
    name: string
    total: PublisherCostDetails
  }[]
  timeseries: PublisherTimeseriesCost[]
}

export type ResellerPublisherCostDetails = ResellerPublisherCostDetailsQuery['reseller']['publisherCostDetails']
export type FormattedResellerPublisherCostDetails = ResellerPublisherCostDetails & {
  advertisers: ResellerPublisherCostDetails['resellers'][number]['advertisers']
  timeseries: PublisherTimeseriesCost[]
}

export type ResellerAdvertiserCostDetails = ResellerAdvertiserCostDetailsQuery['reseller']['advertiserCostDetails']
export type FormattedResellerAdvertiserCostDetails = ResellerAdvertiserCostDetails & {
  publishers: ResellerAdvertiserCostDetails['resellers'][number]['publishers']
  timeseries: AdvertiserTimeseriesCost[]
}

export type AdvertiserCostDetailsType =
  | AdvertiserCost
  | FormattedCompanyAdvertiserCostDetails
  | FormattedCampaignCostDetails
  | FormattedAdGroupCostDetails
export type PublisherCostDetailsType =
  | PublisherCost
  | WebsiteCostDetails
  | FormattedCompanyPublisherCostDetails
  | FormattedWebsiteCostDetails
  | PositionCostDetails

export type AgencyCostDetailsType = AgencyCostDetailsQuery['agency']['costDetails']
export interface PositionDetail {
  name: string
  channelType: ChannelType
  status: Status
  total: PublisherCostDetails
}

export const BASE_STAT_DATA_TYPE = {
  DEVICES: 'devices',
  TIMESERIES: 'timeseries',
}

export const COMPANY_BASE_STAT_DATA_TYPE = {
  RESELLERS: 'resellers',
  WEBSITES_AND_POSITIONS: 'websitesAndPositions',
}

export const WEBSITE_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  CAMPAIGNS: 'campaigns',
  POSITIONS: 'positions',
  RESELLERS: COMPANY_BASE_STAT_DATA_TYPE.RESELLERS,
}

export const POSITION_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ZONES: 'zones',
  CAMPAIGNS: 'campaigns',
  ADTEMPLATES: 'adTemplates',
  RESELLERS: COMPANY_BASE_STAT_DATA_TYPE.RESELLERS,
}

export const PUBLISHER_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  CAMPAIGNS: 'campaigns',
  ...COMPANY_BASE_STAT_DATA_TYPE,
}

export const AGENCY_DATA_TYPE = {
  TIMESERIES: BASE_STAT_DATA_TYPE.TIMESERIES,
  RESELLERS: 'resellers',
}

export const RESELLER_PUBLISHER_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ADVERTISERS: 'advertisers',
}

export const RESELLER_ADVERTISER_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  PUBLISHERS: 'publishers',
}

export const ADVERTISER_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  CAMPAIGNS: 'campaigns',
  ...COMPANY_BASE_STAT_DATA_TYPE,
}

export const ADGROUP_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ADCOPIES: 'adcopies',
  WEBSITES_AND_POSITIONS: COMPANY_BASE_STAT_DATA_TYPE.WEBSITES_AND_POSITIONS,
}

export const CAMPAIGN_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  WEBSITES_AND_POSITIONS: COMPANY_BASE_STAT_DATA_TYPE.WEBSITES_AND_POSITIONS,
  ADGROUPS: 'adgroups',
}

export const INVENTORY_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ...COMPANY_BASE_STAT_DATA_TYPE,
}

export const PROGRAMMATIC_CAMPAIGN_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ...COMPANY_BASE_STAT_DATA_TYPE,
}

export const TRADING_DESK_DATA_TYPE = {
  ...BASE_STAT_DATA_TYPE,
  ...COMPANY_BASE_STAT_DATA_TYPE,
}

export type AdGroupsCostDetailsTableItem = AdvertiserCostDetails & {
  name: string
  isActive: boolean
  dailyBudget: string
}

export type AdCopyCostDetailsTableItem = AdvertiserCostDetails &
  Omit<AdGroupCostDetailsQuery['adGroup']['adCopies'][number], 'costDetails'>

export type PositionInfos = {
  name: string
  channelType?: string
  status?: Status
  id?: string
}

export type PositionsCostDetailsTableItem = PublisherCostDetails & PositionInfos
