import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import React, { Dispatch } from 'react'
import { useAppTranslations } from '../../utils/hooks/useAppTranslations'
import { DialogActions } from '@mui/material'

type ActionButton = {
  label: string
  action: () => void
}

type DialogCustomProps = {
  message: string | string[] | JSX.Element
  actionButton: ActionButton
  setOpen: Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

export const ConfirmDialog: React.FC<DialogCustomProps> = ({ message, actionButton, setOpen, open }): JSX.Element => {
  const t = useAppTranslations()
  const displayMessage = Array.isArray(message) ? message.map((msg, i) => <p key={i}>{msg}</p>) : message

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle sx={{ fontSize: 'h1.fontSize' }}>{t('commons.actions.confirmTheAction')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mt: 2, fontSize: 'body1.fontSize' }}>{displayMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          autoFocus
          variant="outlined"
          type="button"
          color="info"
          data-testid="cancelConfirmAction"
        >
          {t('commons.actions.cancel')}
        </Button>
        <Button
          onClick={() => {
            setOpen(false)
            actionButton.action()
          }}
          color="error"
          variant="contained"
          type="button"
          data-testid="dialogAcceptButton"
        >
          {actionButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
