import { AlertProps } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'

export interface SnackbarState {
  message: string | string[]
  severity: AlertProps['severity']
}

type ContextValue = [SnackbarState | undefined, React.Dispatch<React.SetStateAction<SnackbarState | undefined>>]

const SnackbarContext = createContext<ContextValue | null>(null)
SnackbarContext.displayName = 'SnackbarContext'

export const SnackbarContextProvider: React.FC = ({ children }): JSX.Element => {
  const snackbarState = useState<SnackbarState>()
  return <SnackbarContext.Provider value={snackbarState}>{children}</SnackbarContext.Provider>
}

export function useSnackbarManager(): ContextValue {
  const value = useContext(SnackbarContext)
  if (!value) throw new Error('Snackbar context must be initialized before using it')
  return value
}
