import React from 'react'
import { IconButton } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { StyledFormLabel } from '../../form/styled'

const ToggleDisplay: React.FC<{
  handleClick: () => void
  isDisplayed: boolean
}> = ({ handleClick, isDisplayed, children }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        marginTop: '5px',
      }}
      onClick={handleClick}
    >
      <IconButton data-testid="hide" color="primary" size="small">
        {isDisplayed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      <StyledFormLabel sx={{ cursor: 'pointer' }}>{children}</StyledFormLabel>
    </div>
  )
}

export default ToggleDisplay
