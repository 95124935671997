import { useCallback, useMemo } from 'react'
import {
  CompanyType,
  SidebarItemsDocument,
  useCopyAdGroupMutation,
  useCopyPositionMutation,
} from '../../__generated__/graphql'
import { useCompanyDetailsContext } from './useCompanyDetailsContext'
import { useHistory, useParams } from 'react-router-dom'
import { AdGroupRoute, AdGroupRouteBuilder, PositionRoute, PositionRouteBuilder } from '../../pages/routeParams'
import { useSnackbarManager } from '../providers/snackbarContext'
import { useAppTranslations } from './useAppTranslations'

export const useDuplicateAdGroupOrPosition = (id: string, name: string): [() => Promise<void>, boolean] => {
  const t = useAppTranslations()
  const companyType = useCompanyDetailsContext()['company']['type']
  const isPosition = companyType === 'Publisher'
  const { slug } = useParams<PositionRoute | AdGroupRoute>()
  const history = useHistory()
  const companyDetails = useCompanyDetailsContext()
  const [, setSnackbar] = useSnackbarManager()
  const refetchQuery = {
    refetchQueries: () => [
      {
        query: SidebarItemsDocument,
        variables: {
          companyId: companyDetails.company.id,
          archived: false,
          isAdvertiser: companyDetails.company.type === CompanyType.Advertiser,
          isTradingDesk: companyDetails.company.type === CompanyType.TradingDesk,
          isPublisher: companyDetails.company.type === CompanyType.Publisher,
        },
      },
    ],
    awaitRefetchQueries: true,
  }
  const [copyPosition, { loading: loadingCopyPosition }] = useCopyPositionMutation(refetchQuery)
  const [copyAdGroup, { loading: loadingCopyAdGroup }] = useCopyAdGroupMutation(refetchQuery)

  const loading = useMemo(() => loadingCopyPosition || loadingCopyAdGroup, [loadingCopyPosition, loadingCopyAdGroup])

  const duplicatePosition = useCallback(async () => {
    try {
      const { data } = await copyPosition({
        variables: {
          positionId: id,
          name: `${name} (copy)`,
        },
      })
      if (data?.copyPosition) {
        history.push(PositionRouteBuilder(slug, data?.copyPosition))
        setSnackbar({ message: t('commons.saved'), severity: 'success' })
      }
    } catch (error) {
      setSnackbar({ message: t('commons.errorOccurred'), severity: 'error' })
      console.error('Error duplicating position: ', error)
    }
  }, [id, name, copyPosition, slug])

  const duplicateAdgroup = useCallback(async () => {
    try {
      const { data } = await copyAdGroup({
        variables: {
          adGroupId: id,
          name: `${name} (copy)`,
          isActive: false,
        },
      })
      if (data?.copyAdGroup) {
        history.push(AdGroupRouteBuilder(slug, data?.copyAdGroup))
        setSnackbar({ message: t('commons.saved'), severity: 'success' })
      }
    } catch (error) {
      setSnackbar({ message: t('commons.errorOccurred'), severity: 'error' })
      console.error('Error duplicating adgroup: ', error)
    }
  }, [id])

  const duplicate = useMemo(() => (isPosition ? duplicatePosition : duplicateAdgroup), [isPosition])

  return [duplicate, loading]
}
