import {
  AdvertiserWebsiteCost,
  CostDetailsDimensions,
  CostDetailsFilter,
  PublisherCampaignCost,
  PublisherCostDetails,
} from '../../../__generated__/graphql'

type ResellersCampaigns = {
  advertisers: {
    campaigns: PublisherCampaignCost[]
  }[]
  total: PublisherCostDetails
  name: string
}[]

export const flatResellersCampaigns = (resellers: ResellersCampaigns): PublisherCampaignCost[] => {
  return (
    resellers
      ?.map((reseller) => {
        if (reseller.advertisers !== null && reseller.advertisers.length === 0)
          return {
            name: reseller.name,
            total: {
              ...reseller.total,
              cpl: 0,
              cpo: 0,
              leads: 0,
              lr: 0,
              or: 0,
              orders: 0,
            },
          }
        return reseller.advertisers.map((advertiser) => advertiser.campaigns).flat()
      })
      .flat() || []
  )
}

type ResellersWebsites = {
  publishers: {
    name: string
    websites: AdvertiserWebsiteCost[]
  }[]
}[]

const WebsiteWithPublisherName = (
  website: AdvertiserWebsiteCost,
  publisherName: string
): AdvertiserWebsiteCost & { additionalInfo: string } => {
  return {
    ...website,
    additionalInfo: publisherName,
  }
}

export const flatResellersWebsites = (
  resellers: ResellersWebsites
): Array<AdvertiserWebsiteCost & { additionalInfo: string }> => {
  return (
    resellers
      ?.map((reseller) => {
        return reseller.publishers
          .map((publisher) => {
            return publisher.websites.map((website) => WebsiteWithPublisherName(website, publisher.name))
          })
          .flat()
      })
      .flat() || []
  )
}

export const buildDimensionFilterVariables = (
  dimension: CostDetailsDimensions,
  values: Record<CostDetailsDimensions, string[]>
): CostDetailsFilter[] => {
  if (values === undefined || values[dimension] === undefined) return []
  return [{ dimension, values: values[dimension] }]
}
